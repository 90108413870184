.offer-referral-button {
    margin-left: 16px;
    width: 52px;
    height: 52px;
}

.sort-offers-icon {
    width: 14px;
    height: auto;
}

.one-two-treat-button {
    bottom: 85px;
    position: fixed;
    right: 20px;
    z-index: 5 !important;
    display: inline-block !important;
    cursor: pointer;
}

.intro-container .one-two-treat-button {
    display: none;
}

.share-offer-frame {
    position: relative;
}

.share-float-button {
    bottom: 50px;
    width: 52px;
    height: auto;
    position: absolute;
    right: 25px;
}

.share-offer-button {
    height: 25px;
    width: auto;
    position: relative;
}

.refer-float-button.hovered,
.share-offer-button.hovered {
    filter: hue-rotate(90deg);
}

.logged-in-heading{
    height: 35px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: #222337;
    padding: 6px 8px;
    color: #fff;
    max-width: calc(100vw - 195px);
    position: relative;
}

.logged-in-heading-tooltip {
    font-size: 12px;
    position: absolute;
    opacity: 0;
    color: white;
    background: linear-gradient(90deg, #2CD8D5 0%, #9560f8 55.93%, #362b97 99.88%),
        linear-gradient(85.33deg, #5352B5 -20.5%, #4000af 96.23%);
    transition: opacity 0.4s ease-in-out;
    padding: 8px;
    border-radius: 50px;
    right: 0;
    bottom: -30px;
}
.logged-in-heading:hover .logged-in-heading-tooltip{
    opacity: 1;
}

.logged-in-heading-layout-gold{
    height: 35px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: linear-gradient(0deg, #94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%);
    padding: 6px 8px;
    color: #000;
    max-width: calc(100vw - 195px);
}

.header-login-container {
    flex: 0 1 auto;
    margin-left: auto;
    margin-right: 10px;
}

.logged-in-heading-nickname {
    overflow-x: hidden;
    text-wrap: nowrap;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    -webkit-text-overflow: ellipsis; 
}
.logged-in-heading p{
    margin: 0;
}

.marquee-container {
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}

.horizontal {
    flex-direction: row;
}

.vertical {
    flex-direction: column;
}

.marquee-content {
    display: flex;
    flex-wrap: nowrap;
    animation: marquee 30s linear infinite;
    gap: 0;
}

.marquee-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.reverse .marquee-content {
    animation-direction: reverse;
}

.pause-on-hover:hover .marquee-content {
    animation-play-state: paused;
}

@keyframes marquee {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

@keyframes marquee-vertical {
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-100%);
    }

}

/* Media Queries */
@media screen and (min-width: 447px) {
    .one-two-treat-button {
        bottom: 80px;
    }
}

@media screen and (min-width: 1024px) {
    .logged-in-heading-nickname {
        max-width: none;
    }
}