input::-webkit-input-placeholder {
    color: #282B54;
    font-weight: 400;
}

input::-ms-input-placeholder {
    color: #282B54;
    font-weight: 400;
}

::placeholder {
    color: #282B54;
    font-weight: 400;
}

input[type=text],
input[type=email],
input[type=password],
input[type=date],
input[type=tel],
input[type=url] {
    padding: 12px 16px;
    border: none;
    border-radius: 5px;
    text-align: center;
    color: #685EBF;
    background-color: white;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.form-area {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 17px;
    font-size: 15px;
}

.form-area input, .form-area textarea {
    background: transparent;
    border: 1px solid #B9B9B9;
    color: #fff;
    text-align: left;
    padding: 12px;
    transition: all 0.5s ease-in-out;
}

.login-form-input,
.register-form-input,
.request-reset-form-input,
.reset-form-input,
.swal-payment-methods-input,
.password-change-form-input {
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    color: #6260E1;
    width: 100%;
    box-sizing: border-box;
}

.login-form-input,
.request-reset-form-input {
    margin-top: 20px;
}

.login-form-input {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.login-form-input:-webkit-autofill,
.login-form-input:-webkit-autofill:hover,
.login-form-input:-webkit-autofill:focus,
.login-form-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
}


input.swal-payment-methods-input {
    text-align: left;
    border: 1px solid #9A7AD8;
}

input.swal-payment-methods-input.error {
    border: 1px solid red;
}

input.swal-payment-methods-input::placeholder {
    font-size: 14px;
    color: #9495aa;
}

.register-form-input,
.password-change-form-input,
.reset-form-input {
    margin-top: 0;
}

input.verification-code-input-item {
    padding: 10px;
    width: 40px !important;
    height: 40px;
    background: #e8e8ea;
    border: 1px solid #e8e8ea;
    border-radius: 5px;
    outline: none;
}

input.verification-code-input-item:focus {
    border: 1px solid #9A7AD8;
    background-color: #ffffff;
}

input.verification-code-input-item.error {
    border: 1px solid red;
}

#verification-time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #141629;
}

.swal-auth-code-error {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: red;
    display: none;
}

.swal-auth-code-error a {
    text-decoration: underline;
    cursor: pointer;
}

.swal-auth-code-error.error {
    display: block;
}

.captcha-container {
    width: 100%;
    margin: 0 auto;
}

#captcha-input {
    width: 100%;
    margin-top: -5px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

#canv {
    width: 100%;
    height: 56px;
    opacity: .5;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

#copy-link-input,
#one-two-treat-copy-link {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 18px;
    margin-bottom: 8px;
    color: rgba(10, 11, 20, 0.5);
    width: calc(100% - 36px);
    padding: 20px 12px;
    text-align: left;
}

.red-error-input input {
    border: 1px solid red;
    box-shadow: 1px 1px 16px -3px rgba(255, 0, 0, 0.71);
    -webkit-box-shadow: 1px 1px 16px -3px rgba(255, 0, 0, 0.71);
    -moz-box-shadow: 1px 1px 16px -3px rgba(255, 0, 0, 0.71);
}

.cursor-blink {
    font-weight: 100;
    font-size: 44px;
    width: 5px;
    overflow: hidden;
    animation: cursor-blink-animation 1s steps(5, start) infinite;
}

.cashback-search {
    width: 60%;
    margin: 20px auto;
}

input.cashback-input {
    width: -webkit-fill-available !important;
    padding: 12px;
    padding-left: 50px;
    border-radius: 55px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: rgb(0, 0, 0);
    user-select: none;
}

.cashback-input:focus {
    outline: none;
    border: none;
}

#simple-select {
    display: flex;
    align-items: center;
    height: 10px;
}

#verification-code::placeholder,
#password::placeholder,
#confirm-password::placeholder,
#username::placeholder,
#password::placeholder {
    color: #282B54;
    opacity: .5;
}

.form-input select,
.form-input input {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid;
    color: #282B54;
    text-align: left;
}

.form-input select,
.form-input select:focus-visible,
.form-input input,
.form-input input:focus-visible,
.form-input-phone {
    border-color: #9A7AD8;
    outline: none !important;
}

.form-input-phone input:focus-visible, .form-input-phone select:focus-visible{
    outline: none !important;
}

.form-input-phone input{
    outline: none !important;
    padding: 10px;
    color: #282B54;
    text-align: left;
}

.form-input-phone .input-wrap {
    border-width: 1px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.form-input-phone select, .form-input-phone input{
    font-size: 16px;
}

.form-input input::placeholder {
    color: #a9a9a9;
}

.input-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.input-error-message {
    color: red;
    font-size: 12px;
}

.validation-error input,
.validation-error select {
    border-color: red;
}

.form-input label {
    font-weight: 700;
}

.form-input {
    margin-bottom: 15px;
}

.withdrawal-confirm-input {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #6260E1;
    position: relative;
}

.form-control+.form-control {
    margin-top: 1em;
}

.withdrawal-confirm-input::before {
    content: "";
    min-width: 12px;
    min-height: 12px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    clip-path: polygon(0% 55%, 10% 45%, 35% 70%, 90% 10%, 100% 20%, 35% 90%);
    background: #5FC88F;
    transform: scale(0);
    position: absolute;
}

.withdrawal-confirm-input:checked::before {
    transform: scale(1);
}

.withdrawal-confirm-check-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.confirm-input {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #6260E1;
    position: relative;
}

.form-control+.form-control {
    margin-top: 1em;
}

.confirm-input::before {
    content: "";
    min-width: 12px;
    min-height: 12px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    clip-path: polygon(0% 55%, 10% 45%, 35% 70%, 90% 10%, 100% 20%, 35% 90%);
    background: #5FC88F;
    transform: scale(0);
    position: absolute;
}

.confirm-input:checked::before {
    transform: scale(1);
}

.confirm-check-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: all 0.2s ease-in-out;
}

.searchInput input:focus + span{
    transform: scale(1.2);
    opacity: 1!important;
}

@media (min-width: 600px) {

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=url] {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
    }

    .register-form-input,
    .request-reset-form-input,
    .reset-form-input,
    .password-change-form-input,
    .swal-payment-methods-input .register-error-message {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .captcha-container {
        width: 400px;
        margin: 0 auto;
    }

    .register-checkbox-container {
        width: 400px;
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .cashback-search {
        width: 40%;
        margin: 20px auto;
    }
}

@media (min-width: 1024px) {
    .withdrawal-confirm-check-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=url] {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
    }

}