.coins-month-selection,
.referral-select-selection {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #BBA7EF;
    background: transparent;
    width: 100%;
    border: 1px solid #6C6AEB;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    padding-right: 8px;
    position: relative;
    z-index: 3;
    cursor: pointer !important;
}

.referral-select-selection {
    color: #BBA7EF;
    border: 1px solid #685EBF;
    outline: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.coins-month-selection option,
.referral-select-selection option {
    background-color: #141629;
    text-align: left;
    cursor: pointer !important;
}
option:hover{
    cursor: pointer !important;
}

.coins-month-selection option.selected-option,
.referral-select-selection option.selected-option {
    background-color: #141629;
}


@media (min-width: 1024px) {

    .coins-month-selection,
    .referral-select-selection {
        width: 540px;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .coins-month-selection.desktop {
        width: auto;
        height: auto;
        padding: 10px 50px;
    }

    .coins-month-selection-wrapper.desktop {
        width: fit-content;
    }

    .coins-month-selection-wrapper.desktop::after {
        top: 50%;
        right: 16px;
    }

    .coins-month-selection-wrapper.desktop::before {
        right: calc(100% - 40px);
    }


}

@media (max-width: 400px) {
    .filtered .coins-month-selection {
        margin-left: 40px;
    }
}