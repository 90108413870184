.graph-slider-container {
    margin: 0 20px;
    background: linear-gradient(335.02deg, #5352B5 5.75%, #9F7DDB 85.66%);
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
}

.graph-slider-container-above {
    background-color: transparent;
}

.graph-slider-container-below {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    opacity: 0.6;
    padding: 70px 0 10px;
    border-radius: 0 0 38px 38px;
}

.graph-slider-container-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    opacity: 0.5;
}

.graph-slider-container-number {
    margin: 0;
    margin-bottom: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 110%;
    text-align: center;    
    color: #FFFFFF;
}

.graph-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #fff;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0;
    padding: 0;
}

.graph-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border: 0;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
    border-radius: 50%;
}

.graph-slider-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    gap: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    color: #fff;
    opacity: .5;
}

.stats-tab {
    box-sizing: border-box;
    padding: 0 20px 20px;
    width: 100%;
    margin: 0 auto;
}

.stats-tab-item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
}

.stats-tab-item .flex {
    display: flex;
}

.stats-tab-item.seventh {
    width: 100%;
    margin-top: 40px;
    justify-content: center;
}

.stats-tab-item.seventh .text .sub {
    max-width: none;
}

.stats-tab-item .text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-align: left;
    color: #FFFFFF;
    margin: 5px 0 0 10px;
}

.stats-tab-item .text .sub {
    opacity: .7;
    font-weight: 400;
    max-width: 80px;
}

.chart-container {
    width: 80%;
    background: linear-gradient(335.02deg, #5352B5 5.75%, #9F7DDB 85.66%);
    margin: 20px auto;
    position: relative;
    border-radius: 20px;
}

.chart-container canvas {
    display: inline-block !important;
}

.chart-container-absolute {
    position: absolute;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-align: left;
    color: #FFFFFF;
    z-index: 10;
}

@media (min-width: 500px) {
    .stats-tab {
        width: 75%;
    }

    .chart-container {
        width: 70%;
    }
}

@media (min-width: 900px) {
    .stats-tab {
        width: 50%;
    }

    .chart-container {
        width: 50%;
    }
}

@media (min-width: 1500px) {
    .stats-tab {
        width: 30%;
    }

    .chart-container {
        width: 30%;
    }
}