.page-container.new {
    padding: 0;
}

.page-container.new * {
    box-sizing: border-box;
}

.home-body,
.home-body-padding {
    position: relative;
    z-index: 1;
}

.home-body-header {
    padding: 0 25px;
}

.home-body-container.relative {
    z-index: 3;
}

.bg-absolute {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    bottom: 1px;
    z-index: -1;
    background: linear-gradient(135deg, #EC79FF80 0%, #141629 35%, #141629 56%, #5F26A8E6 98%);
}
.bg-absolute.second {
    background: linear-gradient(200deg, #EC79FF00 0%, #14162900 35%, #14162900 56%, #5F26A8E6 98%);
    
}

.robot-absolute {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url(images/robot-only.png);
    background-size: 400px 314px;
    background-repeat: no-repeat;
    background-position: top center;
}

.hi-container {
    padding: 15px 0;
    text-align: left;
    margin-top: 16px;
}

.hi {
    max-width: 75%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 58px;
    background: linear-gradient(153.31deg, #A24ED3 8.4%, #7326F5 80.24%), #6260E1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    display: inline-block;
}

.hi-hand {
    max-width: 25%;
    font-size: 45px;
}

.hot-offer-time-new {
    margin: 0;
    font-weight: 300;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
}

.hot-offer-left-new {
    margin: 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.hot-offer-button.new {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    text-align: center; 
    background: linear-gradient(90deg, #2CD8D5 0%, #C5C1FF 55.93%, #FFBAC3 99.88%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    color: #000000;
}

.hot-offer-button-div-inactive {
    text-decoration: line-through;
    opacity: .6;
}

.providers-homepage-title {
    padding: 0 40px;
}

.hot-offer-absolute {
    position: absolute;
    animation: hot-offer 2.0s;
    z-index: 1000;
    top: 5px;
    right: 5px;
    min-width: 20px;
    width: fit-content;
    height: 20px;
    background-color: red;
    color: #fff;
    outline: 1px solid #fff;
    border-radius: 20px;
    overflow: auto;
    font-size: 0;
    font-family: 'Poppins';
    font-style: normal;
    padding: 5px;
}

.hot-offer-absolute::after {
    position: absolute;
    content: attr(data-value);
    right: 0;
    top: 20%;
    width: 100%;
    height: 100%;
    font-size: 10px;
}

.home-second-section {
    position: relative;
}

.homepage-swiper {
    padding: 0 50px 10px;
    margin-bottom: 70px;
}

.homepage-swiper .swiper-wrapper {
    align-items: center;
}

.flex-50.desktop{
}

@media (min-width: 700px) {
    .home-body-container {
        width: 65%;
        margin: 0 auto;
    }

    .home-body-header {
        padding: 0;
    }
    
}

@media (min-width: 1024px) {

    .robot-absolute {
        top: 0px;
        left: -80px;
    }

    .homepage-swiper {
        margin-bottom: 0px;
    }

    .homepage-swiper.desktop {
        padding: 0;
    }

    .flex-50.desktop {
        flex: 50% 1;
        position: absolute;
        right: -120px;
        top: -164px;
        width: 300px;
    }

    .home-body-container.desktop.margin-top-80 {
        margin: 0 !important;
        width: 100%;
    }

}

@media (min-width: 1300px) {

    .flex-50.desktop {
        right: 5%;
    }

    .home-body-container {
        width: 45%;
        margin: 0 auto;
    }

}

@media (min-width: 1600px) {
        .flex-50.desktop {
                right: 20%;
            }
}

@media (max-width: 400px) {
    .home-body-header {
        padding: 0 20px;
    }

}