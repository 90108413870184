/* Mobile-first styles */
.account-details-label,
.account-details-value,
.account-details-link,
.account-details-container,
.account-details-section,
.account-settings,
.account-details-links,
.subscription-settings-section,
.delete-account-actions,
.delete-account-confirm-button,
.delete-account-cancel-button {
    font-family: 'Poppins', sans-serif;
    text-align: start;
}

.small-mobile {
    font-size: 26px !important;
    align-self: end;
}

.account-details-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 8px;
    text-align: center;
    max-width: 100px;
}

.account-details-value {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.01em;
    font-style: normal;
    color: #CED0DE;
}

.account-details-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7674F5;
    display: contents;
    text-decoration: underline;
}

.account-details-container {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    max-width: 530px;
    color: #FFFFFF;
    min-width: 220px;
    position: relative;
}

.account-details-links {
    display: flex;
    flex-direction: column;
}

.account-details-section {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    margin-bottom: 38px;
}

.account-settings {
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;
}

.account-details-message {
    z-index: 4;
    background-color: #7674F5;
    padding: 6px;
    border-radius: 10px;
    text-align: center;
    width: 300px;
    position: absolute;
}
.email-setting{
    margin-bottom: 45px;
}

.subscription-settings-section {
    max-width: 1150px;
    margin: 0;
}

.delete-account-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.title-container{
    margin-bottom: 30px;
}

.delete-account-confirm-button {
    color: #0A0B14 !important;
    text-decoration: underline;
    background: transparent !important;
}

.delete-account-cancel-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%) !important;
    border-radius: 18px;
    color: #FFFFFF !important;
    height: 48px;
}

.swal-center-text {
    padding-top: 50px !important;
}

.delete-account-container {
    max-width: 375px;
}
/* Media queries for larger screens */
@media screen and (min-width: 768px) {

    .delete-account-container {
            max-width: 540px;
        }
    .subscription-settings-section {
        flex-direction: row;
        gap: 30px;
        padding: 0;
    }

    .account-details-links {
        gap: 20px;
    }

    .account-settings:last-child {
        margin-right: 60px;
    }

    .subscription-settings-section .telegram-setting,
    .subscription-settings-section .email-setting,
    .account-details-links {
        margin-top: 0;
    }

    .account-details-container {
        margin-bottom: 0;
    }

    .account-details-label,
    .account-details-link,
    .account-details-value {
        font-size: 18px;
        text-align: center;
    }

    .page-title {
        font-size: 36px !important;
        align-self: auto;
    }
}
@media screen and (min-width: 1024px) {
    .account-details-section {
        padding-right: 25px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
    }

    .subscription-settings-section {
        max-width: 1050px;
    }

    .small-mobile {
        font-size: 60px !important;
        align-self: auto;
    }

    .account-settings {
        justify-content: space-between;
        max-width: 1010px;
    }

    .delete-account-cancel-button,
    .delete-account-confirm-button {
        width: 350px !important;
    }


    .account-details-label,
    .account-details-value,
    .account-details-link {
        font-size: 18px;
    }


    .account-details-section {
        max-width: 1027px;
        width: auto;
    }

    .btn-align-right{

    }
}

@media screen and (min-width: 1440px) {
    .account-details-links {
        gap: 100px;
    }

    .account-details-links,
    .account-settings {
        flex-direction: row;
    }
}

@media screen and (min-width: 1750px) {
    .account-details-section {
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    .notification-setting-label {
        max-width: 260px;
    }

    .account-details-container:not(:last-child) {
        margin-bottom: 15px !important;
    }


}