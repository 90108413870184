.intro-survey-quiz-header-font {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 0;
    margin-bottom: 5px;
}

.intro-survey-starting-section-container {
    position: relative;
}

.intro-survey-quiz-header-font.question {
    font-size: 14px;
}

.intro-survey-quiz-header-font.step {
    font-size: 28px;
}

.intro-survey-quiz-layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    margin: 15px 0 30px;
    padding: 20px 10px;
}

.intro-survey-quiz-options {
    margin-top: 10px;
}

.intro-survey-quiz-font {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-align: center;
    color: #141629;
    letter-spacing: 0.02em;
}

.intro-survey-quiz-font.heading {
    margin-top: 20px;
    font-size: 24px;
}

.intro-survey-quiz-font.silder-heading {
    width: 70%;
    margin: 0 auto;
}

.intro-survey-quiz-font.heading.purple {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro-survey-quiz-font.subheading,
.intro-survey-quiz-font.footertext {
    margin: 15px 0 5px;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.5;
}

.intro-survey-quiz-font.footertext.open-screen {
    text-align: left;
    margin: 0;
    margin-bottom: 15px;
}

.slider-question-content-container {
    padding: 0 20px;
}

.slider-question-content-container *,
.select-container-static {
    text-align: left;
}

.black-label {
    color: #282B54;
}

.intro-survey-quiz-range-container {
    display: flex;
    flex-direction: column;
}

.intro-survey-quiz-range-slider {
    padding-bottom: 20px;
}

.intro-survey-quiz-font.info {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.intro-survey-quiz-font.info.bold {
    font-weight: 700;
}

.intro-survey-quiz-font.info.bold.green {
    color: #5FC88F;
}

.intro-survey-quiz-font.options {
    font-size: 14px;
}

.purple, .purple:before {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.intro-survey-quiz-next-button,
.purple-button {
    cursor: pointer;
    padding: 16px 44px;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.intro-survey-quiz-next-button.opacity {
    opacity: 0.5;
}

.intro-survey-quiz-back-button,
.purple-outlined-button {  
    cursor: pointer;
    position: relative;
    padding: 16px 44px;
    border: none;
    outline: none;
    border-radius: 18px;
    z-index: 1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.purple-outlined-button {
    padding: 27px 77px;
}

.intro-survey-quiz-back-button:before,
.purple-outlined-button:before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: -1;
    border-radius: 17px;
    background-color: white;
}

.purple-outlined-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateY(-50%);
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    -webkit-background-clip: text;
    color: transparent;
}


.purple-outlined-button.disagree:after {
    content: "Disagree";
}

.intro-survey-quiz-back-button span  {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    z-index: -1;
    border-radius: 18px;
    -webkit-background-clip: text;
    color: transparent;
}

.open-screen-radio-container {
    display: flex;
    gap: 20px;
}

.open-screen-radio {
    flex-grow: 1;
}

.quiz-actions-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 15px;
}

.quiz-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.progress-bar {
    position: relative;
}

.progress-bar-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    opacity: 0.2;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    height: 9px;
    z-index: 1;
}

.progress-bar-inner {
    position: absolute;
    width: 23px;
    height: 7px;
    top: 1px;
    left: 1px;
    z-index: 1;
    background: linear-gradient(90deg, #5FC88F 0%, #36A368 100%);
    border-radius: 10px;
}

.quiz-option-phone-container {
    display: flex;
    justify-content: space-between;
}

.quiz-option-container {
    position: relative;
    margin-top: 12px;
    padding: 18px 12px;
    user-select: none;
}

.quiz-option-container.open-screen {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="date"]::before,
input[type="tel"] {
    border: none;
}

.quiz-option-container.checked {
    outline: 1px solid #5352B5;
    border-radius: 18px;
    padding: 18px 12px;
}

.quiz-option-container.checked:nth-child(6n+2) { outline: 1px solid #B58252; }
.quiz-option-container.checked:nth-child(6n+3) { outline: 1px solid #52B568; }
.quiz-option-container.checked:nth-child(6n+4) { outline: 1px solid #529DB5; }
.quiz-option-container.checked:nth-child(6n+5) { outline: 1px solid #A752B5; }
.quiz-option-container.checked:nth-child(6n+6) { outline: 1px solid #B55252; }

.quiz-option-container.checked:nth-child(6n+2)::before { background: linear-gradient(85.33deg, #B58252 -20.5%, #DBD77D 96.23%); }
.quiz-option-container.checked:nth-child(6n+3)::before { background: linear-gradient(85.33deg, #52B568 -20.5%, #7DDB97 96.23%); }
.quiz-option-container.checked:nth-child(6n+4)::before { background: linear-gradient(85.33deg, #529DB5 -20.5%, #7DC4DB 96.23%); }
.quiz-option-container.checked:nth-child(6n+5)::before { background: linear-gradient(85.33deg, #A752B5 -20.5%, #CE7DDB 96.23%); }
.quiz-option-container.checked:nth-child(6n+6)::before { background: linear-gradient(85.33deg, #B55252 -20.5%, #DB7D7D 96.23%); }

.quiz-option-container.checked::before {
    content: "";
    position: absolute;
    border-radius: 18px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border: none;
}

.quiz-option-container::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.15;
    border: 1px solid #282B54;
    border-radius: 18px;
}

.quiz-option-container.min {
    flex-grow: 1;
    margin: 10px 0;
}

.quiz-option-container.min::before {
    max-height: 54px;
    border-radius: 10px;
}

.quiz-option-container.min input {
    padding: 0;
    font-weight: 400;
}

.quiz-option-data * {
    cursor: pointer;
}

.quiz-option-data {
    padding: 12px 0;
}

.quiz-option-data,
.quiz-option-label,
.open-screen-radio-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: left;
    display: block;
    position: relative;
    margin-left: 10px;
    user-select: none;
}

.open-screen-radio-item input {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.open-screen-radio-item label {
    position: relative;
    padding-left: 20px;
}

.open-screen-radio-item label::before {
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: 1px solid #282B54;
    outline-offset: 6px;
    opacity: 0.25;
}

.open-screen-radio-item input:checked + label::before {
    background-color: #282B54;
    opacity: 1;
}

.quiz-option-data-text::placeholder {
    opacity: .5;
}

.quiz-option-data-text {
    user-select: none;
    position: relative; 
    color: #282B54 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    font-weight: 400;
    text-align: left !important;
}

.quiz-option-data-text::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    opacity: 0.15;
    border: 1px solid #282B54;
    border-radius: 18px;
}

.quiz-option-data-text:focus {
    border: none;
    outline: none;
}

.quiz-option-data input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.quiz-option-data .text {
    display: inline-block;
    margin-left: 33px;
}

.quiz-option-data .checkmark {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    height: 21px;
    width: 21px;
    display: inline-block;
    border: 1px solid #d0d1dd;
    border-radius: 50%;
    margin-right: 10px;
}

.quiz-option-data .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checked .quiz-option-data .checkmark {
    background-color: #5FC88F;
    border: 1px solid #5FC88F;
}

.checked .quiz-option-data .checkmark:after {
    display: block;
}

.slider-container {
    margin-top: 20px;
    padding: 0 20px;
    user-select: none;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: linear-gradient(85.33deg, #5733A3 -20.5%, #9F7DDB 47.62%, #DB7D7D 111.62%);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 0;
    cursor: pointer;
    background: #7956BD;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.29);
    border-radius: 50%;
}

.slider-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #282B54;
}

.slider-numbers span {
    padding-left: 10px;
    opacity: 0.5;
}

.slider-numbers span.active {
    opacity: 1;
}

.slider-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.slider-buttons img {
    cursor: pointer;
    width: 24px;
    height: auto;
}

.slider-buttons img[disabled] {
    opacity: .2;
}

.select-container {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #787878;
    border-radius: 10px;
}

.select-container.open-screen {
    margin: 10px 15px 0 0;
}

.select-container-static {
    position: relative;
    padding: 15px 10px;
    border: 1px solid rgba(40, 43, 84, 0.3);
    border-radius: 10px;
}

.select-container-static.open-screen {
    padding: 18px 10px;
    border: 1px solid #282b541f;
    min-width: 102px;
}

.select-container-static.open-screen span {
    margin-right: 25px;
    color: #282B54;
}

.select-option-icon {
    padding: inherit;
    padding-left: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
}

.select-option-icon.open-screen {
    right: 0;
    display: flex;
    align-items: center;
}

.select-option-icon .expanded {
    transform: rotate(-180deg);
}

.select-items {
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgba(40, 43, 84, 0.3);
    border-radius: 10px;
    color: #000000;
}

.select-items.open-screen {
    max-height: 100px;
    min-width: 102px;
    overflow: scroll;
    padding-right: 0;
    margin-bottom: 10px;
    border: 1px solid #282b541f;
}

.select-item-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.select-item-container p {
    opacity: 0.6;
}

.select-item-container svg, .select-container svg {
    height: 17px;
    width: 14px;
    margin-right: 4px;
}

*::-webkit-scrollbar {
    display: none;
}

.select-items p {
    cursor: pointer;
    margin: 0;
    margin-bottom: 10px;
}

.intro-survey-starting-section-container *,
.intro-survey-finishing-section-container * {
    box-sizing: border-box;
}

.intro-survey-starting-section-container,
.intro-survey-finishing-section-container {
    min-height: 100vh;
}

.intro-survey-starting-section-absolute,
.intro-survey-finishing-section-absolute {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000000;
    z-index: -999;
}

.intro-survey-starting-section-absolute-shadow.desktop {
    display: none;
}

.intro-survey-starting-section-close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    width: 47px;
    height: 47px;
    right: 25px;
    top: 25px;
    z-index: 5;
    background: linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #FFFFFF;
    border-radius: 50%;
}

.intro-survey-starting-section,
.intro-survey-finishing-section {
    background-image: url(images/intro-survey-starting-section-background.png);
    background-size: auto 60%;
    background-position: top center;
    background-repeat: no-repeat;
    min-height: inherit;
    display: flex;
}

.intro-survey-starting-section.quiz {
    flex-direction: column;
    padding: 45px 25px 0;
    gap: 15px;
}

.intro-survey-finishing-section {
    background-image: url(images/intro-survey-finishing-section-background.png);   
}

.intro-survey-starting-section-info,
.intro-survey-finishing-section-info {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    max-width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    align-self: flex-end;
    margin-bottom: 120px;
}

.intro-survey-starting-section-info-logo {
    padding-top: 20px;
}
.intro-survey-starting-section-info-logo {
    width: auto;
}

.intro-survey-starting-section-header,
.intro-survey-finishing-section-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    color: #141629;
    margin-bottom: 10px;
}

.intro-survey-finishing-section-header {
    padding: 5px 0 20px;
}

.intro-survey-starting-section-content,
.intro-survey-finishing-section-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #282B54;
    margin: 0 10px;
}

.back-to-gain-coins {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-radius: 18px;
    border: none;
    margin-top: 24px;
    padding: 16px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

@media (min-width: 500px) {
    .intro-survey-starting-section-content,
    .intro-survey-finishing-section-content {
        margin: 0 20px;
    }

    .back-to-gain-coins {
        padding: 16px 50px;
    }
}

@media (min-width: 600px) {
    .intro-survey-starting-section,
    .intro-survey-finishing-section {
        background-size: 70% auto;
    }

    .intro-survey-starting-section-content,
    .intro-survey-finishing-section-content {
        margin: 0 20px;
    }

    .intro-survey-finishing-section-info {
        margin-bottom: 100px;
    }

    .intro-survey-quiz-layout {
        padding: 20px;
    }

    .intro-survey-starting-section.quiz {
        padding: 45px 100px 0;
    }

    .open-screen-radio-item label {
        padding-left: 25px;
    }
}

@media (min-width: 900px) {

    .intro-survey-starting-section-close {
        width: 67px;
        height: 67px;
        right: 50px;
        top: 50px;
    }

    .intro-survey-starting-section-close img {
        width: 34px;
        height: 34px;
    }

    .intro-survey-starting-section-info,
    .intro-survey-finishing-section-info {
        padding: 10px 0 40px;
        max-width: 65%;
    }

    .intro-survey-starting-section-info-logo {
        margin: 10px 0 30px;
    }

    .intro-survey-starting-section-info-logo img {
        height: 50px;
    }

    .intro-survey-starting-section-header,
    .intro-survey-finishing-section-header {
        font-size: 32px;
    }

    .intro-survey-starting-section-content,
    .intro-survey-finishing-section-content {
        font-size: 18px;
        margin: 10px 20px;
    }

    .intro-survey-finishing-section-info {
        margin-bottom: 40px;
    }

    .intro-survey-quiz-layout,
    .progress-bar  {
        width: 65%;
        margin: 0 auto;
    }

    .intro-survey-quiz-layout  {
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

@media (min-width: 1000px) {    
    .intro-survey-starting-section,
    .intro-survey-finishing-section {
        background-size: 50% auto;
    }

    .intro-survey-starting-section-info,
    .intro-survey-finishing-section-info {
        max-width: 55%;
    }

    .intro-survey-starting-section-content,
    .intro-survey-finishing-section-content {
        margin: 10px 40px;
    }

    .intro-survey-quiz-layout {
        padding: 40px;
    }
}

@media (min-width: 1024px) {

    .intro-survey-starting-section-container.desktop {
        position: relative;
    }

    .intro-survey-quiz-layout.desktop {
        width: 85%;
    }

    .quiz-actions-container.desktop {
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
    }

    .intro-survey-quiz-options.desktop {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0 30px;
        margin-top: 20px;
    }

    .select-container.desktop {
        width: 50%;
    }

    .quiz-option-container.desktop {
        flex: 45%;
        max-width: calc(50% - 15px);
        max-height: 80px;
        display: flex;
        align-items: center;
    }

    .quiz-option-container.min input {
        width: 100%;
    }

    .quiz-actions.desktop {
        justify-content: center;
        gap: 30px;
    }

    .quiz-actions.desktop .intro-survey-quiz-back-button,
    .quiz-actions.desktop .intro-survey-quiz-next-button {
        padding: 16px 90px;
    }

    .intro-survey-starting-section-info.desktop {
        max-width: 50%;
    }

    .intro-survey-starting-section.desktop {
        background-image: url(images/intro-survey-starting-section-background-full.png);
        background-size: auto 100%;
        background-position: top center;
        background-repeat: no-repeat;
    }

    .intro-survey-starting-section-absolute-shadow.desktop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 350px;
        z-index: 2;
        opacity: 0.15;
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .intro-survey-starting-section.quiz.desktop {
        width: 100%;
    }

    .intro-survey-starting-section.quiz.desktop .progress-bar {
        width: 40%;
    }
}

@media (min-width: 1500px) {
    .intro-survey-starting-section,
    .intro-survey-finishing-section {
        background-size: 45% auto;
    }

    .intro-survey-starting-section-info,
    .intro-survey-finishing-section-info {
        max-width: 42%;
    }

    .intro-survey-starting-section-content,
    .intro-survey-finishing-section-content {
        margin: 10px 50px;
    }

    .intro-survey-starting-section.quiz {
        width: 60%;
        margin: 0 auto;
    }

    .intro-survey-quiz-layout.desktop {
        width: 60%;
    }
}

@media (min-width: 1700px) {
    .intro-survey-starting-section,
    .intro-survey-finishing-section {
        background-size: auto 70%;
        background-position: top center;
    }

    .intro-survey-starting-section-info,
    .intro-survey-finishing-section-info {
        max-width: 32%;
    }

    .intro-survey-finishing-section-info {
        margin-bottom: 100px;
    }
}