a {
    text-decoration: none;
}

a.faq-link {
    color: #8A88FF;
}

a.faq-link:hover {
    color: #5FC88F;
    -webkit-animation: faq-link-color 0.5s ease;
    -moz-animation: faq-link-color 0.5s ease;
    -o-animation: faq-link-color 0.5s ease;
    animation: faq-link-color 0.5s ease;
}

a.privacy-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    position: absolute;
    right: 40px;
    bottom: 3px;
}

a.privacy-link:hover {
    color: #5FC88F;
    -webkit-animation: white-to-green-link-color 0.5s ease;
    -moz-animation: white-to-green-link-color 0.5s ease;
    -o-animation: white-to-green-link-color 0.5s ease;
    animation: white-to-green-link-color 0.5s ease;
}

a.footer-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 8px;
    display: inline-block;
}

a.footer-link:hover {
    color: #5FC88F;
    -webkit-animation: footer-link-color 0.5s ease;
    -moz-animation: footer-link-color 0.5s ease;
    -o-animation: footer-link-color 0.5s ease;
    animation: footer-link-color 0.5s ease;
}

footer.light-mode > div.legal-links-div > a.footer-link {
    color: #282B54;
}

footer.light-mode > div.legal-links-div > a.footer-link:hover {
    color: #32810B;
    -webkit-animation: dark-footer-link-color 0.5s ease;
    -moz-animation: dark-footer-link-color 0.5s ease;
    -o-animation: dark-footer-link-color 0.5s ease;
    animation: dark-footer-link-color 0.5s ease;
}

a.legal-link,
a.signup-link,
a.referral-link,
a.faucetpay-referral-link {
    font-family: Poppins;
    color: #7674F5;
}

a.signup-link {
    margin-left: 4px;
}

a.faucetpay-referral-link {
    font-family: Poppins;
    color: #7674F5;
    font-size: 14px;
}

a.swal-legal-link {
    color: #5FC88F;
    font-weight: bold;
}

a.legal-link:hover,
a.signup-link:hover,
a.referral-link:hover,
a.faucetpay-referral-link:hover {
    color: #5FC88F;
    -webkit-animation: purple-to-light-green 0.5s ease;
    -moz-animation: purple-to-light-green 0.5s ease;
    -o-animation: purple-to-light-green 0.5s ease;
    animation: purple-to-light-green 0.5s ease;
}

a.legal-link.dark-hover:hover,
a.signup-link.dark-hover:hover,
a.referral-link.dark-hover:hover {
    color: #32810B;
    -webkit-animation: purple-to-dark-green 0.5s ease;
    -moz-animation: purple-to-dark-green 0.5s ease;
    -o-animation: purple-to-dark-green 0.5s ease;
    animation: purple-to-dark-green 0.5s ease;
}

a.swal2-link {
    color: #282B54;
}

a.swal2-link:hover {
    color: #5FC88F;
    -webkit-animation: swal2-link-color 0.5s ease;
    -moz-animation: swal2-link-color 0.5s ease;
    -o-animation: swal2-link-color 0.5s ease;
    animation: swal2-link-color 0.5s ease;
}

.tell-me-more-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #282B54;
    text-decoration: underline;
    display: block;
}

.see-more-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 10px;
}

.playground-page-link {
    position: relative;
}

a.facebook-signup-menu-link:hover {
    color: #5FC88F;
    -webkit-animation: white-to-green-link-color 0.5s ease;
    -moz-animation: white-to-green-link-color 0.5s ease;
    -o-animation: white-to-green-link-color 0.5s ease;
    animation: white-to-green-link-color 0.5s ease;
}

a.contact-support-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top: 24px;
    display: block;
}

.affiliates-footer-link {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
    width: 50%;
}

.offer-popup-coins-when-pays-tooltip-link{
    color: #9F7DDB;
    font-weight: 600;
}
@media (min-width: 1024px) {

        .affiliates-footer-link {
                width: fit-content;
                font-size: 24px;
                font-weight: 600;
            }

    a.footer-link {
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
    }

    .tell-me-more-link {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    .see-more-link {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    a.faucetpay-referral-link {
        font-size: 17px;
    }

}
