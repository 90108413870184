* {
    font-family: 'Poppins';
}

.payment-method-container {
    border-radius: 16px;
    padding: 20px 20px;
    height: 140px;
    align-content: center;
    min-width: 50%;
    cursor: pointer;
}

.payment-methods-list-container {
    max-width: 600px;
    grid-gap: 15px;
    justify-content: center;
    margin: 20px auto;
    display: flex;
}

.payment-method-container.selected{
    border: 2px solid #fff;
}

.payment-method-text-container {
    padding-right: 10px;
}

.payment-method-desc{
    gap: 5px;
}

.payment-methods-list-description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: start;
    text-wrap: balance;
    max-width: 400px;
    color: #FFFFFF;
}

.payment-method-container-left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.payment-method-container-right-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.payment-method-action-icon {
    height: 18px;
    width: auto;
}

.payment-method-label-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.payment-method-data-container {
    display: flex;
    align-items: baseline;
}

.payment-methods-list-hint {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
}

.how-does-it-work-hint {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top: 0;
    cursor: pointer;;
    margin: 0 auto
}

.cashout-methods-list-hint {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    display: block;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
}

.payment-method-label-text {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.01em;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-right: 8px;
}

div.payment-methods-list-description {
    text-align: center;
        width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.payment-method-popup {
    max-width: 365px !important;
    border-radius: 38px !important;
    padding: 10px 40px !important;
}

.swal2-confirm.payment-method-get-verification-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px !important;
    border: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 22px;
    text-align: center;
    margin-top: -4px;
    margin-bottom: 30px !important;
    width: 100%;
}


.swal-payment-methods-input-error {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FA3035;
    padding-bottom: 6px;
    transition: all 0.5s ease-in-out;
}

.swal2-html-container .verification-code-input {
    display: flex;
    justify-content: space-between;
    gap: 9px;
}

.faucetpay-referral-link {
    margin-bottom: 5px;
}

#withdrawalPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    overflow: auto;
}

.user-balance {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 263px;
    font-size: 12px;
    border-radius: 99px;
    margin: 0 auto;
}

.withdraw-buttons{
    display: flex;
    flex-direction: column;
}

.cashout-description{
    font-size: 16px;
    font-weight: 700;
}

.coins-balance {
    font-size: 32px;
    font-weight: 700;
}

.pix-form-back {
    width: 30px;
}

@media screen and (min-width: 424px) {
    .payment-methods-list-description {
        text-align: center;
    }
}

@media screen and (min-width: 724px) {

    .payment-method-popup{
        max-width: 460px;
    }

}

.cashout-brands-mobile {
    display: block;
    width: 100%;
    height: auto;
    max-width: 324px;
    margin-left: auto;
    margin-right: auto;
}

.cashout-brands-desktop {
    display: none;
}

@media screen and (min-width: 1024px) {

    .payment-method-label-text {
        font-size: 16px;
    }

    .cashout-methods-list-hint {
        font-weight: 400;
        max-width: 350px;
        font-size: 16px;
    }

    .cashout-brands-mobile {
        display: none;
    }

    .cashout-brands-desktop {
        display: block;
        width: 522px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (min-width: 1200px) {
    div.payment-methods-list-description {
        font-size: 18px;
    }
    .payment-methods-list-container {
        flex-direction: row;
    }

    .payment-method-container {
        margin-bottom: 16px;
    }

}