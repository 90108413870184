.enable-adult-offers-robot {
    width: 81%;
    height: auto;
}

.confeti-1 {
    width: 100vw;
    max-width: 440px;
    position: absolute;
    top: 60vh;
    left: 50%;
    transform: translate(-50%, -50%);
}

.confeti-2 {
    display: none;
    position: absolute;
    top: -94%;
}
.welcome-robot-image {
    width: 100%;
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
}

.back-button-cookies {
    position: absolute;
    top: 16px;
    left: 14px;
}
.suspended-robot-image {
    width: 100%;
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
}

.broken-line-image {
    margin-top: 48px;
    height: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.step-image, .step-image-last {
    z-index: 2 !important;
    margin-top: -110px;
    margin-left: 80px;
}

.step-image-last {
    padding-bottom: 20px;
}

.menu-bar {
    position: absolute;
    top: 9px;
    left: 9px;
    padding: 16px;
    width: 30px;
    height: auto;
    z-index: 4;
}

.login-page-header .menu-bar {
    top: 50px;
    left: 35px;
    width: 30px;
    height: 20px;
    padding: 0;
}

.login-page-header .content-page-logo {
    margin-top: 45px;
    width: 159px;
    height: 28px;
}

.close-menu {
    width: 18px;
    height: 18px;
}

.close-welcome {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 82px;
    right: 14px;
}

.close-account-suspended {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 82px;
    right: 14px;
}

.close-withdrawal {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 14px;
    right: 14px;
}

.close-popup-top_100 {
    position: absolute;
    top: -60px;
    right: 14px;
}

.close-convert {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 80px;
    right: 20px;
}

.home-image {
    width: 184px;
    height: auto;
}

.homepage-logo {
    width: 184px;
    height: auto;
    margin-top: 30px;
    margin-bottom: 16px;
}

.menu-bar:hover,
.close-menu:hover,
.close-welcome:hover,
.close-account-suspended:hover,
.close-withdrawal:hover {
    filter: brightness(75%);
}

.first-position-badge {
    width: 196px;
    height: 196px;
    display: block;
    margin-bottom: -36px;
    margin-left: -25px;
    margin-right: -25px;
}

.second-position-badge {
    width: 71px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 12px;
}

.third-position-badge {
    width: 63.5px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 12px;
}

.place-highscore {
    width: 100%;
    height: 100%;
}

.place-highscore.first {
    width: 135%;
    height: 135%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.first-place-container .first-place-container-img-container::before {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    background: url(images/reward1-outline.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.second-place-container .second-place-container-img-container::before {
    content: "";
    position: absolute;
    top: -40px;
    left: -40px;
    right: -40px;
    bottom: -40px;
    background: url(images/reward23-outline.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.third-place-container .third-place-container-img-container::before {
    content: "";
    position: absolute;
    top: -40px;
    left: -40px;
    right: -40px;
    bottom: -40px;
    background: url(images/reward23-outline.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.provider-logo {
    width: 60%;
    height: auto;
    margin-top: 16px;
}

.provider-list-banner {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 25px;
}

.provider-list-banner.show-more {
    animation: provider-fade-in 1s;
    -webkit-animation: provider-fade-in 1s;
    -moz-animation: provider-fade-in 1s;
    -o-animation: provider-fade-in 1s;
}

.ethereum-image {
    width: 209px;
    height: 260px;
    margin-bottom: -80px;
}

.ethereum-image-small {
    width: 74px;
    height: 93px;
    padding-left: 8px;
}

.start-now-robot{
    width: 34vw;
    height: 26vw;
}

.swal2-robot-icon {
    width: 240px;
    padding-top: 85px;
}

.swal2-robot-icon.delete-account {
    width: 340px;
    padding-top: 115px;
}

.swal2-icon {
    border: none !important;
}

.delete-account-container .swal2-icon {
    height: 8em !important;
}
.swal2-close:focus {
    box-shadow: none !important;
}

.swal2-join-close {
    color: #000000 !important;
    margin-top: 24px !important;
    margin-right: 22px !important;
}

.swal2-nfts-close {
    color: #FFFFFF !important;
    margin-top: 24px !important;
    margin-right: 22px !important;
}

.swal2-legitimacy-close {
    color: #FFFFFF !important;
    margin-top: 24px !important;
    margin-right: 22px !important;
}

.swal2-payment-method-hint-close {
    color: #FFFFFF !important;
    margin-top: 24px !important;
    margin-right: 22px !important;
}

.join-triangle {
    width: 38px;
    height: auto;
    position: absolute;
    bottom: 29px;
    left: -13px;
}

.hidden-join-triangle {
    display: none;
}

.join-option-image {
    width: 26px;
    height: auto;
}

.most-popular-image {
    width: 79px;
    height: auto;
    position: absolute;
    top: -4px;
    left: -3px;
}

.swal-nfts-image {
    width: 182px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    margin-bottom: 30px;
}

.swal-legitimacy-image {
    width: 315px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 0px;
}

.refer-method-image {
    width: 66px;
    height: 66px;
}

.refer-method-image.hidden {
    display: none;
}

.refer-share-icon {
    width: 51px;
    height: 51px;
}

.menu-section-arrow {
    color: #15182A;
    width: 14px;
    height: 14px;
}

.offers-toggle {
    width: 18px;
    height: auto;
    margin-top: 2px;
    cursor: pointer;
}

.unlimited-indicator-completed {
    position: absolute;
    width: 22px;
    height: auto;
    bottom: 2px;
    left: -22px;
}

.daily-indicator-completed {
    width: 26px;
    height: auto;
}

.unlimited-indicator-completed.right,
.daily-indicator-container-completed.right {
    left: unset;
    right: 0;
}

.more-offers-icon {
    width: 16px;
}

.more-offers-icon.plus {
    top: 0;
}

.more-offers-icon.minus {
    top: 6px;
}

.more-offers-icon:hover {
    filter: brightness(120%);
}

.unlimited-indicator-list {
    width: 20px;
    height: auto;
    margin-top: 2px;
}

.daily-indicator-list {
    width: 20px;
    height: auto;
    margin-top: 2px;
}

.unlimited-indicator-list.desktop,
.daily-indicator-list.desktop,
.award-indicator-list.desktop {
    display: none;
}

.list-offer-container.todays-best .unlimited-indicator-list.desktop,
.list-offer-container.todays-best .daily-indicator-list.desktop,
.list-offer-container.todays-best .award-indicator-list.desktop {
    display: inline-block;
}

.list-offer-container.todays-best .unlimited-indicator-list.mobile,
.list-offer-container.todays-best .daily-indicator-list.mobile,
.list-offer-container.todays-best .award-indicator-list.mobile {
    display: none;
}

.unlimited-indicator-details {
    width: 21px;
    height: auto;
}

.percentage-indicator-details {
    width: 19px;
    height: auto;
}

.daily-indicator-details {
    width: 24px;
    height: auto;
}

.highscore-star {
    width: 45px;
    height: auto;
}

.monthly-coins-image {
    width: 90px;
    height: auto;
    padding: 16px;
}

.earning-entry-logo,
.payout-entry-logo {
    width: 88px;
    height: auto;
    border-radius: 16px;
}

.reward-payout-entry-icon {
    width: 62px;
    height: auto;
}

.treatcoin-payout-entry-icon {
    width: 62px;
    height: auto;
}

.offer-details-icon {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.close-offer-icon {
    width: 47px;
    height: 47px;
    position: absolute;
    top: 24px;
    right: 21px;
}

.featured-offer-image {
    width: 79px;
    height: auto;
    position: absolute;
    top: -2px;
    left: -3px;
}

.refer-teaser {
    height: auto;
    width: 300px;
}

.refresh-spinner {
    display: none;
    position: absolute;
    top: 10px;
    left: calc(50% - 14px);
    transform: translateX(50%);
    animation: spin 1s infinite linear;
}

.one-two-treat-teaser-close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #8B71D1;
}

.one-two-treat-teaser-arrow {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: -25px;
    right: 10px;
    color: #333333;
}

.share-teaser {
    height: auto;
    width: 300px;
}

.notifications-left-icon {
    width: 25px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.notifications-right-icon {
    width: 25px;
    height: auto;
    position: absolute;
    top: 3px;
    left: 40px;
}

.the-treat-coin-item-image {
    width: 90px;
    height: 90px;
}

.coins-disclaimer-alert {
    width: 33px;
    height: 33px;
    position: absolute;
    left: 7px;
    top: calc(50% - 16.5px);
}

.coins-disclaimer-close {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 13px;
    right: 12px;
}

.list-offer-image,
.hot-offer-image {
    height: 36px;
    max-width: 36px;
    width: auto;
    object-fit: cover;
    border-radius: 100%;
    margin-top: -43px;
    margin-bottom: 2px;
}

.list-offer-theme-indicator,
.hot-offer-theme-indicator {
    width: 55px;
    height: auto;
}

.list-offer-difficulty-image {
    height: auto;
    width: 50px;
}

.list-offer-difficulty-image:hover + span,
.more-offers-icon:hover + span,
.unlimited-indicator-completed:hover + span,
.daily-indicator-completed:hover + span {
    display: inline;
}

.difficulty-popup-image {
    width: 119px;
    height: auto;
}

.more-from-brand-popup-image {
    width: 180px;
    height: auto;
}

.unlimited-indicator-popup-image {
    width: 180px;
    height: auto;
}

.daily-indicator-popup-image {
    width: 180px;
    height: auto;
}

.refresh-my-treats-icon {
    width: 28px;
    height: 28px;
}

.favourite-offers-left-arrow {
    height: 18px;
    width: auto;
    position: absolute;
    top: calc(50% - 13px);
    left: 0;
    padding: 8px;
}

.favourite-offers-right-arrow {
    height: 18px;
    width: auto;
    position: absolute;
    top: calc(50% - 13px);
    right: 0;
    padding: 8px;
}

.favourite-offers-section-selection {
    width: 17px;
    height: auto;
    position: absolute;
    bottom: 19px;
    left: calc(50% - 9px);
}

.timer-counter-icon {
    width: 20px;
    height: auto;
}

.close-timer-promotion {
    width: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: calc(100% - 48px);
    height: auto;
}

.exchange-treat-coins-image {
    height: 100px;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
}

.withdraw-hint {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.payment-method-icon {
    width: 33px;
    height: auto;
}

.withdrawal-payment-method-icon {
    margin-top: 13px;
    height: 44px;
    width: auto;
}

.withdrawal-payment-method-hint-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 6px;
}

.treat-coins-validity-hint-icon {
    width: 20px;
    height: auto;
    padding-top: 16px;
}

.payment-method-connection-icon {
    height: 10px;
    width: auto;
    margin-right: 4px;
}

.payment-method-cta-image {
    width: 64px;
    height: 64px;
    margin-right: 14px;
}

.recommender-details-icon {
    width: 14px;
    height: auto;
    margin-right: 8px;
}

.providers-homepage-card-image img {
    width: 100%;
    height: 100%;
    display: inline-block;
}

.providers-homepage-menu img[alt='logo'] {
    position: absolute;
    top: -20px;
    left: 10px;
}

.providers-homepage-menu.cashback img[alt='logo'] {
    position: absolute;
    top: -20px;
    left: 15px;
}

.providers-homepage-menu img[alt='arrow'] {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
}

.providers-homepage-brand-item img {
    display: inline-block;
}

.footer-icon {
    width: 5vw;
    height: 5vw;
    min-width: 24px;
    min-height: 24px;
    max-width: 48px;
    max-height: 48px;
}

.special-offer-logo-image {
    width: 500px;
    margin: 0 auto;
}

.treatcoin-payout-entry-wrapper.earning .treatcoin-payout-conversion {
    height: 136px;
}

.robot-background-big {
    position: absolute;
    z-index: 1;
    top: -80px;
    right: -200px;
}

.offers-toggle.desktop {
    display: none;
}

.offers-toggle.mobile {
    display: inline-block;
}

.header-logo {
    left: 20px;
    position: absolute;
    display: flex;
}

.terms-popup-green-limb {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.terms-popup-bot {
    position: relative;
    height: 259px;
    margin-top: 88px;
    transition: all 0.5s ease-in;
    opacity: 1;
}

.terms-popup-bot.fade-out {
    opacity: 0;
}
.withdrawal-confirm-image {
    width: 70px;
    height: 70px;
}

.build-my-team-refer-friends-bots{
    position: absolute;
    top: 2px;
    right: 27px;
    height: 103px;
}

.build-my-team-earnings-tc{
    width: 24px;
    height: 24px;
    margin-right: 4px;
}
.how-it-works-popup-upper-svg,
.how-it-works-popup-bottom-svg {
    position: absolute;
    width: 100%;
    border-radius: 16px;
}

.how-it-works-popup-upper-svg {
    top: 0;
    right: 0;
}

.how-it-works-popup-bottom-svg {
    bottom: 0;
    left: 0;
}
.how-it-works-card-image {
    width: 33px;
    height: 33px;
}
.how-it-works-line {
    width: 48px;
    height: 394px;
    position: absolute;
    top: 80px;
}
.stats-row-ref-earnings-count-tc {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.stats-row-ref-image-tc {
    position: absolute;
    width: 9px;
    height: 9px;
    transform: translate(50%, 50%);
    bottom: 0;
    right: 0;
}

.offer-popup-reviews-rating-star{
    margin-right: 6px;
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
}
.offer-popup-reviews-list-image .image {
    max-width: 76px;
    max-height: 38px;
    border-radius: 11.101px;
    box-shadow: 2.22px 2.22px 5.551px 0px rgba(0, 0, 0, 0.15), 2.22px -2.22px 4.441px 0px #7C7C7C inset, 0px 2.775px 4.441px 0px #BDBDBD inset;
}
.delete-review-modal img {
    width: 70px;
    height: 70px;
}
.affiliates-phones {
    width: 196px;
        height: 217px;
    position: relative;
    margin-bottom: 32px;
}

.affiliate-program-card img{
    max-height: 70px;
    margin-bottom: 16px;
    display: block;
}
.affiliates-designed-for-image {
    width: 314px;
    height: 279px;
    display: block;
    background-size: contain;
    position: relative;
    margin-bottom: 27px;
}
.affiliates-designed-for-image.desktop {
    display: none;
}
.affiliates-why-tc-heading img {
    width: 37px;
    height: 36px;
}

.affiliates-why-tc-graph {
    width: 90vw;
    max-width: 990px;
    max-height: 275px ;
    margin: 0 auto;
}
.affiliates-why-tc-moneybag{
    position: absolute;
    bottom: -0;
    right: 0;
    height: 10vh;
    max-height: 280px;
}
.affiliates-feedback-item img {
    width: 51px;
    height: 51px;
}

.affiliates-feedback-item .flex img {
    width: 16px;
    height: 17px;
}
.affiliates-tools-item-img {
    width: 60px;
    height: 60px;
}
.treatcoin-payout-conversion {
    width: 140px;
}

.offer-popup-coins-when-pays-image{
    width: 10px;
    height: 10px;
}
.picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: black;
    position: absolute;
}

.picture-container img {
    width: 100%;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
    object-fit: cover;
}
.homepage-robot-image,
.confeti-2 {
    width: 100%;
    margin-top: 68px;
}
@media (min-width: 767px) {
    .confeti-1 {
        left: 50%;
            transform: translate(-50%, -50%) rotate(0);
    }
}
@media (max-width: 1023px) {
    .content-page-logo.new {
        width: 148px;
        height: auto;
    }

}

@media (min-width: 768px) and (max-width: 1023px) {

    .homepage-robot-avatar {
        width: 332px;
        transform: translateX(-50%);
        left: 50%;
        display: block;
        position: absolute;
        bottom: 210px;
    }

    .timer-robot-icon {
        width: 300px;
        height: auto;
        display: block;
        position: absolute;
        bottom: 43px;
        left: calc(50% - 150px);
    }

    .login-page-header .menu-bar {
        left: 100px;
    }

}

@media (min-width: 1024px) {

    .confeti-1 {
            display: none;
        }
    
        .confeti-2 {
            display: block;
        }
    .affiliates-feedback-item img {
            width: 80px;
            height: 80px;
        }

    .affiliates-designed-for-image {
        width: 600px;
        height: 533px;
        margin-bottom: 40px;
    }

    .withdrawal-confirm-image {
        width: 120px;
        height: 120px;
    }

    .header-logo {
        left: 50%;
        transform: translateX(-50%);
    }

    .menu-bar {
        display: block;
    }

    .providers-homepage-menu.desktop.homepage img[alt='arrow'] {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 30px;
    }

    .special-offers-popup.desktop .special-offer-logo-image {
        margin: 0 auto;
        width: 444px;
    }

    .special-offers-popup.desktop .special-offers-modal-title {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
    }

    .special-offers-popup.desktop #swal2-html-container {
        width: 80%;
        margin: 300px auto 0;
    }

    .unlimited-indicator-list.mobile,
    .daily-indicator-list.mobile,
    .award-indicator-list.mobile {
        display: none;
    }

    .unlimited-indicator-list.desktop,
    .daily-indicator-list.desktop,
    .award-indicator-list.desktop {
        display: inline-block;
    }

    .list-offer-container.todays-best .unlimited-indicator-list.desktop,
    .list-offer-container.todays-best .daily-indicator-list.desktop,
    .list-offer-container.todays-best .award-indicator-list.desktop {
        display: none;
    }

    .list-offer-container.todays-best .unlimited-indicator-list.mobile,
    .list-offer-container.todays-best .daily-indicator-list.mobile,
    .list-offer-container.todays-best .award-indicator-list.mobile {
        display: inline-block;
    }

    .offers-toggle.mobile {
        display: none;
    }

    .offers-toggle.desktop {
        display: inline-block;
    }

    .close-popup-top_100 {
        top: -80px;
        width: 24px;
        height: 24px;
    }

    .enable-adult-offers-robot {
        display: none;
    }

    .homepage-robot-avatar {
        width: 352px;
        display: block;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 210px;
    }

    .timer-robot-icon {
        width: 350px;
        height: auto;
        display: block;
        position: absolute;
        bottom: 30px;
        left: calc(50% - 175px);
    }

    .swal-nfts-image {
        width: 254px;
    }

    .refer-teaser {
        width: 350px;
    }

    .reward-payout-entry-icon {
        width: 90px;
    }

    .treatcoin-payout-entry-left-section img {
        width: 68px;
        height: auto;
    }

    .close-welcome {
        width: 24px;
        height: 24px;
        top: 154px;
        right: 168px;
    }

    .close-account-suspended {
        width: 24px;
        height: 24px;
        top: 154px;
        right: 168px;
    }

    .close-withdrawal {
        width: 24px;
        height: 24px;
        top: 32px;
        right: calc(50% - 357px);
    }

    .close-withdrawal.desktop {
        top: 35px;
        right: 35px;
    }

    .close-convert {
        width: 30px;
        height: 30px;
        top: 100px;
        right: 50px;
    }

    .ethereum-image {
        margin-bottom: 0px;
        width: 300px;
        height: auto;
        position: absolute;
        right: 0;
        top: 50px;
        margin-right: -50vw;
    }

    .the-treat-coin-item-image {
        width: 116px;
        height: 116px;
        display: inline-block;
    }

    .point-entry-logo {
        width: 88px;
        height: auto;
    }

    .timer-counter-icon {
        width: 25px;
        height: auto;
    }

    .provider-list-banner.show-more {
        animation: provider-fade-in 2s;
        -webkit-animation: provider-fade-in 2s;
        -moz-animation: provider-fade-in 2s;
        -o-animation: provider-fade-in 2s;
    }

    .exchange-treat-coins-image {
        height: 150px;
    }

    .withdraw-hint {
        width: 22px;
        height: 22px;
    }

    .broken-line-image {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .login-page-header .menu-bar {
        left: 100px;
        width: 35px;
        height: 25px;
    }

    .login-page-header .content-page-logo {
        margin-top: 38.5px;
        width: 230px;
        height: 55px;
    }

    div.treatcoin-payout-entry-wrapper.earning .treatcoin-payout-conversion {
        width: 180px;
        height: 155px;
    }

    div.treatcoin-payout-entry-wrapper.earning.homepage .treatcoin-payout-conversion {
        width: auto;
    }

    .special-offer-logo-image.desktop {
        left: 50%;
    }

    .earning-page.desktop .earning-entry-logo,
    .payout-page.desktop .payout-entry-logo {
        width: 70px;
    }

    @media (min-width: 1440px) {

        .affiliates-why-tc-heading img {
                width: 60px;
                height: 60px;
            }

        .affiliates-phones {
                width: 418px;
                height: 473px;
            }
        .affiliates-designed-for-image {
                display: none;
                margin-bottom: 15px;
            }

        .affiliates-tools-item-img {
            width: 96px;
            height: 96px;
        }

       .affiliates-feedback-item img {
           width: 96px;
           height: 96px;
       }

        .affiliate-program-card img {
            max-height: 72px;
        }

        
            .affiliates-designed-for-image.desktop {
                display: block;
                height: 418px;
                width: 1440px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

        .special-offers-popup.desktop #swal2-html-container {
            width: 66%;
            margin: 300px auto 0;
        }
    }

    @media (min-height: 901px) and (max-height: 950px) {

        .homepage-robot-avatar {
            width: 410px;
            height: auto;
        }
    }

    @media (min-height: 851px) and (max-height: 900px) {

        .homepage-robot-avatar {
            width: 390px;
            height: auto;
        }
    }

    @media (min-height: 801px) and (max-height: 850px) {

        .homepage-robot-avatar {
            width: 332px;
            height: auto;
        }
    }

    @media (max-height: 800px) {

        .homepage-robot-avatar {
            width: 350px;
            height: auto;
        }
    }
}

@media (max-width: 767px) {

    .homepage-robot-avatar {
        width: 95%;
            max-width: 262px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        display: block;
        position: absolute;
        top: 100px;
    }

    .timer-robot-icon {
        width: calc(100% - 150px);
        height: auto;
        display: block;
        position: absolute;
        bottom: 150px;
        left: 75px;
    }
}

@media (max-width: 400px) {

    .close-convert {
        top: 80px;
        right: 10px;
    }

    .treatcoin-payout-entry-wrapper.homepage .treatcoin-payout-conversion {
        width: auto;
    }

    .treatcoin-payout-conversion {
        width: 180px;
    }

    .first-place-container .first-place-container-img-container::before {
        top: -40px;
        left: -40px;
        right: -40px;
        bottom: -40px;
    }

    .second-place-container .second-place-container-img-container::before,
    .third-place-container .third-place-container-img-container::before {
        top: -35px;
        left: -35px;
        right: -35px;
        bottom: -35px;
    }

    .kyc-result-img {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 360px) {
    
    .login-page-header .menu-bar {
        left: 20px;
    }
}

@media (min-width: 1440px) {
    .build-my-team-refer-share-btn img {
        width: 31.253px;
        height: 31.253px;
    }

    .build-my-team-refer-friends-bots {
        position: absolute;
        top: 2px;
        right: 27px;
        height: 137px;
    }

    .how-it-works-card-image {
        width: 55px;
        height: 55px;
    }

    .how-it-works-line {
        width: 81px;
        height: 600px;
        top: 115px;
    }
    .stats-row-ref-image-tc {
        width: 14px;
        height: 14px;
        right: -5px;
    }

    .stats-row-ref-earnings-count-tc {
        width: 30px;
        height: 30px;
        margin-right: 7px;
    }
}

@media (min-width: 1440px) and (min-height: 1072px) {
    .how-it-works-line {
        width: 81px;
        height: 658px;
        top: 115px;
    }
}