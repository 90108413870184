.offers-clicked p,
.offers-clicked h1,
.offers-clicked h2,
.offers-clicked h3,
.offers-clicked h4,
.offers-clicked h5,
.offers-clicked ul,
.offers-clicked h6 {
    margin: 0;
}

.offers-clicked {
    font-family: Poppins;
    background: linear-gradient(168deg, #763B80 -62.34%, #05060C 32.46%, #0B0C17 47.97%, #3A1F5B 128.61%);
    padding-bottom: 100px;
}

.offers-clicked-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 92px 15px 30px 15px;
    position: relative;
}

.offers-clicked-back-arrow {
    width: 24px;
    height: 24px;
}

.offers-clicked-heading {
    margin: 0;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
}

.offers-clicked-sort-btn {
    padding: 2px 7px;
    color: #FFF;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #FFF;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.offers-clicked-sort-btn-text {
    display: inline-block;
    width: 72px;
    transition: all 0.3s ease-in-out;
}

.offers-clicked-sort-btn-text.purple-text {
    color: #9F7DDB;
}

.offers-clicked-sort-btn.purple-border {
    border-radius: 12px;
    border: 1px solid #9F7DDB;
}

.filter-by {
    left: 5px !important;
}

.offers-clicked-sort-popup {
    position: absolute;
    border-radius: 8px;
    background: #222337;
    transform: translate(-50%, -50%);
    transition: all 0.7s ease-in-out;
    opacity: 0;
    right: -100px;
    top: 245px;
    list-style-type: none;
}

.offers-clicked-sortby-item:not(:last-child) {
    display: flex;
    transition: all 0.7s ease-in-out;
    max-height: 0;
    height: 25px;
    margin-bottom: 16px;
}

.offers-clicked-sortby-item.checkbox,
.offers-clicked-sortby-item.checkmark {
    display: block;
    padding: 0;
    margin: 0;
}

.offers-clicked-sortby-item.checkbox {}


.offers-clicked-sort-popup.opened,
.offers-clicked-sort-popup.opened .offers-clicked-sortby-item *,
.offers-clicked-sort-popup.opened .offers-clicked-sortby-item {
    max-height: 200vh;
    opacity: 1;
    z-index: 3;
}

.offers-clicked-sort-popup.opened {
    padding: 20px 20px 0px 20px;
}

.offers-clicked-sort-popup.hidden * {
    max-height: 0;
    display: none;
}