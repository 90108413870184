table, tr, td {
    border: none;
}

table.legal-table {
    text-align: left;
    font-family: Poppins;
    border: 1px solid #FFFFFF;
    border-collapse: collapse;
    padding: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    vertical-align: top;
    color: #CED0DE;
    width: 100%;
    table-layout: auto;
}

table.legal-table > thead > tr > th, table.legal-table > tbody > tr > td {
    text-align: left;
    font-family: Poppins;
    border: 1px solid #FFFFFF;
    padding: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    vertical-align: top;
}

table.current-leaderboards {
    border-collapse: collapse;
    width: 100%;
    position: relative;
    table-layout: auto;
    z-index: 3;
    padding: 16px;
}

table.history {
    filter: grayscale(77.5%);
}

table.current-leaderboards tr:last-child td:first-child {
    border-bottom-left-radius: 38px;
}

table.current-leaderboards tr:first-child td:first-child {
    border-top-left-radius: 38px;
}

table.current-leaderboards tr:first-child td:last-child {
    border-top-right-radius: 38px;
}

table.current-leaderboards tr:last-child td:last-child {
    border-bottom-right-radius: 38px;
}

table.current-leaderboards > thead > tr {
    color: white;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
}

table.current-leaderboards > thead > tr > th {
    padding-top: 8px;
    padding-bottom: 8px;
}

table.current-leaderboards > tbody > tr {
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #8D31E4;
}

table.current-leaderboards > tbody > tr.my-score {
    background-color: #FFFFFF;
}

table.current-leaderboards > tbody > tr.other-score.bordered:after {
    content: '';
    height: 1px;
    width: calc(100% - 20px);
    position: absolute;
    background: #141629;
    left: 10px;
}

table.current-leaderboards > tbody > tr > td {
    padding-top: 4px;
    padding-bottom: 4px;
    height: 60px;
    text-align: center;
}

.rank-column {
    width: 20.5%;
}

.username-column {
    width: 36%;
}

.coins-column {
    width: 12.5%;
}

.reward-column {
    width: 31%;
}

.highscore-user-cell {
    text-align: left;
    padding-left: 6px;
}

.highscore-rank-cell {
    text-align: right;
}

.cookie-tracking-name-column {
    width: 25%;
}

.cookie-tracking-type-column {
    width: 25%;
}

.cookie-tracking-purpose-column {
    width: 50%;
}

@media (min-width: 1024px) {

    table.legal-table {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    table.legal-table > thead > tr > th, table.legal-table > tbody > tr > td {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }
}
