.completed-layout {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    background-color:rgba(0, 0, 0, 0.5);
}
.completed-layout.homepage {
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: flex-start;
    padding-top: 40%;
}

.multiple >.completed-layout {
    left: -14px;
    width: 105%;
}

.completed-layout-name {
    margin-bottom: 10px;
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
}

.homepage .completed-layout-name {
    font-size: 14px;
}
.completed-layout .top-offer-homepage-tc-value{
    display: flex;
    align-items: center;
}

.completed-layout .text-success{
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
}

.completed-layout .top-offer-homepage-usd-value{
    font-size: 12px;
}