.light-container {
    background: linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%);
    border-radius: 0px 0px 38px 38px;
    min-height: 320px;
}

.light-container.intro-container {
    height: 90vh;
    overflow-y: hidden;
    position: relative;
    z-index: 3;
}

.enable-adult-offers-container {
    background-color: #6260E1;
    border-radius: 25px;
}

.mail-title-container {
    margin-top: 70px;
}

.show-more-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease-in-out;
}

.show-more-button {
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 4px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.show-more-text {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.show-more-container.expanded {
    max-height: 500vh;
}

.start-now {
    margin-top: 70px;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 70px);
    background: #fff;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.start-now-checkbox {
    width: 300px;
    height: 75px;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
    margin-bottom: 20px;
}

.title-container {
    background-color: #141629;
    padding-top: 5px;
    max-width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
}

.iframe-container {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    overflow-y: auto;
}

.iframe-container-heading {
    height: 68px;
    display: flex;
    justify-content: end;
    padding-right: 20px;
    border-radius: 30px 30px 0px 0px;
    background: linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.global-spinner-container {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    background-color: #282B54;
}
.global-spinner{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.title-container.welcome-page {
    margin-top: 32px;
}

#loading-content {
    height: 100vh;
}

.content-page-header {
    background: linear-gradient(46.77deg, #def5fe 14.02%, #f9e7ff 48.07%, #feebe3 69.02%, #fff1cc 91.84%);
    border-radius: 0;
    height: 69px;
    position: fixed;
    width: 100%;
    z-index: 5;
    margin-top: -1px;
    visibility: visible;
    transition: all 0.6s ease-in-out;
    align-items: center;
    display: flex;
}

.content-page-header.new{
    background: linear-gradient(168deg, #763B80 -62.34%, #05060C 32.46%, #0B0C17 47.97%, #3A1F5B 128.61%);
    position: absolute;

}

.content-page-header.hidden {
    top: -69px;
    margin-top: -25px;
}

.content-page-header.hidden .menu-drawer{
    top: 0px;
    margin-top: 0px;
}

.account-access-page-header {
    background: transparent;
    border-radius: 0px;
    height: 69px;
    padding-top: 17px;
}

footer {
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    background-color: #141629;
    font-weight: 400;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 95px;
    flex-shrink: 0;
}

footer.light-mode {
    color: #282B54;
    background: transparent;
}

.legal-links-div {
    text-align: center;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    margin: 10px auto 20px;
}

#menu-div {
    position: fixed;
    z-index: 7;
}

.menu-drawer {
    overflow: scroll;
    max-height: 90vh;
    width: 83.5%;
    min-width: 315px;
    position: fixed;
    z-index: 7;
    text-align: center;
    -webkit-transform: translateX(-100%) translateZ(0);
    transform: translateX(-100%);
    -webkit-transition: transform 0.5s ease-out;
    -moz-transition: transform 0.5s ease-out;
    -o-transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    background: linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%);
    border-radius: 0px 0px 40px 40px;
}

.treat-coin-equals-div {
    margin-bottom: 20px;
}

.treat-coin-equals-amount.margin-right {
    margin-right: 15px;
}

.step-description {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 100;
    line-height: 25px;
    letter-spacing: 0.01em;
    font-style: normal;
}

.steps-div-container {
    display: block;
}

.providers-homepage * {
    box-sizing: border-box;
}

.providers-homepage-card-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 16px;
}

.providers-homepage-card {
    background: linear-gradient(57.33deg, #5352B5 18.11%, #9F7DDB 92.1%);
    border-radius: 38px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
}

.odd {
    animation: provider-homepage-card-left 2.1s;
    -webkit-animation: provider-homepage-card-left 2.1s;
    -moz-animation: provider-homepage-card-left 2.1s;
    -o-animation: provider-homepage-card-left 2.1s;
}

.even {
    animation: provider-homepage-card-left 2.1s;
    -webkit-animation: provider-homepage-card-left 2.1s;
    -moz-animation: provider-homepage-card-left 2.1s;
    -o-animation: provider-homepage-card-left 2.1s;
}

.providers-homepage-card-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
}

.providers-homepage-card-text {
    padding-left: 30px;
    padding-top: 25px;
    max-width: 50%;
}

.providers-homepage-card-image {
    padding: 10px 10px 0 0;
    width: 50%;
    max-width: 50%;
}
.providers-homepage-menu-container {
    gap: 5px 10px;
}

.providers-homepage-menu-container.cashback {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.providers-homepage-menu-container.cashback .menu-container {
    width: 100%;
}

.providers-homepage-menu {
    position: relative;
    border-radius: 8px;
    width: 170px;
    height: 100px;
    display: flex;
    align-items: flex-end;
    padding: 0 10px;
    margin-top: 30px;
}

.providers-homepage-menu.cashback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.cashback-subtitle {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.5);
}

.cashback-subtitle.menu {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 15px;
    gap: 10px;
}

.cashback-subtitle.menu img {
    cursor: pointer;
    transform: rotate(180deg);
}

.providers-homepage-menu:nth-child(1) {
    background-color: #FCD5FD;
}

.providers-homepage-menu:nth-child(2) {
    background-color: #C9FFC0;
}

.providers-homepage-menu:nth-child(3) {
    background-color: #B8FFF2;
}

.providers-homepage-menu:nth-child(4) {
    background-color: #FDD5D5;
}

.providers-homepage-menu:nth-child(5) {
    background-color: #FFF8D0;
}

.providers-homepage-brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 55px 0;
}

.providers-homepage-brand-item {
    width: 100px;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.light-mode>.footer-icons {
    display: none;
}

.login-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 88vh;
    overflow: hidden;
}

.login-container-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 350px;
    margin: 50px auto 0;
}

.login-container-div hr {
    border: none;
    width: -webkit-fill-available;
    height: 1px;
    background: #D1D1D1;
}

.login-buttons-container {
    display: inline-block;
    width: 312px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}
.live-news-container {
    user-select: none;
    position: relative;
    top: 68px;
    background: #393976;
    padding: 10px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    z-index: 2;
}

.live-news {
    margin: 0;
    padding: 0;
}

.live-news-item {
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
}

.live-news-item:first-child {
    margin-left: 15px;
}

.live-news .underline {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.live-news .entity {
    display: inline-block;
    font-size: 25px;
    margin: 0 20px;
}

.live-news-item:last-child .entity {
    margin-right: 5px;
}

div.marquee {
    min-width: unset;
}

.log-out-popup-container,
.daily-earning-popup-container,
.withdrawal-approved-popup-container {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 30px;
    text-align: center;
    margin-top: 50px;
}

.daily-earning-popup-container {
    margin-top: 0;
}

#swal2-html-container:has(.daily-earning-popup-image) {
    margin: 0;
}

.daily-earning-popup-image,
.withdrawal-approved-popup-image {
    background-image: url(images/daily-earning-plan-finished.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 400px;
    border-radius: 10px;
}

.daily-earning-popup-title {
    font-weight: 700;
    font-size: 24px;
    color: #6260E1;
    margin: 0;
}

.daily-earning-popup-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #141629;
    opacity: 0.7;
    margin-right: 20px;
    margin-left: 20px;
}

.daily-earning-confirm-button,
.withdrawal-approved-confirm-button {
    width: auto !important;
    padding: 15px 50px !important;
    border-radius: 18px !important;
    font-weight: 600 !important;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.daily-earning-cancel-button,
.withdrawal-approved-cancel-button {
    background: none !important;
    color: #0A0B14 !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    margin: 0;
}

.log-out-popup-title {
    font-weight: 500;
    font-size: 20px;
    color: #685EBF;
    margin: 0;
}

.log-out-popup-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0A0B14;
    margin: 0;
}

.log-out-description {
    margin: 0;
}

.daily-earning-plan {
    margin: 0 5px;
    position: relative;
    border-radius: 16px 16px 0 0;
}

.daily-earning-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
}

.daily-earning-container {
    position: relative;
    overflow: scroll;
    z-index: 1;
    background-color: #fff;
}

.polygon-absolute {
    position: absolute;
    bottom: 0px;
    right: 6px;
}

.daily-swiper {
    margin-top: 15px;
    width: 75% !important;
}

.width-auto {
    width: auto !important;
}

.daily-swiper .swiper-slide-prev div {
    background: linear-gradient(-90deg, #9F7DDB 16.45%, rgba(234, 129, 182, 0) 50%);
    opacity: 0.7;
    border-radius: 6px;
}

.daily-swiper .swiper-slide-next div {
    background: linear-gradient(90deg, #9F7DDB 16.45%, rgba(234, 129, 182, 0) 50%);
    opacity: 0.7;
    border-radius: 6px;
}

.earning-plan-carousel-item {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    padding: 5px 8px;
}

.earning-plan-carousel-item.items-3 {
    width: 78px;
}

.earning-plan-carousel-item.items-2 {
    width: 107px;
}

.earning-plan-carousel-item.items-1 {
    width: 235px;
}

.daily-earning-plan-info,
.offer-details-popup-info,
.offer-blurred-info {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 100;
    text-align: right;
}

.offer-details-popup-info {
    max-width: 80%;
    right: 5px;
    top: 52px;
}

.absolute-blurred-offer+.offer-blurred-info {
    right: -25px;
    top: 40px;
}

.absolute-blurred-offer.row1+.offer-blurred-info {
    right: -15px;
    top: 35px;
}

.daily-earning-plan-info img {
    margin-right: 4px;
    filter: brightness(25%);
}

.daily-earning-plan-info-text,
.offer-details-popup-info-text,
.offer-blurred-info-text {
    background-image: url(images/daily-earning-plan-info-text-bg.png);
    background-size: 100% 100%;
    padding: 40px 10px 30px 30px;
    margin: -10px -10px 0 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.01em;
    text-align: left;
    color: #676872;
}

.daily-earning-plan-info-text p {
    text-align: justify;
    max-width: 95%;
}

.offer-details-popup-info-text,
.offer-blurred-info-text {
    padding: 40px 60px;
    margin: 0;
}

.offer-blurred-info-text {
    padding: 30px 45px;
}

.offer-tracking-modal-content {
    padding: 20px 5px;
}

.offer-tracking-modal-images {
    display: flex;
    gap: 20px;
    justify-content: center;
}
.daily-earning-plan-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #141629;
}

.one-two-treat-tabs {
    display: flex;
    position: relative;
    z-index: 2;
    border-bottom: none;
}

.one-two-treat-tab-item {
    cursor: pointer;
    width: 50%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-bottom: 1px solid transparent;
    background-color: #F9F9FF;
    display: flex;
    flex-direction: column;
    padding: 7px 12px;
}

.one-two-treat-tab-item p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    line-height: 110%;
    text-transform: capitalize;
    display: block;
}

.one-two-treat-tab-item.active-tab {
    border: none;
    border-bottom: 3px solid;
    color: #6260E1;
    background-color: #fff;
    border-image-source: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-image-slice: 1;
    border-image-width: 0 0 3px 0;
}

.one-two-treat-tab-item.daily {
    position: relative;
    border: none;
    border-image-source: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-image-slice: 1;
    border-image-width: 0 0 3px 0;
}

.one-two-treat-tab-item.boder-bottom {}

.one-two-treat-tab-item.daily::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    background: #fff;
}

.cashback-search {
    position: relative;
}

.cashback-search img {
    position: absolute;
    top: calc(50% - 12px);
    left: 16px;
}

.special-offers {
    margin-left: -25px;
    margin-right: -25px;
    padding-top: 20px;
    padding-bottom: 10px;
    background: white;
}

.special-offers-container {
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
}

.special-offers-child {
    justify-content: center;
}

.special-offers-container .background {
    width: 250px;
    height: 200px;
    background: url(images/robot-delete-account-icon.svg) no-repeat top center;
}

.special-offers-modal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
}

.offer-details-use-competition-container {
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin: 0px 40px 10px;
    display: none;
}

.margin-0 {
    margin: 0;
}

@media (orientation:landscape) {
    .menu-drawer {
        width: 55%;
        height: calc(100vh - 50px);
    }
}

.menu-drawer.open {
    box-shadow: 45px 10px 80px #1C203A;
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0);
}

.page-container {
    text-align: center;
    min-height: calc(100vh - 500px);
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
}

/* .page-container.payments{
    display: flex;
    flex-flow: column;
    width: 100%;

} */

.page-container:has(.providers-homepage-menu-container) {
    padding-left: 0;
    padding-right: 0;
}

section.content-page-section {
    padding-top: 69px;
    /* padding-bottom: 55px; */
}

.content-page-section.build-my-team {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: rgb(141, 49, 228);
    background: linear-gradient(162deg, rgba(141, 49, 228, 0.38699229691876746) 7%, rgba(19, 21, 34, 0.711922268907563) 35%, rgba(19, 21, 34, 0.8099614845938375) 58%, rgba(141, 49, 228, 0.40940126050420167) 81%);
}

.build-my-team-refer-friends {
    position: relative;
    max-width: 586px;
    width: 100%;
    border-radius: 0 0 20px 20px;
    padding: 30px 16px 21px 13px;
    background: rgb(19, 21, 34);
    background: linear-gradient(162deg, rgba(19, 21, 34, 0.6082808123249299) 8%, rgba(141, 49, 228, 0.26934523809523814) 48%, rgba(19, 21, 34, 0.7399334733893557) 88%);
    }

.build-my-team-refer-friends-link-container {
    border-radius: 38px;
    border: 2px solid #8D31E4;
    padding: 6px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.build-my-team-refer-friends-link-container.padding-6-16 {
    padding: 6px 16px !important;
}
.build-my-team-refer-friends-link-container img,
.build-my-team-refer-friends-link-container button {
    height: 20px;
    width: 20px;
}

.pink-bordered {
    max-height: 35vh;
    overflow: scroll;
    padding: 8px 16px;
    border-radius: 13px;
    border: 5px solid #8D31E4;
    background: #131522;
    box-shadow: 0px 6.251px 6.251px 0px rgba(0, 0, 0, 0.25);
    width: 90vw;
    max-width: 536px;
    position: relative;
}

.build-my-team-referrals-head{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
}
.menu-container {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    background-color: transparent;
}

.build-my-team-referrals-stats-list-item-earnings{
    display: flex;
    align-items: center;
}

.menu-container.menu-top {
    margin-top: 24px;
}

.menu-container.menu-options-container {
    clear: both;
    overflow-y: scroll;
    height: fit-content;
    padding-bottom: 1px;
    margin-bottom: 20px;
}

.menu-bottom-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    margin-bottom: 16px;
    width: calc(100% - 28px);
    margin-left: 14px;
    margin-right: 14px;
    padding-bottom: 13px;
    text-align: center;
}

div.swal2-html-container {
    margin: 45px 13px 10px;
}

div.swal2-html-convert-container {
    margin: 80px 20px 40px !important;
}

div.swal2-html-container:has(.successful-alert) {
    padding: 20px 20px 0;
    margin: 0;
}

.swal2-container.swal2-backdrop-show:has(.successful-alert) {
    background-color: transparent;
    z-index: 9999;
}

.swal2-container:has(.successful-alert).swal2-center>.swal2-popup {
    align-self: flex-start;
}

div.swal2-html-container:has(.error-alert) {
    padding: 20px 20px 0;
    margin: 0;
}

.swal2-container.swal2-backdrop-show:has(.error-alert) {
    background-color: transparent;
    z-index: 9999;
}

.swal2-container:has(.error-alert).swal2-center>.swal2-popup {
    align-self: flex-start;
}

.homepage-container {
    background-color: #141629;
    padding-bottom: 4px;
}

.leaderboards-container {
    padding-left: 0px;
    padding-right: 0px;
}

.join-treatcoin-button-container,
.start-survey-button-container {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 8px;
}

.see-all-offers-button-container {
    position: relative;
}

.sort-by-offers-button-container {
    display: flex;
    justify-content: flex-end;
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
}

.sort-by-offers-lists {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 5px;
    padding: 12px 23px;
    position: absolute;
    top: 50px;
    text-align: left;
    display: none;
    color: #141629;
    z-index: 5;
}

.sort-by-offers-lists.show {
    display: flex;
    flex-direction: column;
}

.sort-by-offers-lists-item {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
    margin-bottom: 8px;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    padding-left: 3px;
}
.sort-by-offers-lists-item:hover {
    background-color: #007aff;
    color: white;
}

.sort-by-offers-lists-item.active {
    opacity: 1;
}

.earn-coins-button-container {
    position: relative;
}

.app-list-offers {
    width: 100%;
    height: auto;
    display: block;
    text-align: left;
    z-index: 2;
    background: white;
    max-height: 0px;
    overflow: hidden;
    cursor: pointer;
}

.app-list-offers.open {
    max-height: 9999px;
    -webkit-transition: max-height 0.5s ease-in;
    -moz-transition: max-height 0.5s ease-in;
    -o-transition: max-height 0.5s ease-in;
    transition: max-height 0.5s ease-in;
    overflow-y: hidden;
}

.app-list-offers.open.visible {
    overflow: visible;
}

.finished-indicator-bar {
    width: 100%;
    height: 5px;
    background: #5FC88F;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.finished-indicator-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
}

.finished-indicator-text-left {
    text-align: left;
    flex: 75%;
    margin-top: auto;
}

.finished-indicator-text-right {
    text-align: right;
    flex: 25%;
}

.offers-section-list {
    display: flex;
    justify-content: flex-start;
}

.offers-section-child {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;
    flex: 50%;
}

.tab{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;
    cursor: pointer;
}

.tab:after{
    background: #8d31e4;
    height: 5px;
    margin: 8px 0 0 -10%;
    width: 120%;
    content: '';
    display: block;
}

.tabs {
    display: flex;
    gap: 60px;
    justify-content: center;
    margin-bottom: 20px;
}

.round-btn{
    border: none;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    width: 100%;
    border-radius: 99px;
    color: #fff;
    font-size: 14px;
    padding: 15px 5px;
    transition: all 0.5s ease-out;
}

.round-btn:disabled{
    background: #B9B9B9;
    opacity: 0.5;
    cursor: inherit;
}

.horizontal-dots-btn{
    height: 20px;
    line-height: 0px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.horizontal-dots-btn:after{
    content: '\2026';
    font-size: 35px;
    color: #fff;
    text-align: right;
    position: absolute;
    right: 0;
}

.horizontal-dots-btn:hover:after{
    opacity: 0.6;
}

.dots-action-menu{
    position: absolute;
    top: 25px;
    background: #303149;
    padding: 5px;
    width: 110px;
    display: none;
    list-style-type: none;
    font-size: 14px;
    right: -3px;
    z-index: 9;
    margin: 0;
    border-radius: 5px;
}

.dots-action-menu li {
    padding: 2px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.dots-action-menu li:not(:last-child) {
    margin-bottom: 5px;
}

.dots-action-menu .reviews-delete {
    color: #D13C3C;
}

.delete-review-modal {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    padding: 25px;
    border-radius: 38px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(159, 125, 219, 0.15);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.delete-review-modal.open {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}
.delete-review-modal-buttons {
    display: flex;
    gap: 10px;
}
.dots-action-menu:after {
    content: "";
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #303149;
    right: 6px;
    top: -7px;
}

.dots-action-menu li:hover {
    opacity: 0.75;
}


.offers-section-child.marked, .tab.marked {
    opacity: 1;
}

.offers-section-child.marked>.highlight, .tab.marked>.highlight {
    background: #8d31e4;
    height: 8px;
    margin-left: 20px;
    margin-top: 8px;
    width: calc(100% - 40px);
}

.offers-list-difficulty {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 325px;
    display: flex;
    justify-content: space-between;
}

.offers-list-hash-tags {
    max-width: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 3px 9px;
    gap: 10px;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
}

.hash-tag-container {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 16px;
    padding: 8px;
}

.hash-tag-container.marked {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 8px;
}

.offers-section-difficulty {
    width: 70px;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    padding: 6px 0;
    border: 1px solid #5352B5;
    background: transparent;
}

.offers-section-difficulty.marked {
    border: #000000;
    background: #000000;
    color: #FFFFFF;
}

.offers-section-difficulty.all-offers {
    border: 1px solid #9F7DDB;
}

.offers-section-difficulty.all-offers.marked {
    border: none;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.offers-section-difficulty.easy-offers {
    border: 1px solid #06D6A0;
}

.offers-section-difficulty.easy-offers.marked {
    border: none;
    background: linear-gradient(85.33deg, #349B80 -20.5%, #06D6A0 96.23%);
}

.offers-section-difficulty.medium-offers {
    border: 1px solid #FFD166;
}

.offers-section-difficulty.medium-offers.marked {
    border: none;
    background: linear-gradient(85.33deg, #E1AA2A -20.5%, #FFD166 86.88%);
}

.offers-section-difficulty.hard-offers {
    border: 1px solid #EF476F;
}

.offers-section-difficulty.hard-offers.marked {
    border: none;
    background: linear-gradient(85.33deg, #BE3758 -20.5%, #EF476F 96.23%);
}

.green-provider-container {
    background: #10667F;
}

/* .white-pink-red-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translate(-50%, -50%);
    border: 0px solid transparent;
    border-radius: 20px;
    background: radial-gradient(ellipse at 91% 82%, rgba(182, 224, 245, 0.51) 41%, rgba(255, 172, 199, 0.5) 81%);
} */
.card-cloud{
    fill: rgba(255, 172, 199, 0.5);
}
.green-provider-container.new {
    background: #fff;
}

.list-app-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 56px);
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
    text-align: left;
    align-items: center;
    padding: 16px 0;
}

.list-offer-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 50px;
    margin-right: 25px;
    width: calc(100% - 75px);
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

.list-offer-container.special {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.list-offer-container.extra-offer {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.list-offer-container.extra-offer:last-of-type {
    border: none;
}

.single-list-offer-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 56px);
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
}

.list-offer-container.favourite-offer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: calc(100% - 42px);
    margin-left: 22px;
    margin-right: 20px;
}

.list-offer-container.favourite-offer.completed {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), linear-gradient(120.01deg, #5352B5 35.38%, #9F7DDB 70.88%);
    box-shadow: 0px 1px 6px rgba(137, 113, 208, 0.5);
    border-radius: 8px;
}

.list-offer-container.favourite-offer.incomplete {
    outline: 1px dashed #5352B5;
    box-shadow: 0px 1px 6px rgba(137, 113, 208, 0.5);
    border-radius: 8px;
}

.list-offer-container.favourite-offer:not(.desktop):last-child {
    border-bottom: none;
}

.list-offer-container.recommended-offer {
    background: white;
    width: calc(100% - 80px);
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #EA81B6;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 18px;
}

.list-offer-container.unavailable-offer {
    background: white;
    width: calc(100% - 80px);
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #EA81B6;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 18px;
    position: relative;
}

.list-offer-container.unavailable-offer>div.list-offer-container-image {
    pointer-events: none;
    position: static;
}

.list-offer-container.unavailable-offer>div.list-offer-container-button {
    pointer-events: none;
    position: static;
}

.list-offer-container.unavailable-offer>div>div>div {
    pointer-events: none;
}

.featured-offer-root,
.special-offer-root {
    margin-left: 9px;
    margin-right: 9px;
    width: calc(100% - 26px);
    text-align: left;
    background: transparent;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 6px;
    z-index: 3;
    position: relative;
    margin-bottom: 6px;
}

.special-offer-root {
    box-shadow: none;
    background: none;
}

.list-offer-container.featured-offer {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.featured-offer-root.desktop.discord {
    color: #fff;
    background: #EA81B6;
    border-radius: 14px;
    box-shadow: 0 2px 24px rgba(141, 49, 228, .35);
    margin-bottom: 6px;
    margin-left: 9px;
    margin-right: 9px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 6px;
    position: relative;
    text-align: left;
    width: calc(100% - 26px);
    z-index: 3;
}
.featured-offer-root.desktop.discord .list-offer-app-name,
.featured-offer-root.desktop.discord .list-offer-description{
    color: #fff;
    font-weight: 600;
}
.daily-earning-list {
    position: relative;
    margin: 0 15px;
}

.daily-earning-list-container {
    padding-bottom: 10px;
}

.absolute-blurred,
.absolute-blurred-offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    color: #141629;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
}

.absolute-blurred-offer {
    gap: 15px;
    z-index: 4;
    backdrop-filter: blur(8px);
    border-radius: 13px;
    top: 5px;
    left: 15px;
    right: 15px;
    bottom: 5px;
}

.absolute-blurred-offer.row {
    flex-direction: row;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.absolute-blurred-offer.full-width {
    left: 0;
    right: 0;
}

.absolute-blurred-offer .absolute {
    position: absolute;
    top: 10px;
    right: 10px;
}

.absolute-blurred-offer.row .absolute {
    position: absolute;
    top: 10px;
    right: 30px;
}

.absolute-blurred p,
.absolute-blurred-offer p {
    margin: 0;
}

.absolute-blurred p.underline {
    text-decoration: underline;
}

.list-offer-container.daily {
    width: auto;
    padding: 5px;
    padding-left: 14px;
    position: relative;
}

.list-offer-container.daily .absolute-icon {
    position: absolute;
    bottom: calc(50% - 10px);
    left: 10px;
}

.list-offer-container.daily .absolute-line {
    position: absolute;
    left: 20px;
}

.list-offer-container.daily .absolute-line.below {
    top: calc(50% + 10px);
    z-index: 1;
}

.list-offer-container.daily .absolute-line.above {
    bottom: calc(50% + 10px);
    z-index: 1;
}

.list-offer-container.daily {
    margin: 0;
    margin-bottom: 12px !important;
}

.list-offer-container.featured-offer>.list-offer-container-button>.list-offer-button>p {
    color: #ffffff;
}

.offer-details-container {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: flex-start;
    margin-left: 21px;
    margin-right: 21px;
    width: calc(100% - 42px);
}

.list-offer-container-image {
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
    margin-right: 16px;
}

.list-offer-container-titles {
    display: flex;
    align-items: center;
}

.list-offer-container-difficulty-mark {
    width: 1px;
    height: 40px;
    background: #000000;
    display: flex;
}

.list-offer-container-difficulty-mark.green {
    background: #06D6A0;
}

.list-offer-container-difficulty-mark.yellow {
    background: #FFD166;
}

.list-offer-container-difficulty-mark.red {
    background: #EF476F;
}

.list-offer-container-difficulty {
    display: flex;
    margin-left: auto;
    align-items: center;
    text-align: center;
    width: 96px;
}

.list-offer-container-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
}

.list-offer-container-unlimited {
    margin-left: auto;
    position: relative;
}

.list-offer-app-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    margin-bottom: 8px;
    text-align: left;
}

.list-offer-app-name.multi-event {
    width: max-content;
}

.list-offer-description,
.hot-offer-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    padding-right: 16px;
    text-align: left;
    overflow: hidden;
}

.one-two-treat-area .list-offer-description{
    max-width: 90px;
}

.list-offer-description.black {
    color: #141629 !important;
}

.list-offer-description.daily {
    font-size: 12px;
    color: #141629 !important;
}

.list-offer-hash-tags {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    margin-top: 8px;
    text-align: left;
}

.list-offer-hash-tags.completed-offer {
    padding-right: 8px
}

.list-offer-hash-tags.featured-offer,
.list-offer-hash-tags.favourite-offer {
    color: #FFFFFF;
}

.list-completed-offer-root {
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
    padding-top: 12px;
    padding-bottom: 6px;
}

.list-completed-offer-root.no-border {
    border: none;
}

.list-completed-offer-container {
    display: flex;
    justify-content: flex-start;
}

.details-hash-tags {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    margin-left: 21px;
    margin-top: 16px;
    margin-bottom: 20px;
}

.what-to-do {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    margin-left: 21px;
    margin-top: 32px;
    margin-bottom: 11px;
}

.offer-details-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #141629;
    opacity: 0.7;
    margin-left: 21px;
    margin-right: 21px;
    max-width: 75vw;
    width: calc(100% - 42px);
    margin-top: 11px;
    overflow: hidden;
}

.offer-details-unlimited-container {
    margin-left: 21px;
    margin-right: 21px;
    width: calc(100% - 42px);
    display: flex;
    align-items: center;
}

.offer-details-percentage-container {
    margin-left: 21px;
    margin-right: 21px;
    width: calc(100% - 42px);
    display: flex;
    align-items: center;
}

.offer-details-daily-container {
    margin-left: 21px;
    margin-right: 21px;
    width: calc(100% - 42px);
    display: flex;
    align-items: center;
}

.offer-details-unlimited-sign {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: right;
    color: #141629;
    opacity: 0.75;
    display: none;
    padding-bottom: 20px;
    margin-right: 5px;
}

.offer-details-percentage-sign {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: right;
    color: #141629;
    opacity: 0.75;
    display: none;
    padding-bottom: 20px;
    margin-right: 5px;
}

.offer-details-daily-sign {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: right;
    color: #141629;
    opacity: 0.75;
    display: none;
    padding-bottom: 20px;
    margin-right: 5px;
    align-items: center;
}

.offer-details-unlimited {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    padding-bottom: 20px;
    display: none;
}

.offer-details-percentage {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    padding-bottom: 20px;
    display: none;
}

.offer-details-daily {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    padding-bottom: 20px;
    display: none;
}

.offer-details-action-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.offer-referral-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.offer-referral-content {
    position: absolute;
    top: 60px;
    right: 0px;
    color: #141629;
    width: 259px;
    padding: 15px 11px 25px 23px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 14px rgba(141, 49, 228, 0.25);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    z-index: 5;
    visibility: hidden;
}

.offer-referral-content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: 17px;
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}

.offer-referral-content.visible {
    visibility: visible;
}

#logo-menu-div {
    width: 50%;
    text-align: left;
    float: left;
}

#exit-menu-div {
    width: 50%;
    text-align: right;
    float: left;
    padding-top: 10px;
}

.home-div {
    width: 100%;
    z-index: 2;
    background-color: #141629;
}

.intro-content {
    width: calc(100% - 84px);
    position: absolute;
    bottom: 132px;
    left: 50%;
        transform: translateX(-50%);
    z-index: 3;
    padding-top: 13px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(159 125 219 / 15%);
    border-radius: 38px;
}

.password-requirements-container {
    padding-bottom: 16px;
}

.steps-div {
    padding-top: 24px;
    padding-bottom: 24px;
}

.step-number+div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
    margin-top: -90px;
}

.step-number-image {
    position: relative;
}

.step-image-position {
    position: absolute;
    bottom: 10px;
    transform: translateX(100%);
    right: 50%;
}
.step-image2 {
    display: inline-block;
    vertical-align: bottom;
}

.step-image2:nth-child(2) {
    margin-bottom: -5px;
    z-index: 10;
}

.scroll-to-top-container {
    display: flex;
    justify-content: end;
    background-color: #141629;
    padding: 25px 15px 10px 0;
}

div.rights-reserved {
    margin-top: 8px;
    margin-bottom: 20px;
    color: #7674F5;
}

span.rights-reserved {
    color: #7674F5;
}

div.copyright-div {
    margin-top: 8px;
}

.login-footer {
    padding-bottom: 40px;
}

.login-footer-copy,
.login-footer-rights {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.login-footer-copy {
    color: #282B54;
}

.login-footer-rights {
    color: #7674F5;
}

.list-offers-div-wrapper {
    background: white;
}

.list-offers-div-wrapper>.cashback {
    width: 90%;
    margin: auto;
}

.list-offers-div-wrapper.cashback-wrapper {
    min-height: 53vh;
    padding-top: 10px;
    padding-bottom: 100px;
}

.homepage-container .list-offers-div-wrapper.cashback-wrapper {
    padding-bottom: 20px;
}

.list-offers-div-wrapper.cashback-wrapper.transparent {
    background-color: transparent;
}

.list-offers-div {
    text-align: center;
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: 16px;
}

.list-offers-div.filled {
    background: white;
}

.offer-details-div {
    background-color: white;
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    text-align: left;
}

.offer-details-div-overlay.hidden,
.offer-details-div.hidden {
    display: none;
}

#offer-details-data {
    overflow: auto;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
}

.offer-details-icon-container {
    background-color: #141629;
    height: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.offer-details-completed-users-container {
    background: linear-gradient(180deg, rgba(135, 184, 157, 0.5) 0%, rgba(78, 147, 109, 0.5) 100%);
    backdrop-filter: blur(7px);
    border-radius: 8px;
    padding: 7px 30px;
    position: absolute;
    bottom: 18px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    display: none;
}

.offer-details-completed-users-container.visible {
    display: block;
}

.providers-div {
    text-align: left;
}

.app-wrapper {
    background-color: white;
}

.app-wrapper.focused {
    border: 2px solid rgba(234, 129, 182, 0.75);
}

.provider-list-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.menu-section {
    border-top: 1px solid #4a507f;
    max-height: 0;
    overflow: hidden;
}

.menu-section.open {
    max-height: 300px;
    -webkit-transition: max-height 0.5s ease-in;
    -moz-transition: max-height 0.5s ease-in;
    -o-transition: max-height 0.5s ease-in;
    transition: max-height 0.5s ease-in;
    overflow-y: auto;
}

.total-reward-wrapper {
    position: relative;
    margin-top: 8px;
}

.total-reward-wrapper.leaderboards {
    margin-left: auto;
    margin-right: auto;
}

.total-reward-description-div {
    position: relative;
}

.total-reward-description-wrapper {
    background: linear-gradient(126.82deg, rgba(192, 232, 255, 0.4) 0.03%, rgba(192, 232, 255, 0.2) 113.88%);
    filter: drop-shadow(0px 8px 30px #080A1A);
    backdrop-filter: blur(30px);
    border-radius: 25px;
    margin-bottom: 24px;
    height: 155px;
    opacity: 0.8;
}

.total-reward-description-wrapper.leaderboards {
    opacity: 0.9;
}

.first-three-places-container {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    width: calc(100% - 24px);
    margin-left: 12px;
    margin-right: 12px;
}

.reward-wrapper {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

#refer-friends-subtitle {
    z-index: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

#leaderboards-subtitle {
    max-width: 80%;
    margin: 0 auto;
    z-index: 3;
    padding-top: 35px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

#leaderboards-title {
    position: relative;
    z-index: 3;
}

#providers-subtitle {
    margin-top: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    text-align: center;
    background: linear-gradient(153.31deg, #A24ED3 8.4%, #7326F5 80.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.faq-style-wrapper .faq-body .faq-row {
    padding: 16px;
    border-bottom: none;
    background-color: #282C4A;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 15px;
}

.faq-style-wrapper .faq-body .row-content {
    border-top: 1.5px #4A507F solid;
    text-align: justify;
}

.faq-style-wrapper .faq-body .row-content .row-content-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    text-align: left;
    margin-top: 8px
}

.faq-style-wrapper .faq-body .faq-row .row-title .icon-wrapper {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
}

.leaderboards-wrapper {
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    border-radius: 38px;
    padding-bottom: 20px;
}

.refer-friends-container {
    background: linear-gradient(138.74deg, rgba(137, 56, 185, 0.35) 7.25%, rgba(98, 25, 222, 0.35) 65.79%);
    border-radius: 38px;
    width: 70%;
    margin: 30px auto;
    padding: 10px 20px 32px;
}

.highscore-data-container {
    z-index: 4;
    position: relative;
}

.top3-container {
    text-align: center;
    width: calc(100% + 50px);
    margin-left: -25px;
    position: relative;
    background-image: url(images/highscore-graphic.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% auto;
}

.first-place-container {
    position: relative;
    width: 33.4%;
    text-align: center;
    float: left;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding-bottom: 64px;
}

.first-place-container .first-place-container-img-container {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
}

.second-place-container {
    position: relative;
    width: 33.3%;
    text-align: center;
    float: left;
    margin-top: 46px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 38px;
    padding-bottom: 16px;
}

.second-place-container .second-place-container-img-container {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    position: relative;
}

.third-place-container {
    position: relative;
    width: 33.3%;
    text-align: center;
    display: inline-block;
    margin-top: 46px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 38px;
    padding-bottom: 16px;
}

.third-place-container .third-place-container-img-container {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    position: relative;
}

.place-highscore-absolute {
    position: absolute;
    top: 7px;
    left: 5px;
}

.place-highscore-absolute.first {
    top: -50px;
    left: -30px;
}

.highscores-div {
    position: relative;
    border-radius: 38px;
    background: #ffffff;
    padding: 12px 25px 25px 25px;
    width: 100%;
    z-index: 4;
}

div.history {
    filter: grayscale(77.5%);
}

.table-div {
    margin-top: 13px;
    border-radius: 38px;
    max-height: 75vh;
    overflow-y: auto;
    width: 100%;
    background: #F6F7FB;
}

.table-div.homepage {
    max-height: 420px;
}

.my-score-div {
    margin-top: 36px;
    margin-bottom: 16px;
    border-radius: 38px;
    overflow-y: auto;
    width: 100%;
    background: #FFFFFF;
}

.highscores-display-container {
    z-index: 2;
    position: relative;
    width: calc(100% - 50px);
    margin-left: 25px;
}

.highscores-display-end-description {
    margin: 10px 20px;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-size: 8px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    opacity: 0.5;
}

.coins-history-section {
    position: relative;
}

.month-total-container {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    margin-top: 36px;
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding-right: 16px;
    padding-left: 16px;
}

.month-total-container.earning.desktop,
.month-total-container.payout.desktop {
    display: none;
}

.month-total-container-coins {
    display: flex;
    align-items: center;
}

.month-total-container-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding: 16px;
}

.monthly-treat-coins-amount-container {
    padding-top: 14px;
    padding-bottom: 16px;
}

.monthly-treat-coins-breakdown-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.monthly-treat-coins-breakdown-container.dashed-top {
    padding-top: 16px;
    border-top: 0.5px dashed rgba(255, 255, 255, 0.3);
}

.monthly-treat-coins-breakdown-item {
    width: calc(50% - 10px);
    height: auto;
    text-align: center;
}

.bottom-breakdown .monthly-treat-coins-breakdown-item {
    display: flex;
    place-content: center;
    align-items: flex-end;
    gap: 6px;
}

.lifetime-usable-treat-coins-container {
    padding-top: 14px;
    padding-bottom: 16px;
}

.coins-month-selection-wrapper {
    position: relative;
    margin-top: 16px;
    width: 175px;
    margin-left: auto;
    margin-right: auto;
}

.referral-select {
    position: relative;
    margin: 0 auto 20px;
    width: 175px;
}

.coins-month-selection-wrapper-desc {
    content: "Filtered by:";
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #BBA7EF;
    background: transparent;
    width: 100%;
    position: absolute;
    right: calc(100% - 30px);
    top: 0;
}

.coins-month-selection-wrapper::after,
.referral-select::after {
    content: "\25BC";
    color: #FFFFFF;
    font-size: 10px;
    top: 40%;
    right: 15px;
    position: absolute;
    z-index: 2;
}

.subscription-settings-options-section,
.activity-preferences-options-section,
.account-details-section {
    width: calc(100% + 25px) !important;
}

.activity-preferences-options-section {
    padding-bottom: 30px;
}

.join-options-container {
    width: 100%;
}

.join-option-div {
    border: 1px solid #282B54;
    border: 1px solid rgba(40, 43, 84, 0.15);
    border-radius: 18px;
    text-align: left;
    padding: 8px 16px 8px 16px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    min-height: 50px;
    position: relative;
    margin-left: 3px;
    margin-right: 6px;
}

.join-option-div svg {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 5%;
}

.success-svg {
    fill: #5FC88F;
}

.join-disclaimer {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0A0B14;
    text-align: left;
    margin-top: 40px;
    width: calc(100% - 6px);
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    text-align: center;
}
.earning-entry-wrapper {
    border-radius: 17px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
    position: relative;
    box-shadow: inset 50px 51px 100px 4px rgba(182, 224, 245, 0.51),inset -40px -30px 80px 16px rgba(255, 172, 199, 0.5)!important;
}

.gradient-green {
    box-shadow: inset 50px 51px 100px 4px rgba(127, 212, 255, 0.4),inset -40px -30px 80px 16px rgba(117, 255, 209, 0.5)!important;
}
.gradient-brown {
    box-shadow: inset 50px 51px 100px 4px rgba(182, 224, 245, 0.5),inset -40px -30px 80px 16px rgba(255, 149, 50, 0.5)!important;
}
.gradient-yellow {
    box-shadow: inset 50px 51px 100px 4px rgba(181, 159, 248, 0.5),inset -40px -30px 80px 16px rgba(255, 255, 0, 0.5)!important;
}
.gradient-pink {
    box-shadow: inset 50px 51px 100px 4px rgba(181, 159, 248, 0.5),inset -40px -30px 80px 16px rgba(255, 172, 199, 0.5)!important;
}

.earning-entry-wrapper-part {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.earning-entry-wrapper-part.first {
    padding-bottom: 16px;
}

.earning-entry-wrapper-part.second {
    padding-bottom: 16px;
}

.homepage-swiper .earning-entry-wrapper-part.second {
    padding-bottom: 0;
}

.coins-wrapper-loading {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 17px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.earning-entry-wrapper-part.first>.earning-entry-left-section {
    text-align: left;
}

.earning-entry-wrapper-part.first>.earning-entry-right-section {
    text-align: right;
    width: 70%;
}

.earning-entry-wrapper-part.second>.earning-entry-left-section {
    text-align: left;
    width: 70%;
}

.earning-entry-wrapper-part.second>.earning-entry-right-section {
    text-align: right;
    width: 30%;
}

.more-offers-link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    text-decoration-line: underline;
    color: #000;
    opacity: 0.8;
    padding-top: 16px;
    border-top: 0.5px dashed rgba(255, 255, 255, 0.3);
}

.treatcoin-payout-entry-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding: 25px;
}

.earning-entry-wrapper.earning-page.desktop,
.treatcoin-payout-entry-wrapper.payout-page.desktop {
    display: none;
}

.treatcoin-payout-entry-wrapper.homepage {
    padding: 10px;
}

.treatcoin-payout-entry-wrapper.pink {
    background-color: #A0699B;
}

.treatcoin-payout-entry-wrapper.brown {
    background-color: #A1858E;
}

.treatcoin-payout-entry-wrapper.green {
    background-color: #6B9283;
}

.treatcoin-payout-entry-wrapper.yellow {
    background-color: #9F9E8A;
}

div.treatcoin-payout-entry-wrapper.earning {
    border-radius: 17px;
}

.treatcoin-payout-entry-left-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.treatcoin-payout-entry-right-section {
    text-align: left;
    width: 70%;
}

.no-completed-offers,
.no-available-offers {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #141629;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
    border-radius: 38px;
    padding: 32px;
    margin-top: 16px;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.no-available-offers.hidden {
    display: none;
}

.notification-icons-container {
    position: relative;
    height: 32px;
}

.the-treat-coin-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
}

.the-treat-coin-item {
    width: calc(50% - 16px);
}

.refer-options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    width: calc(100% - 56px);
    margin-left: auto;
    margin-right: auto;
}

.refer-option-item {
    margin-left: 20px;
    margin-right: 20px;
}

.refer-copy-link-container {
    text-align: center;
    position: relative;
}

.refer-share-container {
    text-align: center;
    padding-bottom: 36px;
    display: flex;
    justify-content: space-evenly;
}

.refer-component-container {
    z-index: 4;
    position: fixed;
    width: calc(100% - 70px);
    max-width: 346px;
    height: auto;
    bottom: 75px;
    right: 15px;
    background: url(images/refer-component-background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    padding-right: 20px;
    padding-left: 20px;
}

.offer-completion-disclaimer-container {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 18px;
    width: 332px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26px;
    margin-bottom: 26px;
    padding: 13px 40px 13px 54px;
    position: relative;
}

.refer-hidden-component {
    background: url(images/refer-component-background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.favourite-offers-container-wrapper {
    width: 100%;
    padding-bottom: 32px;
    background-color: #141629;
    position: relative;
}

.favourite-offers-container {
    width: calc(100% + 50px);
    margin-left: -25px;
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    border-radius: 38px;
    padding-top: 31px;
    padding-bottom: 31px;
    min-height: 99px;
    position: relative;
}

.difficulty-popup-image-container {
    z-index: 4;
    position: absolute;
    top: -52px;
    right: -32px;
    display: none;
}

.expand-popup-image-container {
    z-index: 4;
    position: absolute;
    top: 12px;
    right: -14px;
    display: none;
}

.unlimited-indicator-popup-image-container {
    z-index: 4;
    position: absolute;
    top: 60px;
    right: -14px;
    display: none;
}

.daily-indicator-popup-image-container {
    z-index: 4;
    position: absolute;
    top: 13px;
    right: -10px;
    display: none;
}

.single-list-offer-container.favourite-offer {
    width: 325px;
    margin-left: auto;
    margin-right: auto;
}

.swipe-down-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 64px;
    margin-top: -16px;
    touch-action: none;
    margin: 0 auto;
}

.small-spinner {
    transform: scale(0.35);
}

.offers-list-spinner {
    transform: scale(0.35);
}

.withdrawal-calculator-spinner {
    transform: scale(0.25);
}

.spinner-container {
    text-align: center;
}

.offers-spinner-container {
    text-align: center;
    background: white;
    width: calc(100% + 50px);
    margin-left: -25px;
    flex-grow: 1;
}

.coins-list-swipe {
    overflow-y: auto;
}

.max-coins-container-wrapper-parent {
    position: absolute;
    top: 16px;
    left: 0;
    z-index: 3;
    overflow-x: hidden;
    padding-right: 20px;
    opacity: 0;
    overflow-x: hidden;
}

.max-coins-container-wrapper-parent.shown {
    display: block;
    animation: fade-in 2.1s;
    -webkit-animation: fade-in 2.1s;
    -moz-animation: fade-in 2.1s;
    -o-animation: fade-in 2.1s;
}

.max-coins-container-wrapper {
    overflow: hidden;
    transform: skew(20deg);
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: #6F17C3;
    margin-left: -300px;
}

.max-coins-container {
    text-align: left;
    padding: 8px 32px;
    transform: skew(-20deg);
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
}

.max-coins-amount {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    text-align: left;
}

.max-coins-amount-number {
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    display: inline-block;
}

.max-coins-container-wrapper-parent.shown>div>div>div>.max-coins-amount-number {
    animation: scale-in 2.1s;
    -webkit-animation: scale-in 2.1s;
    -moz-animation: scale-in 2.1s;
    -o-animation: scale-in 2.1s;
}

.max-coins-container-wrapper-parent.shown>div {
    animation: move-in-left 2.1s;
    -webkit-animation: move-in-left 2.1s;
    -moz-animation: move-in-left 2.1s;
    -o-animation: move-in-left 2.1s;
}

.max-coins-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #DA9EB0;
    text-align: left;
}

.one-two-treat-teaser {
    bottom: 106px;
    position: fixed;
    right: 10px;
    z-index: 6;
    width: 224px;
    height: 76px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(139, 113, 209, 0.25);
    border-radius: 40px;
}

.refer-teaser-container {
    bottom: 76px;
    position: fixed;
    right: 0px;
    z-index: 6;
}

.share-teaser-container {
    bottom: 74px;
    position: absolute;
    right: 0px;
    z-index: 6;
}

.stats-component-container {
    z-index: 6;
    position: fixed;
    max-width: 346px;
    margin-bottom: 10px;
    bottom: 135px;
    right: 15px;
    background: url(images/stats-component-background.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.stats-component {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 15px 0 18px !important;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-radius: 52px;
    padding-top: 4px;
}

.stats-component::-webkit-scrollbar {
    display: none;
}

.user-stats {
    padding: 20px;
    padding-top: 5px;
}

.user-stats * {
    box-sizing: border-box;
}

.user-stats-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-stats-header-container.homepage {
    gap: 15px;
    justify-content: center;
}

.gradient-user-stats-header {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border: 1px solid transparent;
    border-image-slice: 1;
    border-radius: 26px;
    max-width: 145px;
    max-height: 92px;
}

.user-stats-header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 26px;
    padding: 30px 10px 5px 10px;
    line-height: 120%;
    max-width: 190px;
    width: 100%;
    height: 88px;
}

.user-stats-header p {
    margin: 0;
    overflow: scroll;
}

.one-two-treat-area p {
    overflow: visible;
}

.user-stats-header-logos {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
}

.user-stats-header-logos.one-two-treat {
    color: #141629;
}

.user-stats-expandable {
    margin: 5px 0 0 0;
    position: relative;
    height: 28px;
}

.user-stats-details {
    position: relative;
}

.user-stats-details>hr {
    border-bottom: 1px solid #141629;
    opacity: 0.1;
    margin: 0;
}

.stats-component-texts {
    position: absolute;
    background: #FFFFFF;
    top: 0;
    left: -5px;
    width: 100%;
    z-index: 10;
    border: 1px solid #6260E1;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 12px;
    padding: 30px 20px 20px;
    padding: 15px 20px 15px;
}

.stats-component-text-item {
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #0A0B14;
    margin-top: 10px;
}

.stats-component-text-item>span:last-child {
    margin-left: 15px;
}

.stats-component-text-item span:not(.bold) {
    opacity: 0.7;
}

.stats-component-text-item span.bold {
    font-weight: 600;
}

.stats-component-text-item+hr {
    border: none;
    border-bottom: 1px solid #141629;
    opacity: 0.1;
    margin-top: 12px;
}

.stats-options-container,
.stats-component-links {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}

.stats-component-links>.copy-text {
    position: absolute;
    bottom: -10px;
    right: 26px;
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    -webkit-text-fill-color: #5352B5;
    opacity: .5;
}

.stats-option-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.stats-component-links {
    padding: 0 20px 10px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.stats-component-link-item {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border: 1px solid #cdced5;
    border-radius: 12px;
    max-width: 120px;
}

.favourite-offers-list {
    position: relative;
}

.timer-container {
    width: calc(100% - 40px);
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    border: 1px solid #EA81B6;
    box-shadow: 0px 2px 8px rgba(159, 125, 219, 0.35);
    border-radius: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 16px;
}

.home-timer-container {
    width: calc(100% - 40px);
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.home-timer-shadow {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px 25px 38px 38px;
    background: linear-gradient(180deg, rgba(234, 255, 254, 0.5) 0%, rgba(205, 201, 241, 0.8) 100%);
    backdrop-filter: blur(6px);
}

.playground-heading {
    margin-bottom: 36px;
}

.timer-counter-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-timer-heading {
    position: fixed;
    width: 100%;
    z-index: 6;
    bottom: 32px;
}

.home-timer-heading.hidden {
    display: none;
}

.completed-offer-list-container {
    margin-left: 25px;
    margin-right: 25px;
    padding-left: 4px;
    padding-right: 12px;
    width: calc(100% - 50px);
    background: #ffffff;
}

.completed-offer-list-container.brands-expanded {
    width: calc(100% - 50px);
    background: #EBEDF5;
    border-radius: 12px;
    border-bottom: 1px solid rgba(20, 22, 41, 0.1);
}

.completed-offers-more {
    max-height: 0;
    transition: all 0.8s ease-in-out;
}

.completed-offers-more.expanded {
    max-height: 100vh;
}

.exchange-treat-coins-container {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.exchange-treat-coins-section {
    width: calc(50% - 8px);
}

.exchange-treat-coins-content {
    width: 100%;
    min-height: 330px;
    padding-top: 32px;
    padding-bottom: 32px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
}

.withdraw-text-container {
    padding-top: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawal-input-container {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
    background: #FFFFFF;
}

.conversion-input-container {
    background: #FFFFFF;
}

.withdrawal-amount-options-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 16px;
}

.withdraw-digits-container,
.convert-digits-container {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 75px 75px 75px;
    grid-gap: 26px;
    justify-content: center;
}

.flex-ui-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 0 auto;
}

.flex-ui-container p,
.flex-ui-container h4 {
    padding: 0;
    margin: 0;
}

.withdrawal-payment-methods-container {
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 16px;
    margin-bottom: 16px;
}

.withdrawal-payment-method {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    position: relative;
    width: calc(33.3% - 8px);
    min-height: 126px;
}

.withdrawal-payment-method.highlighted {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.payment-method-cta-div {
    background: linear-gradient(84.36deg, rgba(83, 82, 181, 0.4) -11.65%, rgba(159, 125, 219, 0.4) 98.43%);
    backdrop-filter: blur(16px);
    border-radius: 12px;
    min-height: 87px;
    position: absolute;
    top: 11px;
    left: 8px;
    width: calc(100% - 56px);
    display: flex;
    align-items: center;
    padding: 9px 30px 15px 10px;
}

.verification-code-expire-time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #141629;
    opacity: 0.5;
}

.recommended-offer-container {
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    border-radius: 38px;
    width: calc(100% + 50px);
    margin-left: -25px;
    position: relative;
    z-index: 2;
    margin-top: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
}

.recommender-details {
    margin-top: 8px;
}

.recommender-details-text-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.recommended-offer-not-available {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #DA9EB0;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 18px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.share-offer-frame.hidden {
    display: none;
}

.share-offer-container {
    margin-bottom: 11px;
    margin-top: -10px
}

.share-offer-container:hover {
    cursor: pointer;
}

.share-teaser-container.hidden {
    display: none;
}

.daily-indicator-container-completed {
    position: absolute;
    bottom: 0;
    left: -26px;
    display: flex;
    align-items: center;
}

.daily-indicator-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-horizontal {
    z-index: 0;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps-div-container {
    display: block !important;
}

.registration-verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #EA81B6;
    margin: 0;
    opacity: 0.5;
}

.home-body-padding {
    padding: 45px 10px 0 20px;
}

.one-two-treat-area>.copy-text {
    position: absolute;
    font-size: 16px;
    top: -40%;
    right: 26px;
}

.z-1300 {
    z-index: 1301 !important;
}

.cookie-popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    z-index: 11;
    bottom: 103px;
    left: 0;
    right: 0;
    padding: 16px;
    border-radius: 38px;
    background: linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%);
    box-shadow: 0px 1px 5px 1px rgba(159, 125, 219, 0.35);
}

.cookie-popup-overlay {
    display: block;
    width: 100%;
    height: 9999px !important;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.stats-earning-section {
    display: flex;
    flex-direction: column;
}

.desktop.slide-next-btn,
.desktop.slide-prev-btn,
.hashtag-wrapper.desktop,
.playground-footer.desktop,
.offers-desktop-view {
    display: none;
}

.playground-footer.mobile {
    margin-top: -20px;
}

.cookie-modal {
    display: none;
    position: fixed;
    z-index: 11;
    top: 69px;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(168.42deg, #EC79FF -62.34%, #141629 32.46%, #141629 47.97%, #5F26A8 128.61%);
}
.scroll-to-top-container.desktop.completed-offers {
    display: none;
}

.exchange-treat-coins-container.desktop {
    display: none;
}

.offer-guide-video-overlay {
    background-color:#fff; 
    width:100%; 
    height: 100%; 
    position: absolute; 
    background-color: rgba(0, 0, 0, 0.65);
}

.swal2-modal{
    border-radius: 38px !important;
}

.swal2-icon.withdrawal-message-icon{
    margin-top: 1.5em;
    margin-bottom: 0;
}

.swal2-html-container.withdrawal-message {
    margin-top: 0;
    margin-bottom: 0;
}

.bg-decor-join {
    bottom: -140px !important;
}

@media screen and (min-height: 840px) {
    .bg-decor-join {
        bottom: -80px !important;
    }
}
.offer-popup-stock-wrapper {
    border-radius: 14px;
    width: 100vw;
    max-width: 727px;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 50px;
    top: 10%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    max-height: 0;
    background: rgba(0, 0, 0, 0.80);
    -webkit-backdrop-filter: blur(10px);
    opacity: 0;
    backdrop-filter: blur(10px);
        -webkit-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
        -moz-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
        -o-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
        transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
}

.offer-popup-stock-wrapper.closed {
    z-index: 1;
    padding: 0 !important;
    top: -10%;
}

.offer-popup-stock-wrapper.open {
    z-index: 21;
    opacity: 1;
    /* padding: 140px 15px 15px 15px; */
    height: 100%;
    max-height: 200vh;
    display: flex;
        top: 64%;
}

.offer-popup-stock-body {
    width: 90%;
    max-width: 724px;
    position: fixed;
    padding-left: 12px !important;
    padding-right: 12px !important;
    top: 40%;
    right: 50%;
    transform: translate(50%, -50%);
}
.offer-popup-stock-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    padding: 10px;
}
.offer-popup-stock-head h5{
    max-width: 80%;
}
.offer-popup-stock-head img {
    max-height: 21px;
}
.offer-popup-stock-list-wrapper{
    padding: 12px 4px;
    margin-bottom: 20px;
}
.fixed-popup-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    overflow-y: auto;
    padding: 140px 15px 15px 15px;
    background: rgba(19, 21, 34, 0.50);
    backdrop-filter: blur(10px);
}

.fixed-popup-wrapper .additional-offers-head-img {
    top: 170px;
    width: 300px;
    height: 278px;
}

.withdrawal-confirm-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 90vw;
    max-width: 600px;
    height: 275px;
    padding: 25px;
    margin: 0 auto;
    border-radius: 38px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(159, 125, 219, 0.15);
}

.container-small{
    max-width: 650px;
    margin: 0 auto;
}

.star-rating-wrap{
    display: flex;
}

.star-rating {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    height: 17px;
    width: 17px;
    position: relative;
    background-size: cover;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMDcwMTQgMTUuOTg1Nkw2LjI3NDI1IDEwLjc4MDFMMi4yMzU4NCA3LjI3ODk0TDcuNTcwOTkgNi44MTU4MUw5LjY0NTc3IDEuOTA2NzRMMTEuNzIwNSA2LjgxNTgxTDE3LjA1NTcgNy4yNzg5NEwxMy4wMTczIDEwLjc4MDFMMTQuMjIxNCAxNS45ODU2TDkuNjQ1NzcgMTMuMjI1NEw1LjA3MDE0IDE1Ljk4NTZaIiBmaWxsPSIjRDlEOUQ5Ii8+Cjwvc3ZnPgo=");
}
.star-rating.filled{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMDcwMTQgMTUuOTg1Nkw2LjI3NDI1IDEwLjc4MDFMMi4yMzU4NCA3LjI3ODk0TDcuNTcwOTkgNi44MTU4MUw5LjY0NTc3IDEuOTA2NzRMMTEuNzIwNSA2LjgxNTgxTDE3LjA1NTcgNy4yNzg5NEwxMy4wMTczIDEwLjc4MDFMMTQuMjIxNCAxNS45ODU2TDkuNjQ1NzcgMTMuMjI1NEw1LjA3MDE0IDE1Ljk4NTZaIiBmaWxsPSIjRkFDNDAxIi8+Cjwvc3ZnPgo=");
    background-size: cover;
} 

.editable .star-rating{
    cursor: pointer;
    position: relative;
    border-radius: 99px;
}

.editable .star-rating:hover,
.editable .star-rating:has(~ .star-rating:hover)  {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMDcwMTQgMTUuOTg1Nkw2LjI3NDI1IDEwLjc4MDFMMi4yMzU4NCA3LjI3ODk0TDcuNTcwOTkgNi44MTU4MUw5LjY0NTc3IDEuOTA2NzRMMTEuNzIwNSA2LjgxNTgxTDE3LjA1NTcgNy4yNzg5NEwxMy4wMTczIDEwLjc4MDFMMTQuMjIxNCAxNS45ODU2TDkuNjQ1NzcgMTMuMjI1NEw1LjA3MDE0IDE1Ljk4NTZaIiBmaWxsPSIjRkFDNDAxIi8+Cjwvc3ZnPgo=");
    transform: scale(1.5);
}

.editable .star-rating.filled{
    transform: scale(1.5);
}

.editable .star-rating::before {
    content: '';
    position: absolute;
    top:-10px; bottom:-10px; 
    left:-10px; right:-10px; 
    cursor: pointer;
}

.star-rating.filled.pulse {
    animation: pulse 0.6s forwards ease-out;
    box-shadow: 0px 0px 0 0 rgba(250, 196, 1, 0.9);
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(250, 196, 1, 0.6);
   }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(250, 196, 1, 0);
   }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 196, 1, 0);
   }
}
@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 -10px rgba(250, 196, 1, 0.6);
        box-shadow: 0 0 0 -10px rgba(250, 196, 1, 0.6);
   }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(250, 196, 1, 0);
        box-shadow: 0 0 0 10px rgba(250, 196, 1, 0);
   }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(250, 196, 1, 0);
        box-shadow: 0 0 0 0 rgba(250, 196, 1, 0);
   }
}
 
.whatsapp-popup-container{ 
    max-width: 500px;
    transform: scale(0.8);
}

.whatsapp-popup-container .phone-number-wrap{ 
    max-width: 340px;
    margin: auto;
}

.terms-popup,
.how-it-works-popup {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    display: none;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    z-index: -1;
    -webkit-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
    -moz-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
    -o-transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
    transition: all 0.6s ease-in-out, opacity 0.4s ease-in-out;
}


.terms-popup.opened,
.how-it-works-popup.opened {
    display: flex;
    opacity: 1;
    z-index: 22;
}

.terms-popup-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3vh;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: 997px;
    overflow: scroll;
    max-width: 682px;
    background: #131522;
    padding-bottom: 35px;
}

.how-it-works-body {
    max-width: 584px;
}
.terms-popup-head-wrapper{
    height: 84px;
}

.terms-popup-steps{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terms-popup-bg-image {
    top: 10px;
    z-index: 10;
    width: 95vw;
    background-size: cover;
    flex-shrink: 0;
    opacity: 0.4;
    overflow: hidden;
    mix-blend-mode: color-dodge;
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-width: 619.093px;
    max-height: 402.514px;
}
    
    .terms-popup-bg-image.step-0 {
}

.term-popup-bordered-outlined-heading {
    margin: 0 auto;
    margin-top: 40px;
    padding: 8px 16px;
    width: 90%;
    max-width: 327px;
    border-bottom-right-radius: 24px;
    background: rgba(0, 0, 0, 0.30);
}
.terms-popup-images {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
}
.how-it-works-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: fit-content;
    padding: 26px 40px;
    width: 90vw;
    height: fit-content;
    max-height: 1000px;
    max-width: 585px;
    border-radius: 16px;
    background: #222337;
    box-shadow: 0px 0px 8px 0px #6260E1;
}

.how-it-works-card {
    color: #FFF;
    padding: 10px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-radius: 14px;
    background: #131522;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

.how-it-works-card:nth-child(even) {
background: rgb(69, 37, 98);
    background: -moz-linear-gradient(145deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: -webkit-linear-gradient(145deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: linear-gradient(145deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#452562", endColorstr="#131522", GradientType=1);
}

.how-it-works-card:nth-child(odd) {
background: rgb(69, 37, 98);
    background: -moz-linear-gradient(225deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: -webkit-linear-gradient(225deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: linear-gradient(225deg, rgba(69, 37, 98, 1) 5%, rgba(19, 21, 34, 1) 28%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#452562", endColorstr="#131522", GradientType=1);
}
.stats-row {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 15px;
}

.stats-card {
    width: 100%;
        max-width: 200px;
        padding: 10px 2px 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
background: rgb(66, 29, 99);
    background: -moz-linear-gradient(139deg, rgba(66, 29, 99, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: -webkit-linear-gradient(139deg, rgba(66, 29, 99, 1) 5%, rgba(19, 21, 34, 1) 28%);
    background: linear-gradient(139deg, rgba(66, 29, 99, 1) 5%, rgba(19, 21, 34, 1) 28%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#421d63", endColorstr="#131522", GradientType=1);
}

.stats-card .flex {
    width: 100%;
    padding-left: 10%;
    align-items: center;
    justify-content: flex-start;
}
.green-outline {
    box-shadow: 0px 0px 12px 2px rgba(93, 243, 168, 0.74);
        -webkit-box-shadow: 0px 0px 12px 2px rgba(93, 243, 168, 0.74);
        -moz-box-shadow: 0px 0px 12px 2px rgba(93, 243, 168, 0.74);
}
.red-outline {
    box-shadow: 0px 0px 12px 2px rgba(248, 28, 18, 0.75);
    -webkit-box-shadow: 0px 0px 12px 2px rgba(248, 28, 18, 0.75);
    -moz-box-shadow: 0px 0px 12px 2px rgba(248, 28, 18, 0.75);
}
.stats-image-container {
    border-radius: 11px;
    border: 1.5px solid #8D31E4;
    width: 33px;
    height: 33px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%, 75%;
    margin-bottom: 4px;
    position: relative;
}
.terms-loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(10px);
}

.offer-popup-reviews-list-item-desc{
    position: relative;
    width: 100%;
}
.offer-popup-reviews-list-image {
    border-radius: 8px;
    background: linear-gradient(90deg, #289BFD 0%, #3AAECA 100%);
    width: 80px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-popup-reviews-list-item-desc .flex{
    justify-content: space-between;
}

.whatsup-popup-main-title{
    font-size: 20px;
}

.whatsup-popup-sub-title{
    font-size: 16px;
}

.whatsapp-popup-container .confirm-button{
    margin: 20px auto  0;
    max-width: 240px;
}

.code-verification-wrap{
    display: flex;
    gap:13px;
    justify-content: center;
}

.whatsapp-popup-container .confirm-button:disabled,
.text-disabled{
    opacity: 0.5;
    cursor: inherit!important;
}

.code-verification-wrap.invalid-verification input{
    border-color: #D13C3C!important;
}

.phone-select-with-countries {
    display: flex;
    align-items: center;
}

.phone-select-with-countries .select-container.open-screen {
    display: flex;
    margin: 0;
    height: 100%;
    position: relative;
    border-radius: 99px 0 0 99px;
    padding-right: 10px;
}

.phone-select-with-countries .select-container.open-screen:hover {
    background: #f0f0ff;
}

.phone-select-with-countries .select-container-static.open-screen {
    margin: 0;
}

.phone-select-with-countries .select-item-container, .phone-select-with-countries .select-container-static.open-screen {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    
    border: 0;
    padding: 0;
    align-items: center;
    cursor: pointer;
}

.phone-select-with-countries .select-item-container {
    padding: 5px 0 5px 10px;
    gap: 3px;
}

.phone-select-with-countries .select-item-container:first-child {
    padding-top: 10px;
}

.phone-select-with-countries .select-item-container:hover {
    background: #e5e4e4;
}

.phone-select-with-countries .select-items p{
    margin-bottom: 0;
}

.phone-select-with-countries .select-option-icon {
    padding: 0 0 0 12px;
    position: inherit;
}

.phone-select-with-countries .select-option-icon.open-screen img {
    left: 60px;
    position: relative;
}

.phone-select-with-countries .select-container-static.open-screen span {
    margin-right: 0;
    color: #000;
    position: relative;
    left: -8px;
    text-wrap: nowrap;
}

.phone-select-with-countries .select-items.open-screen {
    max-height: 160px;
}

.phone-select-with-countries .select-items.open-screen {
    position: absolute;
    background: #fff;
    z-index: 9;
    margin: 0;
    display: flex;
    flex-direction: column;
    top: 45px;
    padding: 0;
    min-width: 113px;
}

.phone-select-with-countries .select-item-container svg, .phone-select-with-countries .select-container svg {
    height: 15px;
    margin-right: 0;
    width: auto;
}

.form-input-phone input::placeholder {
    opacity: 0.5;
  }

.border-rounded{
    border-radius: 38px!important;
}

.max-w-600{
    max-width: 600px;
}

.overflow-none{
    overflow: inherit!important;
}

.remove-br br{
    content: ' ';
    display: inline-block;
    width: 6px;
}

.whatsup-popup-request-new{
    font-size: 16px;
    margin-top: 15px;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
}


.code-verification-wrap input.code-verification-input, 
.disabled-verification-input {
    border: 2px solid #9A7AD8;
    border-radius: 19px;
    outline: none;
    width: 44px;
    height: 68px;
    color:#000;
    font-size: 24px;
    padding: 0;
}

.disabled-verification-input{
    background: #BEBEBE;
    border-color: #BEBEBE!important;
}

.affiliates-section {
    padding-right: 16px;
    padding-left: 16px;
    position: relative;
    margin-bottom: 48px;
}

.affiliates-shadow {
    position: absolute;
    top: -10px;
    left: -10vw;
    width: 110vw;
    height: 70vh;
    max-height: 800px;
background: rgb(234, 129, 182);
    background: -moz-radial-gradient(circle, rgba(234, 129, 182, 0.31976540616246496) 1%, rgba(20, 22, 41, 1) 69%);
    background: -webkit-radial-gradient(circle, rgba(234, 129, 182, 0.31976540616246496) 1%, rgba(20, 22, 41, 1) 69%);
    background: radial-gradient(circle, rgba(234, 129, 182, 0.31976540616246496) 1%, rgba(20, 22, 41, 1) 69%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea81b6", endColorstr="#141629", GradientType=1);
    
}

.affiliates-designed-for-wrapper {
    padding: 30px 18px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 20px 30px;
}

.carousel-container {
    position: relative;
    margin-top: 16px;
    width: 100%;
    height: 72px;
    overflow: hidden;
    margin-bottom: 16px;
}

.carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.carousel-item.active {
    opacity: 1;
}
.affiliates-feedback-item {
    display: flex;
    justify-content: center;
    gap: 12px;
}

.affiliates-feedback-item .flex {
    gap: 12px;
}

.carousel-marquee{
    display: none;
}

.carousel-indicators {
    display: flex;
    gap: 4px;
    justify-content: center;
    margin-bottom: 64px;
}

.affiliates-tools-item {
    display: flex;
    gap: 16px;
    padding: 8px 16px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.affiliates-ready-to-join {
    padding: 12px 42px 50px 42px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.affiliates-footer-links{
    display: flex;
    flex-direction: column;
}

.affiliates-footer-row{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 8px;
}
.offer-popup-coins-when-pays-container {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
    background: var(--Deep-Blue, #2E3B57);
    position: relative;
}
.offer-popup-coins-when-pays-tooltip {
    position: absolute;
    opacity: 0;
    z-index: 0;
    transition: all 0.5s ease-in-out;
    display: inline-block;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    bottom: -50px;
    right: -10px;
    transform: translateY(50%);
    max-width: 250px;
}

.offer-popup-coins-when-pays-tooltip-arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
}

.offer-popup-coins-when-pays-tooltip::before {
    content: '';
    position: absolute;
    bottom: -12px;
    /* Adjust to move the shadow closer or farther */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid rgba(0, 0, 0, 0.1);
    /* Arrow shadow */
}

.offer-popup-coins-when-pays-container:hover .offer-popup-coins-when-pays-tooltip {
    opacity: 1;
    z-index: 5;
}
@media (min-width: 410px){
    .whatsapp-popup-container {
            transform: scale(1);
        }
}

@media screen and (max-width: 1024px) {
    .flex-ui-container {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {

    .max-coins-container-wrapper-parent.right {
        left: unset;
        right: 0;
        padding-right: 0px;
        padding-left: 20px;
    }

    .max-coins-container-wrapper.right {
        margin-left: 0px;
        margin-right: -300px;
    }

    .max-coins-container-wrapper-parent.shown.right>div {
        animation: move-in-right 2.1s;
        -webkit-animation: move-in-right 2.1s;
        -moz-animation: move-in-right 2.1s;
        -o-animation: move-in-right 2.1s;
    }

    .exchange-treat-coins-content {
        min-height: 270px;
    }

    .steps-div-container {
        max-width: 75%;
        margin: 0 auto;
    }

    .providers-homepage {
        padding: 0 20px;
    }

    .providers-homepage-card-container {
        justify-content: center;
    }

    .providers-homepage-card {
        width: 50%;
    }

    .list-offers-div-wrapper>.cashback {
        width: 50%;
        margin: auto;
    }

    .absolute-blurred-offer.row .absolute {
        position: absolute;
        top: 10px;
        right: 25px;
    }

    .cookie-popup-container {
        width: 500px;
        margin-left: 0;
        margin-right: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (min-width: 480px) {
    .list-offer-description{
        max-width: 250px;
    }
}

@media screen and (min-width: 412px) {
    .completed-layout {
        width: 98%;
    }
}
@media screen and (min-width: 580px) {
    .list-offer-description{
        max-width: 300px;
    }
}

@media screen and (min-width: 627px) {
    .affiliates-shadow {
            width: 700px;
                height: 700px;
                left: 45%;
                top: -10px;
                transform: translateX(-50%);
    }
    .list-offer-description{
        max-width: 360px;
    }
}

@media screen and (min-width: 727px) {
    .list-offer-description{
        max-width: 380px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
    .more-offers-link {
        bottom: 10%;
        position: absolute;
        right: 20%;
    }
}


@media (min-width: 682px) {
    .terms-popup-body {
        border-radius: 32px;
        max-height: 667px;
    }
}
@media screen and (min-height: 1024px) and (min-width: 682px) {
    .terms-popup-body.step-4 {
        max-height: 755px;
    }
}
@media (min-width: 1024px) {

.user-stats-header-container.homepage {
    gap: 30px;
    justify-content: start;
}

.affiliates-footer-links {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        gap: 75px;
        margin-bottom: 33px;
    }
    .affiliates-ready-to-join {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .carousel-container {
        position: relative;
        margin-top: 24px;
        height: 110px;
    }

    .carousel-item {
        height: 200px;
    }

    .affiliates-feedback-item {
        gap: 20px;
    }

    .affiliates-feedback-item .flex {
        gap: 20px;
    }

    .carousel-indicators {
        gap: 10px;
    }

    .withdrawal-confirm-body {
        gap: 20px;
        height: 358px;
        padding: 25px;
    }

    .coins-month-selection-wrapper::after,
    .referral-select::after {
        top: 50%;
    }

    .one-two-treat-area>.copy-text {
        bottom: 46px;
        right: 10px;
    }

    .join-disclaimer {
        padding-right: 25px;
        max-width: 720px;
    }

    .my-earnings.earning-entry-wrapper-part.second {
        padding-bottom: 16px;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: space-between;
        gap: 20px;
    }

    .my-earnings.earning-entry-wrapper-part.first {
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 80px;
    }

    .list-offer-description {
        max-width: 560px;
    }

    .footer-mobile {
        display: none;
    }

    .treatcoin-payout-entry-wrapper .treatcoin-payout-entry-left-section{
        width: auto;
    }

    .treatcoin-payout-entry-right-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .treatcoin-payout-entry-wrapper.payout-page.desktop {
        display: flex;
    }

    .earning-entry-wrapper.earning-page.desktop {
        display: block;
    }

    .content-playground {
        background: #fff;
    }

    .special-offers-container.desktop {
        width: calc(70% - 40px);
        margin: 0 auto;
    }

    .special-offers-container.desktop .background {
        margin-top: -25px;
    }

    .special-offers-child.desktop {
        justify-content: space-between;
        gap: 0;
    }

    .special-div.desktop {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-right: 15px;
    }

    .special-header.desktop {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .special-offer-modal-title.desktop {
        color: #FFF;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .featured-offer-root.desktop,
    .special-offer-root.desktop {
        border-radius: 14px;
    }

    .list-offer-container.featured-offer>.list-offer-container-button.desktop>.list-offer-button.desktop>p {
        color: inherit;
    }

    .earning-entry-wrapper.homepage.desktop {
        border-radius: 30px;
    }

    .special-offers-container.desktop {
        margin: 0 auto;
        padding: 0;
    }

    .swal2-container.special-offers-container.desktop {
        padding: 0;
        border-radius: 0;
        width: 100vw;
        height: 100vh;
    }

    .swal2-modal.special-offers-popup.desktop {
        background: var(--gradient-3-rare, linear-gradient(104deg, #8938B9 0%, #6219DE 100%));
        min-height: 100vh;
        width: 100vw !important;
        border-radius: 0 !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .special-offer-content.desktop {
        margin-top: -70px;
    }

    .steps-div-container {
        max-width: 75%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .steps-div {
        width: 255px;
        margin-left: 16px;
        display: inline-flex;
        flex-direction: column;
    }

    .step-number-image {
        min-height: 200px;
    }

    .providers-homepage-menu-container {
        gap: 5px 20px !important;
    }

    .providers-homepage-menu:not(.cashback) {
        width: 200px !important;
    }

    .offers-section-difficulty {
        width: 88px;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        border-radius: 16px;
    }

    div.swal2-html-container {
        margin: 45px 20px 20px;
    }

    section.content-page-section {
        padding-top: 135px;
    }

    .content-subtitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #5FC88F;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    div.copyright-div {
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        margin-right: 8px;
    }

    div.rights-reserved {
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        display: inline-block;
    }

    .earning.payout div.rights-reserved {
        font-size: 18px;
    }

    .treatcoin-description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    .faq-style-wrapper .faq-body .faq-row {
        width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    .page-container>.faq-row-wrapper>.faq-body>.faq-row {
        text-align: center;
        width: 790px;
        margin-left: auto;
        margin-right: auto;
    }

    .faq-style-wrapper .faq-body .row-content .row-content-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .swal2-modal {
        width: 790px !important;
    }

    .swal2-modal.medium-size {
        width: 600px !important;
    }

    .swal2-modal.website-swal-modal {
        width: 500px !important;
    }

    .swal2-modal .form-input {
        max-width: inherit !important;
    }

    .offer-details-div {
        width: 790px;
        height: auto;
        max-height: 95vh;
        box-shadow: 0px 4px 4px rgba(159, 125, 219, 0.15);
        border-radius: 38px;
        margin-left: auto;
        margin-right: auto;
        top: 24px;
        left: calc(50% - 395px);
    }

    .offer-details-div-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 9999vh;
        width: 100%;
        background: #000000;
        opacity: 0.5;
    }

    #offer-details-data {
        height: 530px;
        margin-top: 24px;
        padding-right: 100px;
        padding-left: 100px;
    }

    .offer-details-completed-users-container {
        font-size: 18px;
        line-height: 140%;
    }

    .list-offer-container-difficulty {
        width: 50%;
    }

    .offer-details-icon-container {
        width: 100%;
        border-radius: 38px 38px 0px 0px;
    }

    .offer-completion-disclaimer-container {
        width: 538px;
    }

    .offers-list-difficulty {
        width: 100%;
        max-width: 400px;
    }

    .offers-section-list.desktop {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 550px;
    }

    .list-offers-div {
        width: 540px;
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    .list-offers-div.desktop {
        width: 70%;
    }

    .favourite-offers-container.desktop {
        width: 70% !important;
        background: linear-gradient(104deg, #8938B9 0%, #6219DE 100%);
    }

    .list-offer-container.favourite-offer.desktop {
        margin-left: 30px;
        margin-right: 30px;
        width: auto;
    }

    .list-offers-div-wrapper {
        margin-left: -25px;
        margin-right: -25px;
    }

    .list-offers-div-wrapper.cashback-wrapper {
        margin-left: 0;
        margin-right: 0;
    }

    div.reward-payout-entry-wrapper {
        width: 516px;
        margin-left: auto;
        margin-right: auto;
    }

    div.treatcoin-payout-entry-wrapper {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    div.treatcoin-payout-entry-wrapper.earning {
        padding-right: 50px;
        padding-left: 10px;
    }

    .treatcoin-payout-date-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        text-align: right;
    }

    div.treatcoin-payout-entry-wrapper.homepage {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .desktop.slide-next-btn {
        position: absolute;
        top: calc(25% + 10px);
        right: 51%;
        background: transparent;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        border: none;
    }

    .desktop.slide-prev-btn {
        position: absolute;
        top: calc(25% + 10px);
        left: -15px;
        background: transparent;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        border: none;
    }

    .account-details-section {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .subscription-settings-section,
    .activity-preferences-section,
    .account-security-section {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .subscription-settings-section{
        width: 100%;
    }

    .account-security-section {
        width: auto;
    }

    .what-to-do {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .difficulty-fragment {
        display: inline;
        margin-right: 4px;
    }

    .join-option-div {
        width: 316px;
        display: inline-flex;
    }

    #telegram-join-option {
        padding-top: 6px;
        padding-bottom: 10px;
    }

    .join-disclaimer {
        margin-top: 32px;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.01em;
    }

    .providers-div {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin-top: 16px;
        margin-bottom: 16px;
        width: calc(100% - 50px);
        margin-left: 25px;
    }

    .provider-list-wrapper {
        display: inline-block;
        width: 325px;
        margin-right: 54px;
    }

    .provider-list-wrapper:last-of-type {
        margin-right: 0;
    }

    .leaderboards-wrapper {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .homepage-container,
    .leaderboards-container {
        padding-left: 165px;
        padding-right: 165px;
    }

    .my-score-div {
        width: calc(100% - 500px);
        min-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .top3-container {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .total-reward-wrapper {
        min-width: 340px;
        margin: 0 auto;
    }

    .joined-user-indicator {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .menu-drawer {
        width: 300px;
        height: fit-content;
    }

    .intro-content {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) !important;
    }

    #main.light-mode {
        background: url(images/login-desktop-background.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 100% 0;
    }

    .checkbox-container.terms-container:not(.register) {
        margin-left: 150px;
    }

    .homepage-rewards {
        text-align: left;
        width: 540px;
        margin: 0 auto;
    }

    .total-reward-description-div.homepage {
        width: 540px;
        margin: 0 auto;
    }

    .page-container.the-treatcoin-page-container {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    .page-container.coins-history-container {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .total-reward-text {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .first-three-places-container {
        background: linear-gradient(135deg, rgba(192, 232, 255, 0.40) 0%, rgba(192, 232, 255, 0.00) 100%);
        box-shadow: 0px 4px 30px 0px #121424;
        width: 540px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        filter: drop-shadow(0px 4px 30px #121424);
        backdrop-filter: blur(15px);
        border-radius: 25px;
        padding: 10px 32px 32px;
    }

    .first-three-places-container .first-image {
        width: 100px;
        height: 100px;
    }

    .the-treat-coin-section:last-child {
        margin-top: 0;
    }

    .the-treat-coin-item {
        width: calc(100% - 16px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        padding-top: 32px;
        padding-bottom: 32px;
        text-align: left;
    }

    .the-treat-coin-section:last-child>.the-treat-coin-item:last-child {
        border-bottom: none;
    }

    .no-completed-offers,
    .no-available-offers {
        width: 275px;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        margin-left: auto;
        margin-right: auto;
    }

    .coins-report {
        display: flex;
        align-items: center;
    }

    .earning-entry-wrapper {
        width: 80%;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding: 16px 30px;
    }

    .earning-entry-wrapper.earning-page.desktop,
    .treatcoin-payout-entry-wrapper.payout-page.desktop {
        width: 80%;
        box-sizing: border-box;
    }

    .treatcoin-payout-entry-wrapper.earning-page.desktop {
        background-color: #fff;
        width: 80%;
    }

    .divided-between-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    .total-reward-text {
        font-weight: 600;
        font-size: 60px;
        line-height: 125%;
    }

    .scroll-to-top-container.mobile.offers-list {
        display: none;
    }

    .scroll-to-top-container.desktop.completed-offers {
        display: block;
        background: #fff;
        width: 100%;
        margin: -20px -25px 20px;
        padding: 10px 25px;
    }

    .scroll-to-top-container.desktop.completed-offers .scroll-to-top-container {
        background: #fff;
        width: 100%;
    }

    .see-all-offers-button-container.desktop {
        display: none;
    }

    .scroll-to-top-container.my-treat-coin-earnings {
        width: 1010px;
        margin-left: auto;
        margin-right: auto;
    }

    .scroll-to-top-container.my-treat-coin-payouts {
        width: 1010px;
        margin-left: auto;
        margin-right: auto;
    }

    .scroll-to-top-container.my-treat-coin-earnings.mobile,
    .scroll-to-top-container.my-treat-coin-payouts.mobile {
        visibility: hidden;
        margin: 0;
        padding: 0;
        border: none;
    }

    .favourite-offers-container.offers-list {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
    }

    .coins-month-selection-wrapper,
    .referral-select {
        width: 540px;
    }

    .month-total-container {
        width: 80%;
        margin: 40px auto;
        background: none;
        display: flex;
        gap: 40px;
        box-shadow: none;
    }

    .treat-coins-total-wrap, .treat-coins-detailed-wrap {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        background: hsla(0, 0%, 100%, .1);
        border-radius: 38px;
        box-shadow: 0 4px 4px 0 rgba(159, 125, 219, .15);
        box-sizing: border-box;
        flex: 50% 1;
        max-width: 50%;
        justify-content: flex-start;
    }

    .month-total-container.payout .treat-coins-total-wrap{
        padding: 40px 20px 16px;
    }

    .treat-coins-detailed-wrap{
        padding: 60px 40px;
    }

    .treat-coins-total-wrap .treat-coins-validity-hint-icon{
        padding: 0;
    }

    .monthly-treat-coins-breakdown-container.dashed-top {
        padding-top: 0;
        border-top: 0;
    }

    .month-total-container.earning.mobile,
    .month-total-container.payout.mobile {
        display: none;
    }

    .month-total-container.earning.desktop,
    .month-total-container.payout.desktop {
        width: 80%;
        margin: 40px auto;
        display: flex;
        justify-content: space-between;
        gap: 40px;
        background: unset;
        box-shadow: none;
        border-radius: 0;
    }
        .month-total-container.earning {
            width: 100%;
        }

    .month-total-container.earning.desktop>.card,
    .month-total-container.payout.desktop>.card {
        max-width: 50%;
        box-sizing: border-box;
        flex: 50%;
        border-radius: 38px;
        background: rgba(255, 255, 255, .1);
        box-shadow: 0px 4px 4px 0px rgba(159, 125, 219, 0.15);
    }

    .month-total-container.earning.desktop>.month-total-container-right,
    .month-total-container.payout.desktop>.month-total-container-right {
        padding: 60px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .month-total-container-title {
        font-size: 30px;
        line-height: 45px;
    }

    .coins-wrapper-loading {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
        padding: 16px;
    }

    .refer-teaser-container {
        bottom: 73px;
    }

    .favourite-offers-container {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
    }

    .exchange-treat-coins-container {
        width: 715px;
        margin-left: auto;
        margin-right: auto;
    }

    .exchange-treat-coins-container.mobile {
        display: none;
    }

    .exchange-treat-coins-container.desktop {
        width: 80%;
        display: flex;
        flex-wrap: nowrap;
        gap: 30px;
    }

    .exchange-treat-coins-container.desktop .card {
        height: 220px;
        padding: 30px;
        gap: 20px;
        border-radius: 38px;
        background: rgba(255, 255, 255, .1);
        box-shadow: 0px 4px 4px 0px rgba(159, 125, 219, 0.15);
    }

    .exchange-treat-coins-container.desktop ul.exchange-treat-coins-list {
        width: 100%;
        margin-top: 20px;
        padding: 0;
        font-size: 12px;
    }

    .exchange-treat-coins-section {
        width: calc(50% - 16px);
    }

    .exchange-treat-coins-content {
        min-height: 325px;
    }

    .exchange-treat-coins-content-first.desktop,
    .exchange-treat-coins-content-second.desktop {
        padding: 20px 0;
    }

    .withdrawal-payment-methods-container {
        width: 715px;
        margin-left: auto;
        margin-right: auto;
    }

    .withdrawal-payment-methods-container.desktop {
        width: 80%;
        margin: 30px auto;
        gap: 30px;
    }

    .withdrawal-payment-method.desktop {
        box-sizing: border-box;
        flex: 30%;
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 0 50px 0 30px;
    }

    .withdrawal-payment-method.desktop .withdrawal-payment-method-icon,
    .withdrawal-payment-method.desktop .withdrawal-payment-method-text {
        margin: 0;
        text-align: left;
        font-size: 16px;
    }

    .withdrawal-payment-method.desktop .withdrawal-payment-method-hint-icon {
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .treat-coins-balance-indicator.desktop {
        margin-top: 0;
    }

    .withdrawal-input-container,
    .conversion-input-container {
        width: 715px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
    }

    .withdrawal-input-container.desktop {
        width: 100%;
        border-radius: 0;
        padding: 30px 30px 0;
        border-bottom: 1px solid white;
    }

    .withdraw-text-container.desktop {
        margin-top: 0;
        padding-top: 50px;
    }

    .withdraw-digits-container {
        grid-template-columns: 55px 55px 55px;
        grid-gap: 55px;
    }

    .withdraw-digits-container.desktop {
        grid-gap: 15px 70px;
    }

    .withdrawal-input-container.desktop .complete-withdraw-button {
        margin-top: 80px;
    }

    .convert-digits-container {
        grid-template-columns: 55px 55px 55px;
        grid-gap: 30px 80px;
    }

    .payment-methods-list-hint {
        font-weight: 400;
        font-size: 16px;
    }

    .payment-method-cta-div.desktop {
        border-radius: 12px;
        background: linear-gradient(42deg, rgba(83, 82, 181, 0.40) 0%, rgba(159, 125, 219, 0.40) 88.35%);
        backdrop-filter: blur(16px);
        width: 50%;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 0;
    }

    .verification-code-expire-time {
        font-weight: 400;
        font-size: 18px;
    }

    .list-offer-container.recommended-offer {
        width: 490px;
    }

    .list-offer-container.unavailable-offer {
        width: 490px;
    }

    .recommended-offer-not-available {
        font-size: 18px;
    }

    .treat-coin-equals-div-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding: 20px 0;
    }

    .treat-coin-equals-div-container .treat-coin-equals-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 30%;
    }

    .more-offers-link {
        font-size: 16px;
    }

    .broken-line-image-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .providers-homepage {
        padding: 0 20px;
    }

    .providers-homepage-card-container {
        justify-content: center;
    }

    .providers-homepage-card-container.desktop {
        gap: 30px;
    }

    .providers-homepage-menu-container.cashback .menu-container.desktop.playground {
        padding: 40px 160px 0;
    }

    .login-buttons-container {
        width: auto;
    }

    .daily-earning-popup-image {
        height: 615px;
    }

    .content-playground .page-container.desktop {
        display: flex;
        flex-direction: column;
    }

    .list-offers-div-wrapper>.cashback {
        width: 45%;
        margin: auto;
    }

    .list-offers-div-wrapper>.cashback.desktop.homepage {
        width: 60%;
    }

    .list-offers-div-wrapper>.cashback.desktop.playground {
        width: 100%;
    }

    .list-offers-div-wrapper.cashback-wrapper.desktop.homepage {
        min-height: unset;
    }

    .providers-homepage-menu-container.cashback.desktop.homepage .menu-container.desktop,
    .providers-homepage-menu-container.cashback.desktop.playground .menu-container.desktop {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .providers-homepage-menu-container.cashback.desktop.playground .menu-container.desktop {
        gap: 30px;
        justify-content: flex-start;
    }

    .providers-homepage-menu-container.cashback.desktop.playground .menu-container.desktop .cashback-subtitle.menu {
        padding-top: 0;
    }

    .providers-homepage-menu-container.cashback.desktop.homepage .menu-container.desktop .providers-homepage-menu {
        height: 130px;
        max-width: calc(33.3% - 10px);
        flex: 33.3%;
        align-items: flex-end;
        justify-content: flex-start;
    }

    .providers-homepage-menu-container.cashback.desktop.playground .menu-container.desktop .providers-homepage-menu {
        height: 130px;
        max-width: 45%;
        flex: 45%;
        margin: 0;
        justify-content: center;
        align-items: center;
    }

    .offers-div.playground {
        padding: 0 160px;
    }

    .offer-details-popup-info-text {
        padding: 40px;
    }

    .content-page-header.desktop.hidden {
        position: absolute;
        top: 0;
        margin-top: 30px;
        background: transparent;
    }

    .content-page-header.desktop.hidden .menu-drawer {
        margin-top: -30px;
    }

    .content-page-header.hidden .header-login-container {
        display: none;
    }

    .content-page-header.hidden .menu-bar {
        margin-left: 150px;
    }

    .content-page-header.desktop.hidden .content-page-logo {
        margin-top: 10px;
        width: 250px;
    }

    .stats-earning-section.desktop {
        flex-direction: row;
        gap: 35px;
        padding-bottom: 140px;
        align-items: flex-start;
    }

    .home-body-padding.desktop {
        padding: 80px 100px 0;
    }

    .home-body-container.desktop {
        width: fit-content;
        margin: 0;
    }

    .user-stats-header-container.display {
        justify-content: flex-start;
    }

    .daily-earning-plan.desktop.one-two-treat-area {
        margin: 5px 20px 25px 0;
        border-radius: 38px;
        width: 351px;
        height: 276px;
    }

    .daily-earning-background.desktop.one-two-treat-area {
        border-radius: 38px;
    }

    .polygon-absolute.desktop.one-two-treat-area {
        display: none;
    }

    .stats-component-container.desktop.one-two-treat-area {
        min-width: 355px;
        max-width: unset;
        max-height: unset;
        background: url(images/stats-component-background-desktop.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
    }

    .stats-component.desktop.one-two-treat-area {
        display: flex;
        overflow: visible;
    }

    .user-stats.desktop.one-two-treat-area {
        display: flex;
        padding-bottom: 25px;
    }

    .user-stats-header-container.desktop.one-two-treat-area {
        flex-direction: column;
        gap: 15px;
        justify-content: space-evenly;
    }

    .user-stats-header.desktop.one-two-treat-area {
        max-width: unset;
        max-height: unset;
        justify-content: center;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    .user-stats-header.desktop.one-two-treat-area .user-stats-header-logos {
        text-align: center;
        font-family: Poppins;
        font-size: 24.197px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .user-stats-expandable.desktop.one-two-treat-area {
        display: flex;
        align-items: center;
    }

    .user-stats-expandable.desktop.one-two-treat-area img {
        transform: rotate(-90deg);
    }

    .desktop-data-container.desktop.one-two-treat-area {
        max-width: 350px;
        border-radius: 38px;
        background: #FFF;
        box-shadow: 0px 1px 5px 0px rgba(159, 125, 219, 0.27);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 20px;
    }

    .desktop-data-container.desktop.one-two-treat-area hr.desktop-invisible {
        display: none;
    }

    .stats-component-texts.desktop.one-two-treat-area {
        top: -49px;
    }

    .stats-component-links.desktop.one-two-treat-area {
        padding: 0 0;
    }

    .daily-earning-plan.desktop.one-two-treat-area {
        margin: 5px 20px 25px 0;
        border-radius: 38px;
        width: 351px;
        height: 276px;
        overflow: auto;
        border-radius: 38px;
    }

    .one-two-treat-tab-item.daily.desktop.one-two-treat-area {
        border-bottom-right-radius: 10px;
    }

    .one-two-treat-tab-item.daily:not(.desktop.one-two-treat-area)::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: -1px;
        bottom: -1px;
        z-index: -1;
        background: #fff;
    }

    .offers-section-list.desktop {
        gap: 10px;
    }

    .offers-section-child>.highlight.desktop {
        background: #8d31e4;
        height: 8px;
        margin: 8px 0 0;
        width: 100%;
    }

    .offers-section-child.desktop,
    .tab.desktop {
        opacity: .2;
    }

    .offers-section-child.desktop.marked,
    .tab.desktop.marked {
        opacity: 1;
    }

    .hashtag-wrapper.mobile {
        display: none;
    }

    .hashtag-wrapper.desktop {
        display: block;
    }

    .single-list-offer-container.desktop {
        border-bottom: none;
    }

    .providers-homepage-card.mobile {
        display: none;
    }

    .playground-footer.mobile {
        display: none;
    }

    .playground-footer.desktop {
        padding-top: 30px;
        display: block;
        background: inherit;
    }

    .offers-mobile-view {
        display: none;
    }

    .offers-desktop-view {
        display: block;
    }

    @media (min-height: 901px) and (max-height: 950px) {
        .intro-content {
            transform: translateX(-50%), scale(0.9);
        }
    }

    @media (min-height: 851px) and (max-height: 900px) {
        .intro-content {
            transform: translateX(-50%), scale(0.85);
        }
    }

    @media (min-height: 801px) and (max-height: 850px) {
        .intro-content {
            transform: translateX(-50%), scale(0.80);
        }
    }

    @media (max-height: 800px) {
        .intro-content {
            transform: translateX(-50%), scale(0.75);
        }
    }

    @media (min-width: 800px) {
        .absolute-blurred-offer.row .absolute {
            top: 10px !important;
            right: 10px;
        }
    }

    @media (min-width: 1200px) {
        .absolute-blurred-offer.row .absolute {
            top: 10px !important;
            right: 25px;
        }
    }

    .absolute-blurred-offer.row+.offer-blurred-info {
        right: -55px;
        top: 30px;
    }

    .coins-month-selection-wrapper.filtered::before {
        right: calc(65% - 30px);
        font-size: 16px;
    }

    .cookie-popup-container {
        width: 500px;
        margin-left: 0;
        margin-right: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .homepage-rewards.desktop {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 50px;
    }

    .mail-title-container.desktop {
        display: none;
    }

    .list-offer-container-titles.desktop {
        width: 100%;
    }

    .list-offer-container-titles.desktop>div {
        flex-grow: 1;
    }

    .playground-footer.desktop footer {
        padding-top: 10px;
    }

    .list-offers-div-wrapper.cashback-wrapper.playground.desktop {
        min-height: unset;
        padding-top: 30px;
        padding-bottom: 30px;
        flex-grow: 1;
    }

    @media (min-width: 1440px) {

        .affiliates-section {
            margin-bottom: 80px;
        }

        .affiliates-shadow {
            width: 1200px;
            height: 1200px;
            left: 45%;
            top: 40px;
        }

        .affiliates-feedback-item {
                width: 378px;
            }

        .carousel-container{
            display: none;   
            justify-content: center;
        }
        .carousel-marquee{
            display: block;
        }
        .carousel-indicators {
            display: none;
        }

        .carousel-marquee-list{
            display: flex;
            width: 100%;
            gap: 20px;
        }

        .desktop.slide-next-btn {
            right: calc(50% + 10px);
        }

        .list-offers-div-wrapper>.cashback.desktop.playground {
            width: 75%;
        }

        .providers-homepage-menu-container.cashback.desktop.playground.selected .menu-container.desktop .providers-homepage-menu {
            max-width: 55%;
        }

        .homepage-rewards.desktop {
            gap: 100px;
        }

        /* .home-body-padding.desktop {
            padding: 80px 30px 0px 30px;
        } */

        .special-offer-modal-title.desktop {
            font-size: 32px;
        }

        .special-div.desktop {
            flex-direction: row;
            gap: 30px;
        }

        .see-offers.desktop {
            border-radius: 37.311px;
            padding: 16px 30px;
            font-size: 26px;
            gap: 15px;
        }

        .see-offers.desktop img {
            width: 24px;
            height: 27px;
        }

        .exchange-treat-coins-container.desktop ul.exchange-treat-coins-list {
            font-size: 14px;
        }

        .exchange-treat-coins-content-first.desktop,
        .exchange-treat-coins-content-second.desktop {
            padding: 20px;
        }

        .exchange-treat-coins-container.desktop .card {
            gap: 40px;
        }

        .subscription-settings-options-section,
        .activity-preferences-options-section,
        .account-details-section {
            width: calc(100% + 430px) !important;
        }
    }

    @media (min-width: 1540px) {

        .user-stats-header-container.desktop:not(.one-two-treat-area) {
            max-width: 735px;
            margin-bottom: 20px;
        }

        .user-stats-expandable {
            max-width: 735px;
        }

        .list-offers-div-wrapper>.cashback.desktop {
            width: 50%;
        }

        .special-div.desktop {
            padding-right: 50px;
        }

    }

    @media (min-width: 1700px) {

        .withdrawal-payment-methods-container.desktop,
        .exchange-treat-coins-container.desktop {
            width: 70%;
        }

        .homepage-rewards.desktop {
            gap: 150px;
        }

        /* .home-body-padding.desktop {
            padding: 100px 60px 0px 60px;
        } */
    }
}

@media screen and (min-width: 1400px) {
    .completed-layout {
        width: 97%;
        }
    .list-offer-description{
        max-width: auto;
    }
}
@media screen and (min-width: 1440px) {
        .month-total-container.earning {
                width: 80%;
            }
        .affiliates-ready-to-join {
                padding-top: 35px;
                padding-bottom: 35px;
            }
    .build-my-team-refer-friends-link-container img,
        .build-my-team-refer-friends-link-container button {
            height: 30px;
            width: 30px;
        }
    .build-my-team-refer-friends-link-container {
            border-radius: 60px;
            border: 4px solid #8D31E4;
            font-size: 16px;
            padding: 10px 12px !important;
        }
    
        .build-my-team-refer-friends-link-container.padding-6-16 {
            padding: 10px 16px !important;
        }
    .how-it-works-card {
        padding: 20px 8px;
        gap: 6px;
        border-radius: 14px;
        margin-bottom: 34px;
        position: relative;
        width: 100%;
    }

    .pink-bordered {
        padding: 12px;
    }

    .stats-row {
        margin-bottom: 25px;
    }

    .stats-image-container,
    .stats-row-desc {
        margin-bottom: 7px;
    }
}
@media screen and (min-width: 1600px) {
    .completed-layout {
        width: 103%;
    }
}

@media (max-width: 1024px) {

    .steps-div {
        padding-bottom: 35px;
    }

    .steps-div-container {
        display: block;
    }

    .step-number-image {
        min-height: 200px;
        padding: 0;
    }

    .step-description {
        width: 80%;
        margin: -10px auto 0 !important;
    }

    .treat-coin-equals-div {
        margin-bottom: 20px;
    }

    .list-offers-div-wrapper.cashback-wrapper {
        min-height: 62vh;
    }

    .mail-title-container.mobile {
        display: none;
    }

    .treatcoin-rewards-description-container {
        margin-bottom: -140px !important;
    }

    .total-reward-description-div.homepage {
        margin-left: 25px;
        margin-right: 25px;
    }
}

@media (max-height: 900px) {
    .stats-component {
        max-height: 75vh;
        margin-bottom: 55px;
    }
}

@media (max-height: 700px) {
    .stats-component {
        max-height: 70vh;
        margin-bottom: 50px;
    }
}

@media (max-height: 560px) {
    .stats-component {
        max-height: 65vh;
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {

    .steps-div-container {
        margin: 0 auto;
        max-width: 90%;
    }

    .upload-photos-card {
        min-width: 0;
    }

    @media (min-width: 667px) {
        .steps-div-container {
            max-width: 70%;
        }
    }

    .max-coins-container-wrapper-parent.right {
        left: unset;
        right: 0;
        padding-right: 0px;
        padding-left: 20px;
    }

    .max-coins-container-wrapper.right {
        margin-left: 0px;
        margin-right: -300px;
    }

    .max-coins-container-wrapper-parent.shown.right>div {
        animation: move-in-right 2.1s;
        -webkit-animation: move-in-right 2.1s;
        -moz-animation: move-in-right 2.1s;
        -o-animation: move-in-right 2.1s;
    }

    .treat-coin-equals-amount,
    .treat-coin-equals-amount-inactive {
        font-size: 30px !important;
    }

    .providers-homepage-card-container {
        flex-direction: column;
        align-items: center;
    }

    .providers-homepage-card {
        width: 75%;
    }

    .providers-homepage-card-image {
        padding-top: 20px;
    }

    .refer-friends-container {
        width: 85%;
    }

    .absolute-blurred-offer+.offer-blurred-info {
        right: 0;
        top: 40px;
    }
}

@media (max-width: 520px) {

    .special-offers-container .background {
        width: 200px;
    }

    .treatcoin-payout-entry-right-section {
        margin-left: 15px;
    }

    .monthly-treat-coins-breakdown-container {
        gap: 10px;
    }

    .coins-conversion-panel{
        font-size: 13px !important;
        padding: 3px !important;
    }

    .round-btn {
        padding: 12px 15px;
    }
}

@media (max-width: 400px) {

    .providers-homepage-menu-container {
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .providers-homepage-card-text {
        padding-left: 32px !important;
        padding-top: 14px !important;
    }

    .providers-homepage-card-image {
        padding-top: 40px !important;
    }

    .providers-homepage-card {
        width: 100%;
    }

    /* .user-stats-header {
        width: 135px;
    } */

    .stats-options-container,
    .stats-component-links {
        padding: 0;
    }

    .stats-component-links p {
        margin: 10px;
    }

    .stats-component-links>.copy-text {
        right: 6px;
    }

    .special-offers-child {
        justify-content: flex-start;
    }

    .offer-details-use-competition-container {
        margin: 20px 10px;
        gap: 10px;
    }

    .coins-month-selection-wrapper.filtered::before {
        width: 50%;
    }

    .coins-month-selection-wrapper.filtered::after {
        right: -25px;
    }

    .mobile-img {
        width: 100%;
    }

    .first-place-container .first-place-container-img-container {
        width: 80px;
        height: 80px;
    }

    .second-place-container .second-place-container-img-container,
    .third-place-container .third-place-container-img-container {
        width: 50px;
        height: 50px;
    }

    @media (max-width: 360px) {
        #leaderboards-subtitle {
            font-size: 38px;
        }

        .stats-component-container {
            max-width: 300px;
        }
    }
}
.stats-component {
    max-height: 68vh !important;
}
.stats-component.iOS {
    max-height: 60vh !important;
}