.menu-gold-benefits-btn {
    border-radius: 8px;
    font-size: 17px;
    line-height: 17px;
    padding: 3px 10px;
    margin-top: 17px;
    cursor: pointer;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    font-weight: 600;
    border: solid 1px #FFD993;
}

.gold-btn {
    border-radius: 5px;
    font-size: 12px;
    line-height: 12px;
    padding: 3px 5px;
    cursor: pointer;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    font-weight: 500;
    width:100%;
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
}

.gold-popup {
    color: #fff!important;
    width: 720px;
    border-radius: 5px !important;
}

.gold-popup .swal2-html-container{
    overflow: inherit;
}

.gold-color {
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.gold-popup-container {
    width: 520px;
    margin: 0 auto;
    position: relative;
}

.gold-popup-header{
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px 4px 24px 4px;
    padding: 6px;
    font-size: 18px;
    width: 460px;
    position: relative;
}

.gold-popup-header img {
    position: absolute;
    bottom: -9px;
    right: -58px;
}

.gold-shadow {
    box-shadow: 0px 0px 12px 0px rgba(238, 173, 24, 1);
}

.gold-benefits-title {
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 20px;
}

.gold-benefit {
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    padding: 9px;
}

.gold-border {
    position: relative;
}

.gold-border:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(0deg, #94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%);
    mask: linear-gradient(#94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%) content-box, linear-gradient(#94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%);
    -webkit-mask: linear-gradient(#94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%) content-box, linear-gradient(#94641E 4.17%, #FFF2DB 82.81%, #EAC885 93.75%, #FBF5D1 97.92%, #BD9A4B 99.99%, #FFF0DA 99.99%, #ECD8A3 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.gold-benefit-lable {
    font-weight: 700;
}

.gold-box-shadow{
    box-shadow: 0px 0px 8px 0px #EEAD18;
}

.gold-box-shadow .top-12-offer-page-card-desc {
    padding: 15px;
}

.gold-background {
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%)!important;
}

.gold-popup-btn {
    width: 340px!important;
    height: 40px!important;
    border: 0;
    border-radius: 8px!important;
    margin: 10px;
    font-size: 16px!important;
    font-weight: 600!important;
    color: #000!important;
    line-height: 16px!important;
}

.gold-ligth-bg {
    position: absolute;
    top: 50px;
    left: -30px;
    width: 1px;
    height: 1px;
    box-shadow: 10px 0px 190px 60px #FFF2DB;
    border-radius: 99px;
}

.gold-offer-badge {
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 12px;
    font-weight: 600;
    padding: 5px;
    
}

.gold-offer-badge-wrap {
    background: #000;
    position: absolute;
    top: -13px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 99px;
    box-shadow: 0px -2px 5px -2px #EEAD18;
    display: flex;
}

.offer-popup.is-gold .offer-popup-grey-container {
    border-radius: 8px;
    border: 1px solid #CB8113;
    background: #303149;
}

.offer-popup.is-gold .padding-4 {
    padding: 15px !important;
}

.offer-popup.is-gold .top-offer-homepage-usd-value-container,
.offer-popup.is-gold .top-offer-homepage-usd-desc,
.offer-popup.is-gold .padding-4,
.offer-popup.is-gold .top-offer-homepage-tc-desc {
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.offer-popup.is-gold .top-offer-homepage-tc-desc {
    padding-left: 38px;
}

.offer-popup.is-gold .top-offer-homepage-tc-value {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.offer-popup.is-gold .top-offer-homepage-usd-value {
    font-size: 12px;
    font-weight: 600;
}

.offer-popup-head-right-appname {
    font-size: 18px;
    max-width: 80%;
}

.offer-popup.is-gold .offer-popup-head-right-appname {
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.is-gold-button {
    color: #222337;
    font-size: 15px;
    font-weight: 600;
    border-radius: 18px;
    background: linear-gradient(0deg, #CB8113 4.17%, #FFD993 50%, #FFE39C 74.5%, #FFD699 87.5%, #F8C96D 99.99%, #EEAD18 100%);
}

.gold-box-shadow .top-12-offer-page-card-step button{
    margin-left: 0;
    margin-top: 30px;
}

.top-12-offer-page-card.gold-box-shadow .gold-btn{
    height: 60px;
    font-size: 17px;
}

.top-12-offer-page-card.offer-complete .gold-btn{
    opacity: 0.2;
}

.gold-box-shadow .top-12-offer-page-card-info{
    height: 100%;
    justify-content: start;
}

@media (max-width: 1024px) {
    .gold-popup {
        width: 600px;
    }
}

@media (max-width: 650px) {
    .gold-popup {
        width: 100%;
    }
    .gold-popup-mask {
        padding: 0;
    }
    .gold-popup-container {
        width: 100%;
    }
    .gold-popup-header {
        width: calc(100% - 70px);
        font-size: 16px;
    }
}