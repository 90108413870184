ul.legal-list {
    padding-inline-start: 24px;
}

ul.how-it-works-list {
    padding-inline-start: 24px;
    margin-bottom: 30px;
}

ul.how-it-works-list > li, ul.tips-list > li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
}

ul.tips-list > li {
    font-weight: bold;
}

ul.tips-sublist {
    padding-inline-start: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
}

ul.tips-sublist > li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
}

ol.treatcoin-usage-list {
    padding-inline-start: 14px;
    margin-bottom: 30px;
}

ol.treatcoin-usage-list > li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 16px;
}

ul.exchange-treat-coins-list {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    width: calc(100% - 46px);
    margin-left: 7px;
    margin-right: auto;
    padding-inline-start: 16px;
    list-style-position: inside;
}

ul.exchange-treat-coins-list > li {
    text-align: left;
    width: 100%;
}

ol.swal-payment-methods-text {
    padding-inline-start: 20px;
}

ol.swal-payment-methods-text > li {
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
}


ol.swal-offers-policy-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
    padding-left: 22px;
    padding-right: 12px;
}

ol.swal-offers-policy-text > li {
    padding-top: 6px;
    padding-bottom: 6px;
}

.cookies-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cookies-list-item {
    width: 90vw;
    max-width: 540px;
    border-bottom: 1px solid #9A8FC7;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offer-popup-stock-list {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    height: fit-content;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.terms-popup-conditions-scrollable {
    color: #FFFFFF;
    text-align: start;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-radius: 8px;
    border: 1px solid #96F;
    padding: 10px;
    overflow: scroll;
    width: 100%;
    max-width: 600px;
}

.terms-popup-conditions-scrollable ol {
    padding: 0 0 0 14px;
}

.terms-popup-conditions-scrollable * {
    margin-bottom: 10px;
}
.build-my-team-referrals-stats-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 127px;
}

.build-my-team-referrals-stats-list-item {
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #FFF;
}

.build-my-team-referrals-stats-list-item:nth-child(odd) {
    background: #222337;
}

.build-my-team-referrals-stats-list-item:first-child {
    border-radius: 4px 4px 0px 0px;
}

.offer-popup-reviews-list {
    max-height: 0;
    height: auto;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    padding-left: 0;
}

.offer-popup-reviews-list.open {
    max-height: 360px;
}

.offer-popup-reviews-list-item{
    display: flex;
    gap: 8px;
    margin-bottom: 6px;
}
.affiliates-program-cards {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.affiliates-designed-for-cards {
    display: flex;
    gap: 37px;
    justify-content: center;
    list-style-type: none;
}
.affiliate-program-card {
    border-radius: 8px;
    border: 1px solid #8D31E4;
    background: rgba(27, 28, 30, 0.30);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    max-width: 320px;
    height: 100%;
    justify-content: space-between;
}
.affiliate-program-card:hover {
    transform: scale(1.1);
}

.animated-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 270px;
    list-style-type: none;
    padding-left: 0;
}

.animated-list-inner-wrapper{
    max-height: fit-content;
    transition: all 1s ease-in-out;
}

.animated-list-item {
    transform: scale(0);
    opacity: 0;
    transition: all 0.5s ease-out;
    transform-origin: top center;
    margin-bottom: 12px;
}

.animated-list-item.enter {
    transform: scale(1);
    opacity: 1;
}

@keyframes scaleIn {
    0% {
        max-height: 0;
        transform: scale(0);
        opacity: 0;
    }
    
    100% {
        max-height: 80px;
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scaleOut {
    0% {
        max-height: 80px;
        transform: scale(1);
        opacity: 1;
    }

    100% {
        max-height: 0;
        transform: scale(0);
        opacity: 0;
    }
}

.animated-list-item {
    transform-origin: top center;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.animated-list-item.enter {
    animation-name: scaleIn;
}

.animated-list-item.exit {
    animation-name: scaleOut;
}
@media (min-width: 1024px) {
    .affiliates-program-cards, .affiliates-designed-for-cards {
        flex-direction: row;
        justify-content: center;
        gap: 112px;
        align-items: stretch;
    }
    ul.how-it-works-list {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    ul.tips-list {
        letter-spacing: 0.01em;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }
    ul.legal-list,
    ol.legal-list {
        width: 730px;
        margin-left: auto;
        margin-right: auto;
        padding-inline-start: 42px;
    }

    ol.list-style-alpha {
        list-style-type: lower-alpha;
    }

    ol.list-style-roman {
        list-style-type: upper-roman;
    }

    ol.legal-list>li::marker {
        font-size: 20px
    }
    
    ul.how-it-works-list > li,
    ul.tips-list > li {
        line-height: 140%;
        letter-spacing: 0.01em;
        font-size: 18px;
        font-weight: 400;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    ul.tips-sublist {
        list-style-type: disc;
    }

    ul.tips-sublist > li {
        line-height: 170%;
        letter-spacing: 0.01em;
        font-size: 18px;
        font-weight: 400;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .tips-list-marker {
        font-weight: bold;
    }

    ol.treatcoin-usage-list > li {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    ul.exchange-treat-coins-list {
        font-size: 16px;
    }

    ol.swal-offers-policy-text {
        padding-left: 32px;
        padding-right: 22px;
        font-size: 16px;
    }
}
@media (min-width: 1440px) {
.affiliate-program-card {
        max-width: 406px;
        flex: 1;
    }

    .affiliates-program-cards,
    .affiliates-designed-for-cards {
        gap: 32px;
    }
    .animated-list {
        display: flex;
        flex-direction: row;
        gap: 34px;
        height: 120px;
        margin-top: 0;
        justify-content: center;
    }
         .animated-list-item {
             margin-bottom: 0;
         }
}
