.success-popup {
    width: 90vw !important;
    padding: 20px 23px;
    max-width: 600px !important;
}

.success-popup-image {
    width: 70px;
    height: 70px;
    margin-bottom: 33px;
}

.success-popup-head {
    margin-top: 0;
    margin-bottom: 33px;
    color: #0A0B14;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 33px;
}

.success-popup-desc {
    color: #141629;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 33px;
}

.success-confirm-btn {
    border-radius: 18px !important;
    background: linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%) !important;
}

@media (max-width: 1440px) {
    .success-popup-image {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
    }

    .success-popup-desc {
        margin-bottom: 20px;
    }
}