h1.page-title {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: Poppins;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 48.4px;
    text-align: center;
    padding-bottom: 4px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.build-my-team {
    font-family: Poppins;
}

.build-my-team p {
    margin-top: 0;
}

.page-title.build-my-team {
    font-size: 30px;
}

.build-my-team-refer-friends-desc,
.build-my-team-refer-friends-desc-pink {
    text-align: start;
}

.build-my-team-refer-friends-desc {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
}

.build-my-team-refer-friends-desc-pink {
    color: #8D31E4;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.build-my-team-refer-friends-link-value {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50vw;
    margin: 0;
}

.build-my-team-earnings-name {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
}

.build-my-team-earnings-value {
    margin-bottom: 0;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
}

h1.legal-title,
h1.settings-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

h1.treatcoin-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

h1#playground-page-title {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 110%;
    margin-bottom: 0;
}

#login-title,
#register-title,
#reset-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #282B54;
    padding-bottom: 10px;
}

h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

h2.first-three-places {
    margin: 40px 0 20px;
}

.providers-homepage {
    font-size: 16px;
}

h2.top-monthly-prizes,
h2.how-does-it-work,
h2.providers-homepage-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 110%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

h2.providers-homepage-title {
    line-height: 130%;
}

h2.providers-homepage-subtitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: center;
}

h2.top-monthly-prizes {
    padding-bottom: 16px;
    margin-bottom: 30px;
}

h2.account-settings-subtitle {
    margin-top: 16px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.faq-subject {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    color: #FFFFFF !important;
    text-align: left;
    display: flex;
}

.faq-order {
    margin-right: 16px;
}

.total-reward-title {
    border-radius: 25px 25px 0px 0px;
    background: linear-gradient(126.82deg, rgba(192, 232, 255, 0.4) 0.03%, rgba(192, 232, 255, 0.2) 113.88%);
    filter: drop-shadow(0px 9px 3px #080A1A);
    backdrop-filter: blur(3px);
    position: relative;
    height: 40px;
    z-index: 1;
    margin-bottom: -40px;
    opacity: 0.7;
}

.total-reward-title.leaderboards {
    opacity: 1;
}

p.successful-alert {
    margin: 0;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

p.error-alert {
    margin: 0;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
}

.text-purple {
    color: #6260E1;
}

.text-pink {
    color: #EA81B6;
}

.dark-blue {
    color: #282B54;
}

.grey-blue {
    color: #282B54;
    opacity: 0.8;
}

.swal2-content.top-margin {
    margin-top: 45px;
}

.swal2-actions {
    z-index: 0 !important;
}

.divided-between-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
    margin-top: -178px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.total-reward-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    background: -webkit-linear-gradient(#AB4FD1, #F1C0F8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 125%;
    text-align: center;
    -webkit-text-stroke: 1px #F5CAFA;
    padding-top: 19px;
    padding-bottom: 19px;
    position: relative;
    z-index: 0;
}

.total-rewards-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
}

.total-reward-text-leaderboards {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    background: -webkit-linear-gradient(#AB4FD1, #F1C0F8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 125%;
    text-align: center;
    -webkit-text-stroke: 1px #F5CAFA;
    padding-top: 19px;
    padding-bottom: 19px;
    position: relative;
    z-index: 3;
    margin-top: -1px;
}

.leaderboards-fund-image-container {
    display: flex;
    align-items: center;
    text-align: right;
    z-index: 3;
}

.reward-month-name {
    font-weight: 600;
    color: #5FC88F;
}

.row-title-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.page-container>.faq-row-wrapper>.faq-body>.faq-row>.row-title.expanded+.row-content {
    height: auto !important;
}

.row-content-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
    background: #282C4A;
    border-radius: 15px;
}

.faq-row {
    border-bottom: none !important;
}

.underlined-bold {
    text-decoration: underline;
    font-weight: 700;
}

.step-number {
    display: block;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 166px;
    line-height: 110%;
    text-align: center;
    color: #FCC8BC;
    opacity: 0.37;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    margin-bottom: -16px;
}

.step-content {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #CB8F6D;
    margin-bottom: 20px;
    display: inline-block;
}

.step-description {
    margin-top: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    display: block;
}

.offer-referral-title {
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.7;
}

.offer-referral-title span {
    -webkit-text-fill-color: initial;
}

.offer-referral-text {
    font-weight: 400;
}

.offer-referral-text span {
    font-weight: 700;
}

.offer-tracking-notification,
.offer-tracking-notification button {
    color: #9F7DDB;
    font-size: 16px;
}

.offer-tracking-notification button {
    width: fit-content;
    text-decoration: underline;
}

.offer-tracking-modal-heading {
    font-size: 26px;
}

.offer-tracking-modal-list {
    max-width: 500px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    opacity: 0.8;
    color: #282B54;
    margin-bottom: 60px;
}

.finished-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #5FC88F;
}

.finished-coins-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    margin-left: 8px;
}

.finished-total-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: right;
    color: #141629;
    opacity: 0.5;
}

.treatcoin-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #282B54;
    position: relative;
    z-index: 2;
}

.treatcoin-description p {
    margin: 0;
}

.treatcoin-rewards-description-container {
    margin-bottom: 32px;
    margin: 0 auto;
    text-align: center;
}

.treatcoin-rewards-description {
    position: absolute;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(97.86deg, rgba(192, 232, 255, 0.4) 0.01%, rgba(192, 232, 255, 0.05) 100%);
    box-shadow: 0px 4px 30px #121424;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 25px;
    z-index: 2;
    padding: 8px 5px;
    margin-top: 20px;
}

p.long-content,
p.role-title,
p.reward-range,
p.legal-last-updated,
p.list-item,
.text-white.mb-4.leading-7 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
}

.content-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
    text-align: left;
    margin-top: 48px;
    margin-bottom: 24px;
}

.content-subtitle.small-margin {
    margin-top: 24px;
}

.content-subtitle.top-title {
    margin-top: 24px;
}

.reward-range.prize-fund {
    margin-bottom: 32px;
}

.verification-code-expire-time {
    color: #8a8b94;
    margin-top: 8px;
    margin-bottom: 8px;
}

span.count {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 18px;
    color: #8a8b94 !important;
}

span.referral-info {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.loading-message {
    padding: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #FFFFFF;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}

.success-message {
    padding: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #61F2A3;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}

.success-message.dark {
    color: #32810B;
}

.error-message {
    padding: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #E4FF3A;
    -webkit-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}

.error-message.red-error {
    color: #EC1717;
}

.success-message.account-settings {
    min-height: 24px;
}

.error-message.account-settings {
    min-height: 24px;
}

.login-error-message,
.reset-error-message,
.register-error-message {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #EC1717;
    padding: 5px;
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
}

.register-error-message.terms-error {
    margin-bottom: 8px;
}

.login-error-message,
.login-invisible-message {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 15px;
    padding: 0;
}

.login-invisible-message {
    opacity: 0;
    margin-top: 19px;
}

.password-change-error-message {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #E4FF3A;
    padding: 5px;
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
}

.invisible-message {
    opacity: 0;
    font-size: 12px;
    line-height: 18px;
    padding: 5px;
}

.withdrawal-approved-popup-description {
    font-size: 36px;
    color: #4d66d6;
}

.start-now-checkbox_desc {
    font-size: 14px;
    color: #000000;
}

.start-now_desc {
    color: #8F8F8F;
    font-size: 12px;
}

.start-now-title {
    color: #000;
    font-size: 32px;
    font-weight: 450;
    margin-top: -15px;
    margin-bottom: 25px;
    max-width: 300px;
}

.start-now-title .text-purple {
    color: #8D31E4;
    font-weight: 700;
}

.start-now-title .text-pink {
    color: #EA81B6;
    font-weight: 700;
}

.swal-invisible-message {
    opacity: 0;
    font-size: 10px;
    line-height: 14px;
    transition: all 0.5s ease-in-out;
}

.referral-name-label {
    color: #F4F4F5;
}

#swal2-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #685EBF;
    height: 35px;
    margin-top: 40px;
}

.swal2-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #0A0B14;
}

.swal2-content.hint-text {
    margin-top: 24px;
    font-size: 12px;
    line-height: 18px;
}

.swal2-content.contact-us {
    margin-top: 36px;
}

.swal-join-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #141629;
    text-align: left;
    margin-top: -20px;
    margin-left: 12px;
}

.swal-nfts-title,
.swal-convert-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #141629;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 0;
}

.swal-legitimacy-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #141629;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 0;
}

.swal-payment-methods-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #141629;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 0;
}

.swal-payment-methods-title.large-margin {
    margin-bottom: 16px;
}

.swal-payment-methods-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.swal-treat-coins-validity-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #141629;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 0px;
}

.swal-treat-coins-validity-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    text-align: left;
    width: calc(100% - 24px);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16px;
}

.swal-treat-coins-validity-text:first-of-type {
    padding-top: 16px;
}

.swal-payment-methods-text.centered {
    text-align: center;
}

.swal-payment-methods-text.large-margin {
    margin-top: 120px;
}

.swal-add-payment-method-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #141629;
    max-width: fit-content;
    margin: 10px auto;
}

.swal-offers-policy-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #141629;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 45px;
}

.swal-nfts-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #FFFFFF;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.swal-legitimacy-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #FFFFFF;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
}


.refer-component-title,
.stats-component-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    text-align: center;
    color: #685EBF;
    margin-bottom: 0;
}

.user-stats-header.one-two-treat-area {
    color: #6260E1 !important;
}

.stats-component-title {
    margin-bottom: 5px;
}

.stats-component-title span {
    color: #141629;
}

.stats-component-subtitle {
    margin-top: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #0A0B14;
    opacity: 0.5;
}

.refer-component-text,
.stats-component-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #0A0B14;
}

.join-option-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #282B54;
    margin-left: 12px;
    display: inline-block;
}

.join-option-indicator {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: #282B54;
    opacity: 0.5;
    display: block;
    margin-left: 12px;
}

.bold-text {
    font-weight: bold;
}

.green-text {
    color: #5FC88F;
}

.red-text {
    color: #FA3035 !important;
}

.black-text {
    color: #000000;
}

.email-text {
    margin-top: 10px;
    display: block;
}

.underlined-text {
    text-decoration: underline;
}

.payment-method-deletion-warning {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #5FC88F;
}

.payment-method-cta-text {
    flex-grow: 1;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #FFFFFF;
}

p.login-content,
p.register-content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #282B54;
}

/* @media screen and (min-width: 1024px) and (max-width: 1300px) {
    .login-content a,
    .register-content a {
        padding: 3px;
        box-shadow: -1px 1px 221px 40px rgba(255,255,255,0.9) inset;
        -webkit-box-shadow: -1px 1px 221px 40px rgba(255,255,255,0.9) inset;
        -moz-box-shadow: -1px 1px 221px 40px rgba(255,255,255,0.9) inset;
        border-radius: 10px;
    }
} */

p.password-requirement {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    font-size: 12px;
    color: #ced0de;
}

p.password-requirement.light-mode {
    color: #141629;
}

p.password-requirement.indented {
    padding-left: 16px;
}

.highscore-indicator {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
    margin-top: 4px;
    text-align: center;
    color: #141629;
    opacity: 0.5;
    min-width: 50px;
}

.highscore-user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #141629;
}

.highscore-rank {
    position: relative;
    text-align: center;
    height: 45px;
    margin-right: -30px;
}

.rank-number {
    z-index: 3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #691FD6;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.highscore-month {
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #141629;
    margin-bottom: 12px;
}

.highscore-data {
    margin: 0 auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}

.highscore-place {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.8;
}

.highscore-place.second {
    margin-right: 5px;
}

.highscore-place.third {
    margin-left: 5px;
}

.monthly-coins-indicator {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 110%;
    color: #FFFFFF;
    text-align: left;
}

.coins-earning-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 0;
}

.coins-earning-unique-id {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.8);
}

.coins-earning-unique-id.new {
    color: rgba(0, 0, 0);
}

.coins-earning-unique-id.new.opacity {
    color: rgba(0, 0, 0, 0.5);
}

.treatcoin-payout-transaction-id {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #141629;
    opacity: 0.5;
    margin-top: 4px;
}

.treatcoins-returned-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #141629;
}

.coins-earning-time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #000000;
}

.coins-earning-time.new {
    color: #000;
}

.coins-earning-amount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    text-align: right;
    color: #5C0879;
    width: 68%;
}

.coins-earning-amount.new {
    color: #5C0879;
    font-weight: 600 !important;
    margin-left: 15px;
}

.coins-earning-worth {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-align: right;
    color: #FFFFFF;
}

.coins-earning-validity {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-align: right;
    color: #000000;
    opacity: 0.5;
}

.treatcoin-payout-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #141629;
}

.treatcoin-payout-entry-wrapper.homepage .treatcoin-payout-description.conversion {
    line-height: 20px;
}

.treatcoin-payout-description.conversion {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #141629;
}

.reward-earning-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #8D31E4;
    opacity: 0.5;
    margin-top: 8px;
}

.treatcoin-payout-status {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 3px;
    margin-right: 8px;
}

.treatcoin-payout-status.payout-status-1 {
    background: #EBB018;
}

.treatcoin-payout-status.payout-status-2 {
    background: #FA3035;
}

.treatcoin-payout-status.payout-status-3 {
    background: #5FC88F;
}

.treatcoin-payout-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #8D31E4;
    opacity: 0.5;
}

.welcome-robot-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5FC88F;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}

.account-suspended-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #FFFFFF;
}

.terms-text {
    margin-left: 36px;
}

.featured-offer-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
    color: #bf45fdcb;
    opacity: 0.8;
}
.featured-offer-root.desktop.discord .featured-offer-text {
    color: #fff;
}

.playground-description,
.approval-identity-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    max-width: 350px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}

.my-treat-coin-earnings-description,
.my-treat-coin-payouts-description,
.use-your-treat-coins-description,
.leaderboards-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.notification-setting-label {
    width: 175px;
    display: inline-block;
}

.the-treat-coin-item-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.the-treat-coin-item-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #5FC88F;
}

.hash-tags-toggle {
    text-decoration: underline;
}

.single-hash-tag {
    margin-right: 2px;
    display: inline-block;
}

.single-hash-tag.bold {
    font-weight: bold;
    white-space: nowrap
}

.offer-completion-disclaimer-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.difficulty-popup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #141629 !important;
    opacity: 0.7;
    position: absolute;
    top: 21px;
    text-align: center;
    width: 100%;
}

.offer-difficulty-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    letter-spacing: 0.01em;
    color: #000000;
    width: 85px;
    padding-left: 8px;
}

.offer-difficulty-label.green {
    color: #06D6A0;
}

.offer-difficulty-label.yellow {
    color: #FFD166;
}

.offer-difficulty-label.red {
    color: #EF476F;
}

.more-from-brand-popup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #141629;
    opacity: 0.7;
    position: absolute;
    top: 28px;
    width: 100%;
}

.unlimited-indicator-popup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    letter-spacing: 0.01em;
    color: #141629;
    opacity: 0.7;
    position: absolute;
    top: 23px;
    left: 20px;
    width: 80%;
}

.daily-indicator-popup-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    letter-spacing: 0.01em;
    color: #141629;
    opacity: 0.7;
    position: absolute;
    top: 28px;
    left: 29px;
    width: 80%;
}

.refer-teaser-text {
    position: absolute;
    top: 64px;
    left: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #041007;
    width: 175px;
}

.one-two-treat-teaser-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    margin: 20px 30px;
}

.share-teaser-text {
    position: absolute;
    top: 48px;
    left: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
    width: 175px;
}

.most-popular-join-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    position: absolute;
    top: -2px;
    left: 2px;
}

.favourite-offers-section-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 110%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: calc(100% - 45px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 8px;
}

.favourite-offers-section-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 36px;
}

.timer-title {
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.timer-description {
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.timer-counter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #EA81B6;
    margin-left: 8px;
}

.home-timer-title {
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 6px rgba(95, 0, 254, 0.40);
    margin-bottom: 16px;
}

.home-timer-description {
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #141629;
}

.home-timer-counter {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 8px;
}

.more-from-brand-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #141629;
    opacity: 0.7;
    margin-top: 16px;
    margin-bottom: 16px;
}

.exchange-treat-coins-title {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170%;
    color: #FFFFFF;
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
}

.withdraw-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 44px;
    color: #FFFFFF;
}

.treat-coins-balance-indicator {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    color: #FFFFFF;
    margin: 16px 0;
}

.withdrawal-treat-coins-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    text-align: center;
    color: #141629;
}

.withdrawal-treat-coins-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 110%;
    text-align: center;
    color: #141629;
}

.withdrawal-treat-coins-usd-amount,
.convert-treat-coins-usd-amount {
    margin-left: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 60%;
    color: #141629;
    opacity: 0.5;
}

.convert-treat-coins-usd-amount {
    max-width: 200px;
    line-height: 120%;
}

.min-treat-coin-withdrawal-hint {
    margin-top: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    opacity: 0.75;
}

.min-treat-coin-withdrawal-hint.hint {
    color: #141629;
}

.min-treat-coin-withdrawal-hint.error {
    color: #FA3035;
}

.withdrawal-payment-method-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-top: 8px;
}

.recommended-offer-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 110%;
    text-align: center;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding-bottom: 24px;
    width: calc(100% - 50px);
    margin-left: auto;
    margin-right: auto;
}

.recommended-offer-title.alternative {
    padding-top: 24px;
}

.recommender-details-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    padding-top: 6px;
    padding-bottom: 6px;
}

.daily-times-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: right;
    color: #141629;
    opacity: 0.5;
}

.offer-details-daily-amount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: right;
    color: #141629;
    opacity: 0.5;
    margin-right: 4px;
}

.daily-indicator-list-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: right;
    color: #FFFFFF;
    margin-right: 4px;
    opacity: 0.5;
}

.daily-indicator-list-text.featured {
    color: #8D31E4;
    opacity: 1.0;
}

.lifetime-treat-coins-earned-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    opacity: 0.5;
    padding-bottom: 18px;
    padding-top: 18px;
}

.monthly-treat-coins-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top: 0.5px dashed rgba(255, 255, 255, 0.3);
    padding-top: 18px;
    width: 100%;
}

.lifetime-usable-treat-coins-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 18px;
}

.monthly-treat-coins-amount-number {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.monthly-treat-coins-amount-tc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}

.treatcoins-breakdown-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.treatcoins-breakdown-amount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    padding-top: 18px;
    padding-bottom: 16px;
    text-wrap: nowrap;
}

.treatcoins-breakdown-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    padding-top: 18px;
    padding-bottom: 16px;
}

.my-payouts-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 14px;
}

.green-color {
    color: #5FC88F;
    margin: 0;
}

.treat-coin-equals-text {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
}

.treat-coin-equals-amount {
    color: #CB8F6D;
    font-family: Poppins;
    font-size: 41px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
}

.treat-coin-equals-amount-inactive {
    font-family: Poppins;
    font-size: 41px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    margin: 20px 0 0;
    padding: 0;
    opacity: .5;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FFFFFF;
    color: transparent;
}

.treat-little-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.providers-homepage-brands-description {
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 30px 0 50px 0;
    text-fill-color: transparent;
}

.providers-homepage-card-title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.providers-homepage-card-subtitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: center;
}


.providers-homepage-card-description,
.connect-to-bot-bonus {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    opacity: 0.8;
}

.connect-to-bot-bonus {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
}

.providers-homepage-menu-description {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.providers-homepage-menu {
    font-size: 18px;
}

.providers-homepage-brands-container {
    font-size: 20px;
}

.login-container-div span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #282B54;
}

.special-offer-modal-title {
    font-size: 20px;
}

.offer-details-switch-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.01em;
}

.convert-button-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
}

.cookie-popup-container .cookie-description {
    text-align: center;
    font-size: 15px;
    font-family: Poppins;
    line-height: 130%;
    opacity: 0.5;
}

.cookie-description,
.cookie-description * {
    color: #282B54;
}

.cookies-list-item-desc {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;

}

.forgot-password-subheading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: black;
    max-width: 400px;
    margin: 30px auto 16px;
}

.black-text-opacity {
    color: rgba(20, 22, 41, 1) !important;
}

.list-offer-theme-indicator-text {
    width: 60px;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 18px;
    color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.list-offer-theme-indicator-text.time {
    background: rgba(239, 202, 69, 1);
}

.list-offer-theme-indicator-text.cashback {
    background-color: rgba(238, 148, 237, 1);
}

.list-offer-theme-indicator-text.ff {
    background-color: rgba(37, 170, 239, 1);
}

.list-offer-theme-indicator-text.engagement {
    font-size: 8px;
    padding: 2px 0;
    background-color: rgba(72, 217, 11, 1);
}

.info-text {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-align: center;
}

.absolute-privacy-policy {
    position: absolute;
    bottom: 40px;
    right: 30px;
    color: #282B54;
}

.special-offer-popup-title-container.desktop {
    display: none !important;
}

p.swal-nfts-text {
    margin-bottom: 10px;
}

.payment-method-data-text {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    overflow-wrap: anywhere;
}

.cookie-text {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 4px;
    margin-top: 0px;
    color: #15182A;
}

.withdrawal-confirm-desc {
    color: #0A0B14;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.withdrawal-confirm-agreement {
    color: #131522;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    width: 90%;
    max-width: 327px;
    margin: 0;
}

.withdrawal-confirm-desc {
    color: #0A0B14;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.withdrawal-confirm-agreement {
    color: #131522;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    width: 90%;
    max-width: 327px;
    margin: 0;
}

.text-white-14-700 {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
}

.text-white-14-500 {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}

.withdrawal-confirm-desc {
    color: #0A0B14;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.withdrawal-confirm-agreement {
    color: #131522;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    width: 90%;
    max-width: 327px;
    margin: 0;
}

.withdrawal-confirm-desc {
    color: #0A0B14;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.withdrawal-confirm-agreement {
    color: #131522;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    width: 90%;
    max-width: 327px;
    margin: 0;
}

.text-white-14-700 {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
}

.text-white-14-500 {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}

.white-20-700-text {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
}

.white-16-400-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.8px;
    text-transform: none;
}

.uppercase-text {
    text-transform: uppercase;
}

.letter-spacing-08 {
    letter-spacing: 0.8px;
}

.terms-popup-desc {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}

.term-popup-step-terms-and-conditions-head {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 700;
    margin-top: 24px;
}

.terms-popup-agreement {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    margin: 0;
}

.build-my-team-underline-14 {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
}

.build-my-team-referrals-stats-list-item-username {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.build-my-team-referrals-stats-list-item-gen {
    color: #FFF;
    font-size: 8px;
    font-weight: 300;
    margin: 0;
    text-align: start;
}

.build-my-team-referrals-stats-list-item-earnings p {
    margin: 0;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    width: 15vw;
    max-width: 73px;
}

.how-it-works-heading,
.how-it-works-card-heading,
.how-it-works-card-desc,
.stats-row-desc,
.stats-row-ref-count,
.stats-row-ref-earnings-count {
    color: #FFF;
    margin-top: 0;
}

.how-it-works-heading {
    color: #FFF;
    margin-bottom: 21px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.how-it-works-card-heading {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.how-it-works-card-desc {
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 0;
    opacity: 0.8;
}

.stats-row-desc {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
}

.stats-row-ref-count {
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    opacity: 0.8;
}

.stats-row-ref-earnings-count {
    margin-bottom: 0;
    color: #5FC88F;
    font-size: 12px;
    font-weight: 600;
}

.offer-popup-reviews-rating {
    color: #FAC401;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.8px;
}

.offer-popup-reviews-list-item-desc .is-mine {
    background: linear-gradient(180deg, #AB4FD1 21.67%, #F1C0F8 77.22%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 2px;
}

.offer-popup-reviews-list-item-desc .gray-8 {
    color: #FFF;
    font-size: 8px;
    font-weight: 400;
    opacity: 0.5;
    line-height: 5px;
    margin-bottom: 2px;
}

.offer-popup-reviews-list-item-desc .text {
    color: #FFF;
    align-self: stretch;
    font-size: 10px;
    font-weight: 400;
}

.delete-review-modal-desc {
    color: #0A0B14;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.affiliates-head {
    margin-top: 0;
    position: relative;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 32px;
}

.heading-2-white {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin: 0 auto;
    margin-bottom:  24px;
}

.text-desc-white,
.affiliates-1-desc {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    max-width: 1080px;
}

.affiliates-1-desc {
    position: relative;
}
.affiliate-program-card-head {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin: 0 auto;
    margin-bottom: 16px;
}

.affiliate-designed-card-head {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    margin: 0 auto;
    margin-bottom: 4px;
}

.affiliate-designed-card-desc {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    max-width: 340px;
    margin: 0 auto;
}

.affiliates-designed-for-image p {
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    line-height: 16.8px;
    max-width: 60px;
    max-height: 65px;
    overflow: hidden;
    position: absolute;
    transform: translate(-50%, -50%);
}

.affiliates-designed-for-image .text-first {
    top: 30px;
    left: 40px;
}

.affiliates-designed-for-image .text-second {
    top: 177px;
    left: 153px;
}

.affiliates-designed-for-image .text-third {
    top: 120px;
    right: -14px;
}
.affiliates-why-tc-heading {
    color: #EA81B6;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;
}

.affiliates-why-tc-desc {
    font-size: 20px;
    font-weight: 500;
    text-align: start;
    margin: 0 auto;
    margin-bottom: 48px;
    max-width: 1220px;
}
.affiliates-feedback-item h5 {
    color: #6E73DC;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: start;
}

.affiliates-feedback-item p {
    text-align: start;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 273px;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}
.affiliates-tools-item-right p,
.affiliates-tools-item-right h6 {
    margin-bottom: 0;
    margin-top: 0;
    text-align: start;
}

.affiliates-tools-item-right h6 {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
}

.affiliates-tools-item-right p {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
}
.affiliates-footer-rights {
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    text-transform: capitalize;
}

.purple-text {
    color: #8649D1;
}

.affiliates-footer{
    padding-bottom: 20px !important;
}
.offer-popup-coins-when-pays-text {
    color: #FFF;
    font-size: 8px;
    font-weight: 400;
    text-align: center;
}

.offer-popup-coins-when-pays-tooltip p {
    color: #2E2E2E;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
@media (max-width: 1024px) {
    .notification-setting-label {
        width: auto;
    }
}

@media (max-width: 767px) {
    h2.providers-homepage-title {
        font-size: 45px;
    }

    .highscore-data {
        max-width: 100px;
    }

    .treatcoins-breakdown-amount {
        font-size: 20px;
    }

    .treatcoins-breakdown-label {
        font-size: 16px;
    }

    @media (max-width: 600px) {
        .treatcoins-breakdown-amount {
            font-size: 22px;
        }

        .treatcoins-breakdown-label {
            font-size: 18px;
        }

        .absolute-privacy-policy {
            bottom: 20px;
            right: 50%;
            transform: translateX(50%);
        }
    }

    @media (max-width: 500px) {
        .treatcoins-breakdown-amount {
            font-size: 16px;
        }

        .treatcoins-breakdown-label {
            font-size: 16px;
        }

        .font-18.smaller {
            font-size: 16px;
            line-height: 16px;
        }
    }

    @media (max-width: 415px) {
        .treatcoins-breakdown-amount {
            font-size: 12px;
        }

        .treatcoins-breakdown-label {
            font-size: 12px;
        }

        .monthly-treat-coins-breakdown-container.gap {
            gap: 10px;
        }
    }

    @media (max-width: 360px) {
        .font-18.smaller {
            font-size: 12px;
            line-height: 12px;
        }
    }
}

@media screen and (max-width: 728px) {
    .highscore-indicator {
        font-size: 10px;
    }
}

@media screen and (max-width: 625px) {
    .highscore-indicator {
        font-size: 8px;
    }
}

@media (max-width: 500px) {
    .treatcoins-breakdown-title {
        font-size: 10px;
    }
}
@media (max-width: 400px) {
    .how-does-it-work {
        font-size: 42px !important;
    }

    .treat-coin-equals-amount {
        font-size: 26px !important;
        font-weight: 400;
    }

    .treat-coin-equals-text {
        font-size: 20px !important;
    }

    .margin-right {
        margin-right: 0 !important;
    }

    .steps-div-container {
        margin: 0 auto;
        max-width: 100%;
    }

    h2.providers-homepage-title {
        font-size: 35px;
    }

    .swal-convert-title {
        font-size: 35px;
    }

    h1.page-title.approve {
        font-size: 37px;
    }
}

@media (min-width: 1024px) {
    .mobile-enter {
            display: none;
        }

    .affiliates-footer-rights {
            font-size: 24px;
        }
    .affiliates-feedback-item h5 {
            font-size: 28px;
        }
        
        .affiliates-feedback-item p {
            max-width: fit-content;
            font-size: 24px;
        }
    .affiliates-why-tc-heading {
            margin-bottom: 40px;
        }
    
        .affiliates-why-tc-desc {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
        }
    .affiliates-designed-for-image p {
            font-size: 16px;
            line-height: 33px;
            max-width: 150px;
            max-height: 130px;
            max-width: 110px;
        }
    
        .affiliates-designed-for-image .text-first {
            top: 57px;
            left: 75px;
        }
    
        .affiliates-designed-for-image .text-second {
             top: 340px;
              left: 290px;
        }
    
        .affiliates-designed-for-image .text-third {
            top: 230px;
            right: -20px;
        }
    .affiliate-designed-card-head {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .affiliate-designed-card-desc {
        font-size: 20px;
    }

    .text-desc-white.big {
        font-size: 48px;
        font-weight: 500;
        max-width: 1060px;
    }

    .heading-2-white {
        font-size: 40px;
        margin-bottom: 40px;
        line-height: 2.5rem;
    }

    .build-my-team-referrals-stats-list-item-earnings p {
        width: 5vw;
    }

    .withdrawal-confirm-agreement {
        font-size: 15px;
    }

    /* .desktop-white-18-semi {
        font-size: 18px;
        font-weight: 500;
        color: #fff !important;
        */

    .start-now-title {
        font-size: 52px;
        max-width: 585px;
    }

    .month-total-container.earning.desktop .monthly-treat-coins-amount-number.desktop {
        font-size: 36px;
    }

    .month-total-container.earning.desktop .monthly-treat-coins-amount-tc.desktop {
        font-size: 22px;
    }

    .lifetime-treat-coins-earned-label {
        padding-top: 5px;
    }

    .monthly-treat-coins-label.desktop {
        font-size: 20px;
        font-weight: 700;
        background: var(--gradient-2, linear-gradient(74deg, #6BC6F5 0.55%, #EE94ED 43.76%, #F5BC68 74.48%, #EFCA45 99.29%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 20px;
        margin-right: 20px;
    }

    .hash-tags-toggle.desktop {
        display: none;
    }

    .account-details-label {
        font-size: 22px;
    }

    .approval-identity-description {
        font-size: 18px;
    }

    h1.page-title.account {
        margin-top: 0;
    }

    .offer-details-switch-text {
        font-size: 18px;
    }

    h1.page-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    h1.page-title.desktop {
        margin-top: 0;
    }

    h1.legal-title,
    h1.settings-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
    }

    h1#playground-page-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    h2.top-monthly-prizes,
    h2.how-does-it-work {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
    }

    #providers-subtitle {
        font-weight: 700;
        font-size: 60px;
        line-height: 126%;
    }

    .playground-description {
        margin-top: 11px;
        width: 500px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
    }

    .playground-description.desktop {
        max-width: unset;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.18px;
    }

    div.my-treat-coin-earnings-description,
    div.my-treat-coin-payouts-description,
    div.use-your-treat-coins-description {
        margin-top: 11px;
        width: 500px;
        margin-left: auto;
        margin-right: auto;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    .page-title.earnings-page.desktop {
        margin-bottom: 10px;
    }

    .page-title.use-your-treatcoins-page.desktop {
        margin-bottom: 10px;
    }

    div.use-your-treat-coins-description.desktop {
        width: 70%;
    }

    div.my-treat-coin-earnings-description.desktop,
    div.my-treat-coin-payouts-description.desktop {
        width: 100%;
    }

    div.leaderboards-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    h2.account-settings-subtitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    h2.first-three-places {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    p.long-content,
    p.role-title,
    p.legal-last-updated,
    p.reward-range,
    p.list-item,
    .text-white.mb-4.leading-7 {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    .swal-nfts-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        padding-bottom: 24px;
    }

    .swal-convert-title {
        margin-top: 20px;
        font-size: 50px;
    }

    .swal-legitimacy-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        padding-bottom: 24px;
    }

    .swal-payment-methods-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        padding-bottom: 24px;
        margin-top: -12px;
    }

    .swal-treat-coins-validity-title {
        font-weight: 700;
        font-size: 38px;
    }

    .swal-treat-coins-validity-text {
        font-weight: 500;
        font-size: 16px;
    }

    .swal-offers-policy-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        padding-bottom: 24px;
    }

    .special-offers-popup-swal-title.mobile {
        display: none !important;
    }

    .special-offer-popup-title-container.desktop {
        display: flex !important;
    }

    p.swal-nfts-text.desktop {
        width: auto;
        padding: 0 40px 40px;
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.18px;
    }

    p.swal-legitimacy-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        width: 620px;
    }

    p.swal-payment-methods-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        margin-left: 6px;
    }

    p.swal-offers-policy-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        width: 620px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        padding-left: 22px;
        padding-right: 22px !important;
    }

    .swal-add-payment-method-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        width: 620px;
        margin: 15px auto;
    }

    p.swal-payment-methods-text.centered {
        width: auto;
    }

    .treatcoin-payout-description,
    .treatcoin-payout-description.conversion {
        font-size: 16px;
    }

    .treatcoin-payout-entry-wrapper.payout-page .treatcoin-payout-description {
        font-size: 16px;
        width: max-content;
    }

    .treatcoin-payout-entry-wrapper.payout-page .treatcoin-payout-transaction-id {
        font-size: 12px;
    }

    .reward-earning-date {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    }

    .treatcoin-payout-status {
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        width: fit-content;
    }

    .coins-withdrawal-status {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    }

    .treatcoin-payout-date {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
    }

    .treatcoin-payout-entry-wrapper.payout-page .treatcoin-payout-status,
    .treatcoin-payout-entry-wrapper.payout-page .treatcoin-payout-date {
        font-size: 14px;
        margin-top: 10px;
    }



    .notification-setting-label {
        width: auto;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        margin-top: 2px;
    }

    .activity-preferences-container {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    #offer-data-app-name {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
    }

    #offer-data-description {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    #offer-data-hash-tags {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.01em;
    }

    .offer-details-description {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.01em;
        margin-bottom: 24px;
    }

    .error-message,
    .login-error-message,
    .reset-error-message,
    .register-error-message,
    .password-change-error-message,
    .swal-payment-methods-input-error {
        font-weight: 400;
        font-size: 17px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    p.login-content,
    p.register-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .terms-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        padding-top: 2px;
    }

    p.password-requirement {
        font-weight: 400;
        line-height: 20px;
        font-size: 16px;
    }

    #leaderboards-subtitle {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
        margin-bottom: 16px;
    }

    #refer-friends-subtitle {
        font-weight: 700;
        font-size: 60px;
        line-height: 110%;
    }

    .highscore-month {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }

    #swal2-title {
        font-size: 22px;
        line-height: 33px;
    }

    p.swal2-content {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        width: 620px;
        max-width: 300px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .page-title.welcome-title {
        width: calc(100% - 300px);
        margin-left: auto;
        margin-right: auto;
    }

    .welcome-robot-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .account-suspended-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .the-treat-coin-item-text {
        display: inline-block;
        margin-left: 48px;
        vertical-align: top;
    }

    .the-treat-coin-item-title {
        text-align: left;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-top: 0px;
    }

    .the-treat-coin-item-description {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.01em;
        width: 350px;
        text-align: left;
    }

    .monthly-coins-indicator {
        font-weight: 700;
        font-size: 44px;
        line-height: 110%;
    }

    .coins-earning-description {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .coins-earning-unique-id {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .treatcoin-payout-transaction-id {
        font-weight: 400;
        line-height: 21px;
    }

    .treatcoins-returned-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-top: -10px;
    }


    .coins-earning-time {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.01em;
        text-align: right;
    }

    .coins-earning-amount {
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        text-align: right;
    }

    .coins-earning-worth {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .coins-earning-validity {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .step-number {
        font-weight: 600;
        font-size: 166px;
        line-height: 110%;
    }

    .step-content {
        font-weight: 400;
        font-size: 21px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .step-description {
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .refer-teaser-text {
        font-size: 15px;
        line-height: 22px;
        top: 74px;
        left: 60px;
        width: 225px;
    }

    .share-teaser-text {
        font-size: 15px;
        line-height: 22px;
        top: 50px;
        left: 34px;
        width: 225px;
    }

    .favourite-offers-section-title {
        font-size: 38px;
    }

    .favourite-offers-section-description {
        font-size: 16px;
    }

    .favourite-offers-section-title.desktop {
        background: var(--gradient-2, linear-gradient(74deg, #6BC6F5 0.55%, #EE94ED 43.76%, #F5BC68 74.48%, #EFCA45 99.29%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 60px;
        font-weight: 700;
        line-height: 110%;
    }

    .favourite-offers-section-description.desktop {
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        line-height: 140%;
    }

    .timer-title,
    .timer-description {
        width: calc(100% - 90px);
        margin-left: auto;
        margin-right: auto;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #FFFFFF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .timer-description {
        font-weight: bold;
    }

    .timer-counter {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #EA81B6;
        margin-left: 8px;
    }

    .home-timer-title {
        font-size: 40px;
        line-height: 30px;
    }

    .home-timer-description {
        font-size: 20px;
        line-height: 30px;
    }

    .home-timer-counter {
        font-size: 16px;
        line-height: 140%;
    }

    .exchange-treat-coins-title {
        font-size: 16px;
    }

    .treat-coins-balance-indicator {
        font-size: 16px;
    }

    .withdrawal-treat-coins-label {
        font-size: 28px;
    }

    .withdrawal-treat-coins-amount {
        font-size: 48px;
    }

    .withdrawal-treat-coins-usd-amount {
        font-size: 13px;
    }

    .convert-treat-coins-usd-amount {
        font-size: 13px;
    }

    .min-treat-coin-withdrawal-hint {
        font-size: 16px;
    }

    .payment-method-data-text {
        font-size: 14px;
        max-width: 380px;
    }

    .recommended-offer-title {
        font-size: 42px;
    }

    .recommender-details-text {
        font-weight: 400;
        font-size: 16px;
    }

    .recommended-offer-not-available {
        font-weight: 400;
        font-size: 20px;
    }

    .payment-method-deletion-warning {
        font-weight: 400;
        font-size: 22px;
    }

    .payment-method-cta-text {
        font-weight: 400;
        font-size: 18px;
    }

    .payment-method-cta-text.desktop {
        width: 100%;
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-decoration: none;
    }

    .daily-times-label {
        font-size: 14px;
    }

    .offer-details-daily-amount {
        font-size: 14px;
    }

    .daily-indicator-list-text.incomplete-list {
        color: #5e5dbb;
        opacity: 1.0;
    }

    .daily-indicator-list-text {
        font-size: 14px;
    }

    .lifetime-usable-treat-coins-label {
        font-weight: 600;
        font-size: 22px;
    }

    .month-total-container .monthly-treat-coins-amount-number {
        font-weight: 600;
    }

    .month-total-container.payout .month-total-container .monthly-treat-coins-amount-number {
        font-size: 54px;
    }

    .month-total-container.earning .monthly-treat-coins-amount-number {
        font-weight: 700;
        font-size: 36px;
    }

    .monthly-treat-coins-amount-tc {
        font-weight: 700;
        font-size: 22px;
    }

    .month-total-container.payout .monthly-treat-coins-amount-tc {
        font-size: 35px;
        font-weight: 600;
    }

    .treatcoins-breakdown-title {
        font-weight: 700;
        font-size: 18px;
    }

    .month-total-container.payout .treat-coins-detailed-wrap .treatcoins-breakdown-title {
        font-size: 22px;
        font-weight: 600;
    }

    .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-title {
        font-weight: 700;
        background: var(--gradient-2, linear-gradient(74deg, #6BC6F5 0.55%, #EE94ED 43.76%, #F5BC68 74.48%, #EFCA45 99.29%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .treatcoins-breakdown-amount {
        font-weight: 700;
        font-size: 20px;
    }

    .month-total-container.payout .treatcoins-breakdown-amount{
        font-size: 34px;
        font-weight: 600;
    }

    .treatcoins-breakdown-label {
        font-weight: 700;
        font-size: 18px;
    }

    .month-total-container.payout .treatcoins-breakdown-label{
        font-size: 22px;
        font-weight: 600;
    }

    .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-amount {
        font-size: 19px;
        font-weight: 700;
    }

    .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-label {
        font-size: 18px;
        font-weight: 700;
    }

    .my-payouts-label {
        font-size: 16px;
    }

    p.successful-alert {
        font-size: 18px;
    }

    p.error-alert {
        font-size: 18px;
    }

    h2 .providers-homepage-card-title {
        font-size: 26px;
    }

    h2.providers-homepage-card-description {
        font-size: 20px;
        line-height: 30px;
    }

    h2.providers-homepage-card-description.desktop {
        font-size: 16px;
    }

    h2.providers-homepage-subtitle {
        font-size: 18px;
    }

    h2.providers-homepage-menu-description {
        font-size: 20px;
    }

    h2.providers-homepage-brands-description {
        font-size: 22px;
    }

    .cookie-popup-container .cookie-description {
        font-size: 17px;
    }

    .forgot-password-subheading {
        font-size: 16px;
    }

    .forgot-password-subheading.desktop {
        display: none;
    }

    #reset-title {
        font-size: 30px;
    }

    @media (min-width: 1440px) {

        .offer-popup-coins-when-pays-text {
                font-size: 10px;
        }
        
        .text-desc-white{
            font-size: 20px;
            font-weight: 400px;
        }

        .affiliate-program-card-head { 
            font-size: 24px;
        }

        .affiliates-why-tc-heading{
            font-size: 40px;
            font-weight: 700;
        }
        .affiliates-1-desc {
                font-size: 24px;
                font-weight: 400;
        }

        .affiliates-feedback-item p {
        max-width: 273px;
        font-size: 20px;
        }
        
        .affiliates-tools-item-right h6 {
            color: #FFF;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 6px;
        }
        
        .affiliates-tools-item-right p {
            color: #FFF;
            font-size: 20px;
            font-weight: 400;
        }
        .affiliates-designed-for-image .text-first {
                top: 50px;
                left: 134px;
            }
        
            .affiliates-designed-for-image .text-second {
                top: 190px;
                left: 880px;
            }
        
            .affiliates-designed-for-image .text-third {
                top: 328px;
                right: 350px;
            }

        .providers-homepage-title.desktop {
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }

        .month-total-container.earning .monthly-treat-coins-amount-number {
            font-size: 44px;
        }

        .month-total-container.earning .monthly-treat-coins-amount-tc {
            font-size: 26px;
        }

        .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-amount {
            font-size: 21px;
        }

        .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-label {
            font-size: 20px;
        }

        .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-title {
            font-size: 20px;
        }
    }

    @media (min-width: 1700px) {

        .month-total-container.earning .monthly-treat-coins-amount-number {
            font-size: 52px;
        }

        .month-total-container.earning .monthly-treat-coins-amount-tc {
            font-size: 32px;
        }

        .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-amount {
            font-size: 34px;
        }

        .month-total-container.earning .treat-coins-detailed-wrap .treatcoins-breakdown-label {
            font-size: 28px;
        }
    }
}

@media (min-width: 1440px) and (min-height: 1072px){
    .how-it-works-heading {
        font-size: 32px;
        margin-bottom: 32px;
    }

    .how-it-works-card-heading {
        font-size: 20px;
    }

    .how-it-works-card-desc {
        font-size: 16px;
    }
}

@media (min-width: 1440px) {
    .affiliates-head {
            font-size: 64px;
            width: 978px;
            margin: 0 auto;
            margin-bottom: 40px;
        }
    .build-my-team-refer-friends-desc-pink {
        font-size: 32px;
        margin-bottom: 28px;
    }

    .build-my-team-refer-friends-desc {
        font-size: 20px;
        margin-bottom: 11px;
    }

    .build-my-team-refer-friends-link-value {
        font-size: 16px;
    }

    .build-my-team-earnings-name {
        font-size: 24px;
    }

    .build-my-team-earnings-value {
        font-size: 28px;
    }

    .stats-row-desc {
        font-size: 18px;
        margin-bottom: 7px;
    }

    .stats-row-ref-count {
        font-size: 16px;
    }

    .stats-row-ref-earnings-count {
        font-size: 20px;
    }
}
@media screen and (min-height: 1024px) and (min-width: 682px) {
    .term-popup-step-terms-and-conditions-head {
        margin-top: 30px;
    }
}