/* todo migrate user_stats styles here, general to move/keep in general files */
.full-screen-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.user-stats {
    font-family: Poppins;
}
.user-stats-button {
    left: 5%;
    top: 5%;
    position: absolute;
}

.benefits-container {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.benefits-card {
    max-width: 110px;
}

.benefits-logo {
    width: 32px;
    height: 32px;
}

.benefits-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #0A0B14;
    opacity: 0.7;
}

.daily-earnings-plan-hint {
    padding: 40px 25px 80px 25px;
}

.daily-earnings-plan-hint h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
}

.daily-earnings-plan-hint_desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
}

.daily-earnings-plan-hint_desc span {
    font-weight: 700;
    color: #5FC88F;
}

.daily-earnings-plan-hint_bubble1,
.daily-earnings-plan-hint_bubble2 {
    position: absolute;
}

.daily-earnings-plan-hint_bubble1 {
    top: 20px;
    left: 40px;
}

.daily-earnings-plan-hint_bubble2 {
    top: 20px;
    right: 40px;
}

.surveys-container {
    overflow: scroll;
    padding: 18px 16px 0px 18px;
}

.survey-button{
    /* position: absolute; */
}

@media screen and (min-width: 1024px) {
    .user-stats-button {
        top: 40%;
    }
}