.hot-offer-swiper .swiper-slide-prev div {
    opacity: 0.7;
    border-radius: 6px;
}

.hot-offer-swiper .swiper-slide-next div {
    opacity: 0.7;
    border-radius: 6px;
}

.hot-offer-swiper.new .swiper-slide-prev div {
    background: none;
}

.hot-offer-swiper.new .swiper-slide-next div {
    background: none;
}

.hot-offer-slider-slide-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hot-offer-slider-slide-container.new {
    gap: 20px;
}

.hot-offer-slider-slide-container.new.desktop {
    gap: 0;
}

.hot-offer-carousel-item {
    max-width: 110px;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-radius: 6px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #fff;
    padding: 5px 20px;
}

.hot-offer-carousel-item.new {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    max-width: 180px;
}

@media (min-width: 1024px) {

    .hot-offer-swiper:not(.desktop.one-two-treat-area) {
        margin-top: 15px;
    }

    .hot-offer-slider-slide-container.desktop.one-two-treat-area {
        width: 100%;
    }
}