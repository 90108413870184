.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-text {
    color: #ffffff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    position: absolute;
      top: 49%;
      left: 50%;
      transform: translateX(-50%);
    }
    
    .rotate-image {
      animation: rotateAnimation 3s ease-in-out infinite;
      transform-origin: center;
    }
    
    @keyframes rotateAnimation {
      from {
        transform: rotate(0deg);
      }
    
      to {
        transform: rotate(360deg);
      }
  }
  