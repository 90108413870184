.top-offer-homepage-category-desc,
.top-offer-homepage-tc-value,
.top-offer-homepage-usd-value,
.top-offer-homepage-app-name,
.top-offer-homepage-tc-desc,
.top-offer-homepage-usd-desc,
.top-offer-homepage-desc,
.top-offers-homepage-name,
.top-12-offer-page-card-desc,
.top-offer-page-desc,
.top-12-offer-page-card-info-index,
.top-12-offer-page-card-spec-1-name,
.top-offer-homepage-card-tc-desc,
.top-offer-homepage-card-usd-desc,
.top-12-offer-page-card-step-name {
    margin: 0;
    color: #fff;
}

.top-offers-homepage-container {
    position: relative;
    width: 100%;
}
.top-offers-homepage-container.gradient-bg {
    border-radius: 30px;
    margin-top: 40px;
    padding: 20px 30px 20px 20px;
    background: linear-gradient(160deg, #EC79FF80 0%, #141629 35%, #141629 66%, #5F26A8E6 98%);
}

.top-offers-homepage-container-new {
    position: relative;
}


.top-offers-homepage-header,
.top-12-offer-page-card-spec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-offers-homepage-header {
    max-width: 100vw;
    gap: 5vw;
}

.top-offers-homepage-name {
    font-size: 18px;
    font-weight: 600;
    text-align: start;
}
.top-offers-homepage-name.no-offers {
    margin: 0 auto;
    text-align: center;
}

.top-offers-homepage-btn {
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: end;
    min-width: 100px;
}
.top-offers-homepage-list {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    width: 100%;
    list-style-type: none;
    padding: 16px 15px 15px 0;
    margin-top: 0;
    position: relative;
}

.offer-table-container {
    min-width: 178px;
    padding: 14px;
}
.top-offer-homepage-card {
    background-color: #222337;
    width: 235px;
    margin-right: 10px;
    padding: 8px;
    border-radius: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top-offer-homepage-category {
    position: absolute;
    right: 12px;
    top: 12px;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    background: rgba(24, 24, 24, 0.70);
    backdrop-filter: blur(2.5px);
    border-radius: 14px;
    padding: 5px 10px 5px 10px;
    z-index: 2;
}

.top-offer-homepage-category-img {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
}

.top-offer-homepage-first-part {
    width: 100%;
}

.top-offer-homepage-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 14px;
    position: relative;
    margin-bottom: 12px;
    min-width: 156px;
    min-height: 127px;
}

.top-offer-homepage-app-name {
    color: #A9A9C7;
    font-size: 14px;
    line-height: 2em;
    font-weight: 500;
    text-align: start;
}

.top-offer-homepage-desc {
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    text-align: start;
    color: #A9A9C7;
    margin-bottom: 20px;
}

.top-offer-homepage-payout,
.top-offer-homepage-payout-desc {
    display: flex;
    gap: 6px;
    align-items: center;
}

.homepage .top-offer-homepage-payout {
    justify-content: start;
}

.offers-categories-homepage-linked-offer,
.top-offer-homepage-linked-offer {
    position: absolute;
    bottom: 1px;
    right: 8px;
}
.offers-categories-homepage-linked-offer{
    bottom: 20px;
}
.top-offer-homepage-linked-offer.card {
    top: 10px;
    height: 30px;
}

.offers-categories-linked-offers-tooltip,
.top-12-linked-offers-tooltip {
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 10px;
}
.top-offer-homepage-linked-offer.card .top-12-linked-offers-tooltip{
    left: -20px;
    top: 25px;
}

 .top-12-linked-offers-tooltip.categories {
     left: -28px;
 }
.offers-categories-homepage-linked-offer img,
.top-offer-homepage-linked-offer img {
    width: 32px;
    height: 32px;
}

.top-offer-homepage-usd-value-container {
    position: relative;
}

.top-offer-homepage-linked-offer.categories {
    bottom: 18px;
    right: 15px;
    height: 30px;
}

.top-offer-homepage-usd-desc {
    position: absolute;
    bottom: -16px;
    left: 15px;
}

.top-offer-homepage-tc-desc {
    padding-left: 25px;
}
.top-offer-homepage-tc-desc,
.top-offer-homepage-usd-desc {
    font-weight: 400;
    text-align: start;
    font-size: 8px;
    margin-bottom: 8px;
}
.top-offer-homepage-usd-desc {
    position: absolute;
    bottom: -20.5px;
}

.top-offer-homepage-usd-desc.clicked {
    bottom: -12.5px;
}

.offers-categories-homepage-up-to,
.top-offer-homepage-up-to {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    text-align: start;
}
.top-offer-homepage-tc-value {
    font-size: 16px;
    font-weight: 600;
    min-width: 40px;
    text-align: left;
}

.offers-categories-homepage-tc-value,
.offers-categories-homepage-usd-value,
.homepage .top-offer-homepage-tc-value,
.homepage .top-offer-homepage-usd-value {
    font-size: 10px;
    min-width: fit-content;
}

.top-offer-homepage-usd-value {
    font-size: 16px;
}

.offers-categories-coin,
.top-offer-coin {
    display: flex;
}

.offers-categories-homepage-card-tc-desc,
.offers-categories-homepage-card-usd-desc,
.top-offer-homepage-card-tc-desc,
.top-offer-homepage-card-usd-desc {
    font-weight: 400;
    text-align: start;
    font-size: 8px;
}

.offers-categories-table{
    margin-bottom: 10px;
}


.top-offer-homepage-currencies p {
    margin-top: -10px;
}

.top-offers-homepage-scroll-left,
.top-offers-homepage-scroll-right {
    display: none;
    width: 44px;
    height: 44px;
    position: absolute;
    z-index: 3;
}

.top-offers-homepage-scroll-right {
    right: 0;
}
.top-offers-homepage-container.gradient-bg .top-offers-homepage-scroll-right{
    right: 10px;
}
.top-offers-homepage-container.gradient-bg .top-offers-homepage-scroll-right.bottom-35 {
    right: -20px;
}

.top-offers-homepage-scroll-left {
    left: 0;
}

.top-offers-homepage-container.gradient-bg .top-offers-homepage-scroll-left {
    left: 0px;
}

.top-offers-homepage-container.gradient-bg .top-offers-homepage-scroll-left.bottom-35 {
    left: -20px;
}

.top-offers-homepage-scroll-left img,
.top-offers-homepage-scroll-right img {
    width: 44px;
    height: 44px;
}

.top-12-offers-page-container {
    padding: 20px 4px;
    width: 100vw;
    margin: 0 auto;
    min-height: 60vh;
}

.top-offers-homepage-header-left {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: 50vw;
}

.top-12-offers-page-container .top-offers-homepage-header {
    align-items: flex-start;
    max-width: 80vw;
    margin: 0 auto;
}

.top-offers-homepage-header.top-12 {
    margin-bottom: 24px;
}

.top-offer-page-desc {
    font-size: 14px;
    text-align: left;
}

.top-offers-page-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 95vw;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
}

.top-12-offer-page-card {
    width: 345px;
    min-height: 375px;
    position: relative;
}

.top-12-offer-page-card .hidden-button {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.top-12-offer-page-card-desc {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 5px;
}

.top-12-offer-page-card-info {
    background-color: #222337;
    width: 88vw;
    max-width: 345px;
    padding: 10px 5px;
    border-radius: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.top-offer-play-icon {
    padding-top: 10px;
    height: 75px;
}

.top-12-offer-page-card-info,
.top-offer-homepage-card,
.top-offer-play-icon {
    transition: all 0.5s ease-in-out;
}


.top-offer-homepage-card:hover,
.top-offer-homepage-card:focus {
    box-shadow: 0px 0px 10px 2px rgba(140, 253, 1, 0.2);
    transform: translateY(-1px), scale(1.01);
}

.top-12-offer-page-card-info:hover .top-offer-play-icon,
.top-12-offer-page-card-info:focus .top-offer-play-icon {
    transform: scale(1.05);
}

.top-12-offer-page-card-info-index {
    width: 40px;
    height: 40px;
    background-color: #181818;
    color: #4BC68B;
    border-radius: 40px;
    position: absolute;
    z-index: 2;
    font-size: 16px;
    font-weight: 500;
    top: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.top-12-offer-page-card-image {
    width: 340px;
    height: 127px;
    background-repeat: no-repeat;
    /*background-size: cover;*/
    background-size: contain;
    background-position: center;
    border-radius: 14px;
    position: relative;
    margin-bottom: 5px;
}

.top-12-offer-page-category {
    display: flex;
    font-size: 10px;
    font-weight: 500;
    background-color: #181818;
    border-radius: 14px;
    padding: 5px 10px 5px 10px;
    margin-bottom: -15px;
}

.top-12-offer-page-card-spec {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.offers-categories-homepage-treat-coin,
.top-offer-homepage-treat-coin {
    width: 22px;
    height: 22px;
}

.top-12-offer-page-card-spec-1-name {
    color: #A9A9C7;
    font-size: 12px;
    font-weight: 500;
}

.top-12-offer-page-card-steps {
    align-self: baseline;
    padding-left: 20px;
    color: #73738D;
    padding-inline-start: 10px;
    width: 100%;
}

.top-12-offer-page-card-step {
    margin-bottom: 12px;
    position: relative;
}

.top-12-offer-page-card-step button {
    width: 100%;
    margin-left: -6px;
}

.top-12-offer-page-card-step-name {
    color: #73738D;
    font-size: 14px;
    text-align: start;
    font-weight: 400;
    margin-bottom: 5px;
}

.top-12-offer-page-ref-earnings {
    height: 36px;
}

.top-12-offer-page-card-step-border {
    border-bottom: 1px solid #fff;
    width: 104%;
    margin-bottom: 5px;
}

.top-12-offer-page-card-step-earnings {
    display: flex;
    justify-content: space-between;
    padding-right: 2px;
    margin-bottom: 6px;
}

.top-12-offer-page-card-multistep-ref {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    max-width: 40px;
}

.top-12-offer-page-card-multistep-ref img {
    width: 16px;
    height: 16px;
}

.offers-clicked-list .gold-btn {
    display: none;
}

.top-offer-homepage-linked-offer:hover .offers-categories-linked-offers-tooltip,
.top-offer-homepage-linked-offer:hover .top-12-linked-offers-tooltip {
    visibility: visible;
    opacity: 1;
}

.offers-categories-linked-offers-tooltip {
    left: -40px;
}
@media screen and (max-width: 1023px) {
    .top-offers-homepage-list {
        max-width: 95vw;
    }
    .top-offer-homepage-image {
        min-width: 127px;
        min-height: 77px;
        background-size: cover;
    }

    .top-offers-homepage-container {
        min-height: 320px;
    }
        .robot-positioner {
            margin-top: 110px;
        }
}

@media screen and (min-width: 800px) {
    .top-12-offer-page-card-steps {
        max-height: 170px;
        overflow: scroll;
    }

    .top-offers-homepage-scroll-left,
    .top-offers-homepage-scroll-right {
        display: block;
    }

    .top-offers-homepage-scroll-right {
        right: -40px;
    }
}
@media screen and (min-width: 1024px) {
    .robot-positioner {
            margin-top: 300px;
        }
   
    .top-offers-homepage-container-new.user {
        width: 100%;
    }

    .top-offers-homepage-scroll-left {
        left: -30px;
    }

    .top-offers-homepage-scroll-right {
        right: -30px;
    }

    .top-12-offer-page-card-desc {
        min-height: 81px;
    }

    
}


@media screen and (min-width: 1440px) {
    .top-offer-homepage-linked-offer.categories {
        bottom: 34px;
    }
}
@media screen and (min-width: 1600px) {
    .top-12-offer-page-card {
        width: 484px;
    }
    
    .top-12-offer-page-card-info {
        max-width: 485px;
    }
    
    .top-12-offer-page-card-image {
        width: 460px;
        height: 215px;
    }
    
    .top-offers-page-list {
        gap: 40px;
    }
}
@media screen and (max-width: 1027px) {
    .top-offers-homepage-list.second {
        margin-bottom: 80px;
    }
}