.bottom-menu {
    font-family: Poppins;
    position: fixed;
    bottom: -5px;
    width: 100%;
    max-height: 85px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #131522;
    z-index: 4;
    gap: 5px;
}

.bottom-menu-item {
    padding-right: 20px;
    padding-left: 20px;
    height: 72px;
    width: 75px;
    margin: 16px 0 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 2px;
    padding-bottom: 8px;
}

.bottom-menu-item-icon {
    width: 28px;
    height: 30px;
}

.burger-menu-bottom {
    position: relative;
    width: 32px;
    height: 32px;
}
.bottom-menu-item-desc {
    color: #73738D;
    margin: 0;
    font-size: 10px;
    text-align: center;
    min-width: 45px;
    height: 30px;
    width: 70px;
    overflow: visible;
}

.chosen-bottom-menu {
    background: linear-gradient(174.01deg, #4AABF5 -0.35%, #EA4661 99.52%), linear-gradient(174.01deg, #FFFFFF -0.35%, #EA4661 99.52%);
    box-shadow: 0px 4px 12px #5352B5;
    height: 105px;
    justify-content: start;
    padding-top: 10px;
    border-radius: 10px;
    transform: translateY(-27%);
    transition: all 0.3s ease-in-out;
}

.chosen-bottom-menu .bottom-menu-item-desc {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
}

.show-team-stats {
    display: block;
    transition: all 1s ease-in-out;
}

.hide-team-stats {
    display: none;
}

.link-button-image-back{
    margin-right: 10px;
    margin-left: 10px;
}

/* @media screen and (max-width: 579px) {
    .display-none-mobile{
        display: none;
    }
} */

@media screen and (min-width: 579px) {

    .bottom-menu-item {
        width: 75px;
    }

    .chosen-bottom-menu .bottom-menu-item-desc {
         font-size: 12px;
    }

}

@media screen and (min-width: 1024px) {

    .bottom-menu-item {
        width: 95px;
    }

    /* .hide-team-stats, .show-team-stats{
        margin-top: -30px;
    } */

    .bottom-menu {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
    
    .bottom-menu-item-icon {
        width: 32px;
        height: 32px;
    }

}