.simplebar-scrollbar:before {
    background: #9F7DDB !important;
}

@media (min-width: 1024px) {

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #7674F5;
        background: rgba(221, 221, 228, 0.5);
    }
}
