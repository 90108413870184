@-webkit-keyframes faq-link-color {
    from {
        color: #8A88FF;
    }

    to {
        color: #5FC88F;
    }
}

@-ms-keyframes faq-link-color {
    from {
        color: #8A88FF;
    }

    to {
        color: #5FC88F;
    }
}

@-o-keyframes faq-link-color {
    from {
        color: #8A88FF;
    }

    to {
        color: #5FC88F;
    }
}

@-moz-keyframes faq-link-color {
    from {
        color: #8A88FF;
    }

    to {
        color: #5FC88F;
    }
}

@keyframes faq-link-color {
    from {
        color: #8A88FF;
    }

    to {
        color: #5FC88F;
    }
}

@-webkit-keyframes white-to-green-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-ms-keyframes white-to-green-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-o-keyframes white-to-green-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-moz-keyframes white-to-green-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@keyframes white-to-green-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-webkit-keyframes footer-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-ms-keyframes footer-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-o-keyframes footer-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-moz-keyframes footer-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@keyframes footer-link-color {
    from {
        color: #FFFFFF;
    }

    to {
        color: #5FC88F;
    }
}

@-webkit-keyframes dark-footer-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #32810B;
    }
}

@-ms-keyframes dark-footer-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #32810B;
    }
}

@-o-keyframes dark-footer-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #32810B;
    }
}

@-moz-keyframes dark-footer-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #32810B;
    }
}

@keyframes dark-footer-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #32810B;
    }
}

@-webkit-keyframes purple-to-light-green {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@-ms-keyframes purple-to-light-green {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@-o-keyframes purple-to-light-green {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@-moz-keyframes purple-to-light-green {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@keyframes purple-to-light-green {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@-webkit-keyframes purple-to-dark-green {
    from {
        color: #7674F5;
    }

    to {
        color: #32810B;
    }
}

@-ms-keyframes purple-to-dark-green {
    from {
        color: #7674F5;
    }

    to {
        color: #32810B;
    }
}

@-o-keyframes purple-to-dark-green {
    from {
        color: #7674F5;
    }

    to {
        color: #32810B;
    }
}

@-moz-keyframes purple-to-dark-green {
    from {
        color: #7674F5;
    }

    to {
        color: #32810B;
    }
}

@keyframes purple-to-dark-green {
    from {
        color: #7674F5;
    }

    to {
        color: #32810B;
    }
}

@-webkit-keyframes swal2-link-color {
    from {
        color: #7674F5;
    }

    to {
        color: #5FC88F;
    }
}

@-ms-keyframes swal2-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #5FC88F;
    }
}

@-o-keyframes swal2-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #5FC88F;
    }
}

@-moz-keyframes swal2-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #5FC88F;
    }
}

@keyframes swal2-link-color {
    from {
        color: #282B54;
    }

    to {
        color: #5FC88F;
    }
}


@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    24% {
        opacity: 1;
    }

    82% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fade-in {
    0% {
        opacity: 0;
    }

    24% {
        opacity: 1;
    }

    82% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fade-in {
    0% {
        opacity: 0;
    }

    24% {
        opacity: 1;
    }

    82% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }

    24% {
        opacity: 1;
    }

    82% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    24% {
        opacity: 1;
    }

    82% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes provider-homepage-card-left {
    0% {
        opacity: 0;
        margin-left: -200px;
        margin-right: 0;
    }

    100% {
        margin-left: 0;
        margin-right: 0;
        opacity: 1;
    }
}

@-webkit-keyframes provider-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes provider-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes provider-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes provider-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes provider-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes scale-in {
    0% {
        -webkit-transform: scale(0.1);
    }

    24% {
        -webkit-transform: scale(1);
    }

    82% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.1);
    }
}

@-ms-keyframes scale-in {
    0% {
        -webkit-transform: scale(0.1);
    }

    24% {
        -webkit-transform: scale(1);
    }

    82% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.1);
    }
}

@-o-keyframes scale-in {
    0% {
        -webkit-transform: scale(0.1);
    }

    24% {
        -webkit-transform: scale(1);
    }

    82% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.1);
    }
}

@-moz-keyframes scale-in {
    0% {
        -webkit-transform: scale(0.1);
    }

    24% {
        -webkit-transform: scale(1);
    }

    82% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.1);
    }
}

@keyframes scale-in {
    0% {
        -webkit-transform: scale(0.1);
    }

    24% {
        -webkit-transform: scale(1);
    }

    82% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.1);
    }
}

@-webkit-keyframes move-in-left {
    0% {
        margin-left: -300px;
    }

    24% {
        margin-left: -13px;
    }

    82% {
        margin-left: -13px;
    }

    100% {
        margin-left: -300px;
    }
}

@-ms-keyframes move-in-left {
    0% {
        margin-left: -300px;
    }

    24% {
        margin-left: -13px;
    }

    82% {
        margin-left: -13px;
    }

    100% {
        margin-left: -300px;
    }
}

@-o-keyframes move-in-left {
    0% {
        margin-left: -300px;
    }

    24% {
        margin-left: -13px;
    }

    82% {
        margin-left: -13px;
    }

    100% {
        margin-left: -300px;
    }
}

@-moz-keyframes move-in-left {
    0% {
        margin-left: -300px;
    }

    24% {
        margin-left: -13px;
    }

    82% {
        margin-left: -13px;
    }

    100% {
        margin-left: -300px;
    }
}

@keyframes move-in-left {
    0% {
        margin-left: -300px;
    }

    24% {
        margin-left: -13px;
    }

    82% {
        margin-left: -13px;
    }

    100% {
        margin-left: -300px;
    }
}

@-webkit-keyframes move-in-right {
    0% {
        margin-right: -300px;
    }

    24% {
        margin-right: -13px;
    }

    82% {
        margin-right: -13px;
    }

    100% {
        margin-right: -300px;
    }
}

@-ms-keyframes move-in-right {
    0% {
        margin-right: -300px;
    }

    24% {
        margin-right: -13px;
    }

    82% {
        margin-right: -13px;
    }

    100% {
        margin-right: -300px;
    }
}

@-o-keyframes move-in-right {
    0% {
        margin-right: -300px;
    }

    24% {
        margin-right: -13px;
    }

    82% {
        margin-right: -13px;
    }

    100% {
        margin-right: -300px;
    }
}

@-moz-keyframes move-in-right {
    0% {
        margin-right: -300px;
    }

    24% {
        margin-right: -13px;
    }

    82% {
        margin-right: -13px;
    }

    100% {
        margin-right: -300px;
    }
}

@keyframes move-in-right {
    0% {
        margin-right: -300px;
    }

    24% {
        margin-right: -13px;
    }

    82% {
        margin-right: -13px;
    }

    100% {
        margin-right: -300px;
    }
}

@keyframes cursor-blink-animation {
    from,to {
        color: transparent
    }

    50% {
        color: #000
    }
}

@-moz-keyframes cursor-blink-animation {
    from,to {
        color: transparent
    }

    50% {
        color: #000
    }
}

@-webkit-keyframes cursor-blink-animation {
    from,to {
        color: transparent
    }

    50% {
        color: #000
    }
}

@-ms-keyframes cursor-blink-animation {
    from,to {
        color: transparent
    }

    50% {
        color: #000
    }
}

@-o-keyframes cursor-blink-animation {
    from,to {
        color: transparent
    }

    50% {
        color: #000
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes hot-offer {
    0% {
        outline: none;
        right: calc(50vw - 100px);
        width: 250px;
        height: fit-content;
        padding: 5px 0;
        transform: translateY(300px);
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
    }
    35% {
        outline: none;
        right: calc(50vw - 100px);
        width: 250px;
        height: fit-content;
        padding: 5px 0;
        transform: translateY(-10px);
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
    }
    45% {
        outline: none;
        right: calc(50vw - 100px);
        width: 250px;
        height: fit-content;
        padding: 5px 0;
        transform: translateY(0);
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
    }
    75% {
        font-size: 0;
        outline: none;
        transform: translateY(-15px);
    }
    100% {
        font-size: 0;
        width: 18px;
        outline: 1px solid #fff;
    }
}
