html,
body {
    height: 100%;
}

#root,
#root-div {
    height: 100vh;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #141629;
    text-align: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#main {
    flex: 1 0 auto;
    background-color: #141629;
}

#main.suspended {
    min-height: 100vh;
}

#content {
    flex: 1 0 auto;
}

#main.light-mode {
    background: linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%);
    /* min-height: 900px; */
    height: 100vh;
}

section {
    background-color: #141629;
    color: #fff;
    font-size: calc(10px + 2vmin);
    text-align: center;
}

section.light-mode {
    background-color: transparent;
}

.switch-wrapper > div {
    position: absolute;
    width: 100%;
    height: 100vh;
}

.switch-wrapper > div > div:not(.intro-survey-starting-section-container.desktop) {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.opacity-5 {
    opacity: .5;
}

.opacity-8 {
    opacity: 0.8;
}
.font-18 {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.f-weight-600 {
    font-weight: 600;
}

.font-weight-medium {
    font-weight: 400 !important;
}

.capitalize {
    text-transform: capitalize;
}

.text-center {
    text-align: center;
}

.text-success {
    color: #49C188;
}

.text-center-i {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-limit-1rows {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.text-limit-2rows {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-limit-3rows {
    overflow: hidden;
    max-height: 85px;
    transition: all 0.6s ease-in-out;
}

.text-limit-3rows.desc-expanded {
    overflow: auto;
    max-height: 30vh;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.relative {
    position: relative;
}

.overflow-scroll {
    overflow: scroll;
}
.hidden-element {
    display: none;
}

.pre-line{
    white-space: pre-line;
}

.z-1 {
    z-index: 1;
}

.z-5 {
    z-index: 5 !important;
}

.rotate180 {
    transform: rotate(180deg);
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-auto {
    overflow: auto !important;
}

.translate-85 {
    transform: translateX(85%) !important;
}

.translate-120 {
    transform: translateX(120%) !important;
}

.h-full {
    height: 100%;
}

.h-full-i {
    height: 100% !important;
}

.w-full {
    width: 100% !important;
}

.max-w-fit-content {
    max-width: fit-content;
}

.max-w-full {
    max-width: 100%;
}

.max-w-half {
    max-width: 50%;
}
.max-w-75 {
    max-width: 75%;
}

.w-44 {
    width: 40px !important;
    overflow: hidden;
}
.w-fit {
    width: fit-content !important;
}

.w-max {
    width: max-content !important;
}

.w-full-3 {
    width: 33.33%;
}

.survey-offer-btn {
    position: relative;
    background: #6260E1 !important;
    font-size: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.max-w-full {
    max-width: 100%;
}

.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
}

.inline-block {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.direction-row-wrap {
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row !important;
}

.flex-column {
    display: flex !important;
    flex-direction: column !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex {
    display: flex !important;
    align-items: center;
}


.flex-column-centered {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.font-smallest{
    font-size: 8px;
}

.flex-grow {
    flex-grow: 1;
}
.gap-1-i {
    gap: 1px !important;
}
.gap-5-i {
    gap: 5px !important;
}

.gap-5 {
    gap: 5px;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}


.justify-between {
    justify-content: space-between;
    height: 100%;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: end;
}
.items-center {
    align-items: center;
}

.space-between{
    justify-content: space-between;
}

.items-start {
    align-items: flex-start;
}

.items-start-i {
    align-items: flex-start !important;
}

.items-end {
    align-items: flex-end;
}

.underline {
    text-decoration: underline;
}

.radius-0 {
    border-radius: 0px !important;
}

.border-purple{
    border: 1px solid #9F7DDB;
}

.margin-auto {
    margin: auto !important;
}

.wh-21px {
    width: 21px;
    height: 21px;
}

.wh-31px {
    width: 31px;
    height: 31px;
}

.flex-row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.d-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-none {
    display: none !important;
}

.d-grid{
    display: grid;
}

.d-flex-center-gap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.m-0-auto{
    margin: 0 auto;
}

.margin-0 {
    margin: 0px !important;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-right-2 {
    margin-right: 2px !important;
}

.margin-right-8 {
    margin-right: 9px !important;
}
.margin-right-10 {
    margin-right: 10px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-minus-10 {
    margin-left: -10px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-bottom-2 {
    margin-bottom: 2px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-16-25 {
    margin-bottom: 16px !important;
}

.margin-bottom-32-40 {
    margin-bottom: 32px !important;
}
.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-24 {
    margin-bottom: 24px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-top_15 {
    margin-top: -15px;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.margin-top-8 {
    margin-top: 8px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top_10 {
    margin-top: -10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top_20 {
    margin-top: -20px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-45 {
    margin-top: 45px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top_80 {
    margin-top: -80px !important;
}

.margin-top-60 {
    margin-top: 60px !important;
}

.margin-top_60 {
    margin-top: -60px !important;
}

.margin-top-60-100 {
    margin-top: 60px;
}
.margin-x-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.padding-0 {
    padding: 0px !important;
}

.padding-4 {
    padding: 4px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important; 
}

.padding-20 {
    padding: 20px !important;
}

.padding-right-20 {
    padding-right: 20px !important;
}
.padding-top-2 {
    padding-top: 2px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}
.padding-top-14 {
    padding-top: 14px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-top-25 {
    padding-top: 25px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-top-35 {
    padding-top: 35px !important;
}
.padding-top-40 {
    padding-top: 40px !important;
}

.padding-top-80 {
    padding-top: 80px !important;
}
.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.padding-bottom-30 {
    padding-bottom: 30px !important;
}
.padding-bottom-85 {
    padding-bottom: 85px !important;
}

.padding-x-5{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.padding-x-6 {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.padding-x-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.padding-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-x-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.padding-x-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.padding-x-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.bottom-45 {
    bottom: 45px !important;
}

.bottom-165 {
    bottom: 165px !important;
}
.bottom-240-iOS {
    bottom: 240px !important;
}

.top-25 {
    top: 25% !important;
}

.bottom-35 {
    bottom: 35%;
}
.wh-25 {
    width: 25px;
    height: 25px;
}

.wh-18 {
    width: 18px;
    height: 18px;
}

.height-150-250 {
    height: 150px;
}
.shadow-none {
    box-shadow: none !important;
}

.min-height-288 {
    min-height: 288px;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-scale-transitioned {
    transition: all 0.5s ease-in-out;
}

.hover-scale-transitioned:hover {
    transform: scale(1.07);
}

.white-i {
    color: white !important;
}
.white {
    color: white;
}

.text-500 {
    font-weight: 500 !important;
}

.text-700 {
    font-weight: 700;
}

.underlined-text {
    text-decoration: underline !important;
}
.text-5-i {
    font-size: 5px !important;
}

.text-8-i {
    font-size: 8px !important;
}
.text-10-i {
    font-size: 10px !important;
}
.text-12-i {
    font-size: 12px !important;
}
.text-16-i {
    font-size: 16px !important;
}
.text10 {
    font-size: 10px;
}

.bg-white {
    background: white;
}

.black {
    color: black;
}

.bg-black {
    background: black;
}

.color-grey{
    color: #141629;
    opacity: 0.5;
}

.color-red{
    color: #D13C3C;
}

.bg-gradient-1{
    background: linear-gradient(106.74deg, #2CD8D5 6.37%, #C5C1FF 50.17%, #FFBAC3 100.44%);
}

.bg-gradient-2{
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
}

.text-gradient-2{
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.bg-gradient-3{
    background: linear-gradient(#93B5B0 -17.63%, #317C72 50.17%, #38897E 110.31%);
}

.hidden-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    overflow: hidden;
}

.fade-enter {
    opacity: 0;
    transform: translate(0, 25px);
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 1250ms ease-out, transform 300ms ease;
}

.fade-exit {
    opacity: 1;
    transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate(0, 30px);

    transition: opacity 1250ms ease-out, transform 300ms ease;
}

.website-swal-modal.swal2-popup{
    border-radius: 38px;
    padding: 20px;
}

.website-swal-modal .swal2-html-container{
    margin: 0;
}

.website-swal-modal #swal2-title {
    margin: 0;
    padding: 0;
}

.website-swal-modal .swal2-close {
    margin-top: 0;
    padding: 0;
    height: 25px;
}

@media (min-width: 1024px) {
    .height-150-250 {
            height: 250px;
        }
    .margin-top-60-100 {
            margin-top: 85px;
        }
    .margin-bottom-16-25 {
            margin-bottom: 25px !important;
        }

    .margin-bottom-32-40 {
        margin-bottom: 40px;
    }
    /* .flex-desktop-center {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        } */
    
        .display-none-desktop {
            display: none !important;
        }
    .offers-section-difficulty:hover,
    .offers-section-child:hover,
    .hash-tag-container:hover,
    .hash-tags-toggle:hover {
        cursor: pointer;
    }

    .join-option-div:hover,
    .refer-share-icon:hover,
    .see-more-link:hover,
    .earn-coins-arrow:hover,
    .tell-me-more-link:hover,
    .scroll-to-top-button:hover {
        cursor: pointer;
    }

    .font-18 {
        font-size: 20px;
    }

    .max-w-half-desktop {
        max-width: 50%;
    }
    .max-w-50-desktop {
        max-width: 50%;
    }
@media (max-width: 1300px) {
    
    .w-auto-small-desktop{
        width: auto;
    }
    .desktop-min-height-320 {
        min-height: 320px !important;
    }
}

.mbt-65-desktop {
    margin-bottom: 65px !important;
    margin-top: 65px !important;
}

.mb-40-desktop {
    margin-bottom: 45px !important;
}

.m-0-auto-desktop {
    margin: 0 auto;
}
}

@media (max-width: 727px) {
    .bottom-310-tablet {
        bottom: 310px;
    }
}


@media (max-width: 580px) {
    .display-none-tablet {
        display: none !important;
    }
}
@media (max-width: 1024px) {

    .display-none-mobile {
            display: none !important;
        }
    .max-w-half-mobile {
        max-width: 50%;
    }

    .mobile-margin-top-0 {
        margin-top: 0 !important;
    }
.mobile-margin-right-10 {
    margin-right: 10px !important;
}

.mobile-padding-25 {
    padding: 0 25px !important;
}

.mobile-width-auto {
    width: auto !important;
}

    @media (min-width: 767px) {
        .right-0 {
            right: 0 !important;
        }
    }

}

