.terms-checkbox,
.subscription-settings-checkbox,
.activity-preferences-checkbox {
    display: none;
}

.checkbox-container {
    position: relative;
    display: block;
    margin-top: 17px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #CED0DE;
    text-align: left;
}

.checkbox-container.register {
    display: flex;
    align-items: center;
}

.checkbox-container.register[for="terms"] {
    margin-bottom: 25px;
}

.receive-newsletters-container {
    margin-top: 0;
}

.checkbox-container.light-mode {
    color: #282B54;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark-checkbox {
    position: absolute;
    top: 2px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #6C6AEB;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 0;
    background-color: #141629;
    background-color: rgba(20, 22, 41, 0.6);
}

.register .checkmark-checkbox {
    background-color: unset;
    border-radius: 5px;
}

.checkmark-checkbox.right-checkbox {
    left: 40px;
}

.checkmark-checkbox.left-checkbox {
    z-index: 3;
}

.checkbox-container input:checked ~ .checkmark-checkbox {
    background-color: #141629;
    background-color: rgba(20, 22, 41, 0.6);
}

.checkbox-container.register input:checked ~ .checkmark-checkbox {
    background-color: unset !important;
}

.checkmark-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark-checkbox:after {
    display: block;
}

.checkbox-container .checkmark-checkbox:after {
    left: 9px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid #61F2A3;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container.register .checkmark-checkbox:after {
    border: solid #6C6AEB;
    border-width: 0 2px 2px 0;
}

.checkbox-container.subscription-settings-container,
.checkbox-container.activity-preferences-container {
    line-height: 28px;
    padding-left: 41px;
}

.checkbox-container.allow-survey-data-sharing-container {
    line-height: 28px;
    padding-left: 41px;
    margin: 8px 10px;
    color: #282B54;
}

.checkbox-container.subscription-settings-container-dual {
    padding-left: 73px;
}

.checkbox-container.terms-container {
    padding-left: 18px;
}

@media (min-width: 500px) {
    .checkbox-container.allow-survey-data-sharing-container {
        margin: 8px 20px;
    }
}

@media (min-width: 1000px) {
    .checkbox-container.allow-survey-data-sharing-container {
        margin: 8px 40px;
    }
}

@media (min-width: 1500px) {
    .checkbox-container.allow-survey-data-sharing-container {
        margin: 8px 50px;
    }
}