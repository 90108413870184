.hot-offer-button.playground {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    color: #FFFFFF;
}

.offer-steps-container {
    margin: 10px 21px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(20, 22, 41, 0.3);
}

.offer-steps-container .title-font,
.offer-steps-container .title-font-10 {
    display: inline-block;
    color: #141629;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
}

.offer-steps-container .title-font-10 {
    font-size: 10px;
}

.offer-steps-container .title-font.opacity {
    opacity: 0.6;
}

.offer-steps-container .step-row {
    padding: 5px 0;
    border-top: 1px solid rgba(20, 22, 41, 0.1);
}

.offer-steps-container .outlined {
    width: fit-content;
    max-width: 50%;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(137, 56, 185, 1);
}

.offer-steps-container span.expanded-step-completion-indicator {
    padding: 10px;
}

@media (min-width: 1024px) {
    .offer-steps-container .title-font {
        font-size: 16px;
    }

    .offer-steps-container .title-font-10 {
        font-size: 12px;
    }
}