.join-login-buttons-container {
    display: inline-block;
    width: 312px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%) scale(0.8);
}

.join-position-relative {
    position: relative;
}

.join-homepage-head-content {
    position: absolute;
    transform: translateX(50%);
    bottom: 8%;
    z-index: 4;
    right: 50%;
    font-size: 16px;
    padding: 20px 15px 15px;
    border-radius: 10px;
    color: #141629;
    background-color: #fff;
    width: 90vw;
    max-width: 600px;
}
.join-homepage-head-content.iOS{
    bottom: 14%;
}

.join-content-signup {
    padding-top: 50px;
}

.join-content {
    width: 100vw;
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    padding: 20px 15px 15px 15px;
    background: #FFFFFF;
    color: #000;
    box-shadow: 0px 4px 4px rgb(159 125 219 / 15%);
    border-radius: 22px;
    max-width: 600px;
}

.join-bg-decor {
    position: absolute;
    width: 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.join-bg-decor.login {
    bottom: 80px;
}

.join-with-reg-email {
    border: none;
    background: none;
    box-shadow: none;
    top: 0%;
}


.join-home-body {
    width: 100%;
    margin: 0 auto;
}

.earn-coins-arrow {
    width: 18px;
    height: auto;
    margin-bottom: -15px;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.join-content-email-input {
    width: 100%;
    max-width: 540px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 10px;
}

.join-content.signup-page {
    bottom: 106px !important;
}

.join-content-email-input input {
    width: 100%;
    border: 1px solid #DFDFE5;
    border-radius: 18px;
    text-align: start;
    padding: 21px 10px;
}

.join-content-email-input input::placeholder {
    color: #9395A9;
    text-align: left;
}

.join-content-email-input input:focus,
.join-content-email-input input:focus-visible {
    outline: none;
    border: 1px solid #9F7DDB;
}

.join-content-email-input-button {
    position: absolute;
    right: 4px;
    top: 3px;
    margin-top: 0;
    line-height: 28px;
    padding: 15px 20px;
}

.join-content-heading {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 5px;
}

.join-content-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 20px;
    position: relative;
}

.join-content-desc-link {
    font-weight: 600;
    color: #7674F5;
}

.join-signup-policy-desc {
    font-family: Poppins;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    line-height: 17px;
    color: #BFC0C9;
    max-width: 390px;
    text-align: center;
    margin: 0 auto;
    position: relative;
}

.join-signup-policy-desc-link {
    color: #BFC0C9;
    text-decoration: underline;
}

.join-robot-avatar {
    z-index: 1;
    width: 24vw;
    height: auto;
    display: block;
    position: absolute;
    bottom: 100px;
    right: 0;
    filter: drop-shadow(1px 1px 5px rgba(221, 221, 221, 1));
}

.join-robot-avatar.sign-up{
    width: 18vh;
    right: 0;
}

.join-robot-avatar.iOS {
    bottom: 140px;
}
.join-robot-avatar.sign-up.iOS{
    bottom: 130px;
}

@media screen and (min-width: 767px) {
    .join-robot-avatar {
        width: 250px;
    }

    .join-login-buttons-container {
        transform: translateX(-50%);
    }
}

@media screen and (min-width: 1024px) {
    
    .join-home-body{
        margin: 0;
    }

    .join-robot-avatar {
            width: 350px !important;
        }

    .earn-coins-arrow {
        width: 35px;
        height: auto;
        margin-bottom: -20px;
    }

    @media (max-height: 800px) {

        .join-login-buttons-container {
            bottom: -10%;
        }
    }

}

@media (max-height: 800px) {

    .join-login-buttons-container {
        bottom: -10%;
    }
}

@media (min-height: 801px) and (max-height: 900px) {

    .join-login-buttons-container {
        bottom: 10%;
    }
}

@media screen and (max-width: 767px) and (max-height: 740px) {
        .join-bg-decor.login {
                bottom: 230px;
            }
}