* {
    box-sizing: border-box;
}

.offer-popup p,
.offer-popup h1,
.offer-popup h2,
.offer-popup h3,
.offer-popup h4,
.offer-popup h5,
.offer-popup ul,
.offer-popup h6 {
    margin: 0;
}

.offer-popup *::-webkit-scrollbar {
    display: inline-block;
    visibility: hidden !important;
}

.offer-popup-category-sticker {
    width: max-content;
    position: absolute;
    top: 115px;
    transform: translate(-50%, -50%);
    left: 50%;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.offer-popup-play-relative {
    position: relative;
    max-height: 81px;
}

.offer-popup-play-container {
    width: 100%;
    max-height: 81px;
    border-radius: 14px;
}

.offer-popup-play-button {
    max-height: 81px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
}

.offer-popup {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 14px;
    background: #222337;
    position: relative;
    padding: 44px 10px 20px 10px;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-weight: 500 !important;
    font-style: normal;
    line-height: 150%;
    text-align: start;
    max-width: 724px;
    margin: 0 auto;
}

.offer-popup.is-gold {
    box-shadow: 0px 0px 33.9px 0px #FFB629;
}
.offer-popup-white-heading-14 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 4px;
    text-align: start;
}

.offer-popup-gradient-heading-20 {
    text-align: center;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 700;
    background: linear-gradient(44deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.offer-popup-grey-text-12 {
    font-size: 12px;
    color: #A9A9C7;
    line-height: 18px;
}

.offer-popup-dark-text-12 {
    color: #222337;
    font-size: 12px;
    font-weight: 500;
}

.offer-popup-grey-container {
    padding: 10px;
    border-radius: 8px;
    background: #303149;
}

.linked-offers {
    display: none;
}

.linked-offers.displayed {
    display: flex;
}

.offer-popup .close-button {
    position: absolute;
    right: 10px;
        top: 10px;
}

.offer-popup-head {
    display: flex;
    align-items: center;
    gap: 8px;
}

.offer-popup-head-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 120px;
    height: 120px;
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.offer-popup-head-right {
    padding-top: 5px;
    padding-bottom: 5px;
    overflow-wrap: break-word;
}


.offer-popup-head-right-maintitile {
    color: #A9A9C7;
    font-size: 14px;
    margin-bottom: 12px !important;
}

.offer-popup .top-offer-homepage-tc-value {
    font-size: 16px;
}

.offer-popup .top-offer-homepage-usd-value-container,
.offer-popup .top-offer-homepage-tc-desc {
    font-size: 12px;
}

.offer-popup .top-offer-homepage-usd-desc {
    bottom: -24px;
    font-size: 12px;
}

.offer-popup-list {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    gap: 10px;
}

.offer-popup-category {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 5px 10px;
    border-radius: 8px;
    background: #303149;
}

.offer-popup-report-input-choose-file {
    display: flex;
    position: relative;
    gap: 20px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}

.offer-popup-report-input-file-select {
    display: flex;
    position: relative;
    width: 130px;
    height: 44px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #9395A9;
}

.offer-popup-report-input-file-select-modal {
    display: flex;
    width: 80vw;
    max-width: 384px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #9395A9;
    background: #FFF;
    position: absolute;
    top: 10px;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.offer-popup-report-input-file-select-modal li {
    list-style-type: none;
    width: 100%;
}

.offer-popup-report-input-file-select-modal-mandatory {
    color: #7F2A2A;
    font-size: 10px;
    font-weight: 500;
    justify-self: end;
    display: block;
}

.offer-popup-report-input-file-select-modal-mandatory.absolute {
    position: absolute;
    bottom: -25px;
    left: 0;
    color: #EE0000;
}

.success-color {
    color: #78C885 !important;
}

.offer-popup-category-img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.offer-popup-desc-show-more-text {
    background: linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 5px;
    align-self: flex-end;
}

.offer-popup-desc-arrow {
    transition: all 0.4s ease-in-out;
}

.offer-popup-rewards-list {
    display: flex;
    gap: 30px;
    padding-left: 0 !important;
    justify-content: center;
}

.offer-popup-reward {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    width: 90px;
}

.offer-popup-reward p {
    text-align: center;
}

.offer-popup-multistep {
    display: flex;
    align-items: center;
    gap: 8px;
}

.offer-popup-multistep-value {
    min-width: 100px;
    box-sizing: border-box;
    display: flex;
    padding: 10px;
    justify-content: start;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    background: var(--Button-liner, linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%));
}

.offer-popup-multistep-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.offer-popup-guides {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offer-popup-guides-arrow {
    transition: all 0.2s ease-in-out;
}

.button-hidden.offer-popup-guides {
    width: 100%;
}

.offer-popup-grey-container.collapsed {
    padding: 0;
}

.offer-popup-grey-container {
    transition: all 0.5s ease-in-out;
}

.offer-popup-report, .offer-popup-report-status {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.offer-popup-report-status{
    flex-direction: row;
    justify-content: start;
}

.offer-report-status {
    margin: 0 auto;
    width: 100%;
}

.offer-popup-status-icon {
    width: 32px;
    height: 32px;
}

.offer-popup-report-btn {
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    border-radius: 18px;
}

.button-hidden.disable-submit {
    border: 1px solid #5B5B68;
}

.button-hidden.possible-submit {
    border: 1px solid #fff;
}

.offer-popup-report-top {
    display: flex;
    position: absolute;
    width: 55%;
    justify-content: space-between;
    top: 24px;
    padding-left: 17px;
}

.offer-popup-report-form {
    width: 100%;
    padding: 17px 17px 20px 17px;
    min-height: 50vh;
}

.offer-popup-report-input {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 6px;
    margin-bottom: 5px;
}

.offer-popup-report-input.disabled {
    opacity: 0.5;
}

.offer-popup-report-input input:focus-visible,
.offer-popup-report-input select:focus-visible,
.offer-popup-report-input textarea:focus-visible {
    outline: 3px solid #9F7DDB;
}

.offer-popup-report-input-text,
.offer-popup-report-input-textarea,
.offer-popup-report-input-dropdown {
    border-radius: 10px !important;
    text-align: left !important;
    border: 1px solid #DFDFE5;
    background: #FFF;
    color: #282B54 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 10px;
}

.offer-popup-report-input-textarea {
    padding: 10px;
    max-height: 150px;
    height: 150px;
    resize: vertical;
}

.offer-popup-report-input-text::placeholder,
.offer-popup-report-input-textarea::placeholder {
    color: #9395A9;
    font-size: 14px;
    font-weight: 400;
}

.offer-popup-report-input-file {
    font-size: 0;
    /* Hide the text */
    padding: 0;
    width: 0;
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
    /* Adjust padding or margin as needed */
    /* Add any other styling you want for the file input */
}

.offer-popup-report-input-file::file-selector-button {
    background: transparent;
    color: #fff;
    border: none;
}

.offer-popup-report-input-file-inner {
    display: flex;
    gap: 5px;
}

.offer-popup-report-input-file-inner .offer-popup-white-heading-14 {
    max-width: 50vw;
    max-height: 40px;
    overflow: hidden;
}

.offer-popup-report-input-file-remove {
    border-radius: 18px;
    border: 1px solid #5352B5;
    padding: 4px 10px;
    background: linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.offer-popup-report-input-dropdown {
    padding: 8px 10px;
}

.offer-popup-report-input-file-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.offer-popup-report-yes-no-toggle {
    display: flex;
    height: 72px;
    padding: 0px 12px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #DFDFE5;
    margin-top: 10px;
    margin-bottom: 10px;
}

.offer-popup-report-yes-no-toggle-checkbox {
    width: 0;
    height: 0;
}

.offer-popup-report-yes-no-toggle label {
    display: flex;
    gap: 8px;
    align-items: center;
}

.offer-popup-report-input.checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.offer-popup-sticky-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #222337;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
}

.offer_popup_error_text {
    color: #F00;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.offer_popup_error_catcher {
    align-self: self-end;
    position: relative;
    margin: 0px auto;
    width: 0%;
}

.offer-popup-report-yes-no-toggle-label,
.offer-popup-report-checkbox-label {
    position: relative;
}

.offer-popup-report-input .checkbox .offer-popup-grey-text-12 {
    max-width: 70vw;
}

.offer-popup-report-checked {
    position: absolute;
    left: 15px;
    max-height: 0 !important;
    transition: all 0.34s ease-in-out;
    object-fit: contain;
}

.offer-popup-report-checkbox-label .offer-popup-report-checked {
    left: 0px;
}

.offer-popup-report-checked.displayed {
    max-height: 5vh !important;
}

.offer-popup-report-error {
    display: flex;
    visibility: hidden;
    opacity: 0;
    width: 70vw;
    max-width: 346px;
    padding: 10px 20px 20px 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 14px;
    border: 1px solid #000;
    background: #FFF;
    position: absolute;
    z-index: 3;
    transition: visibility 1s, opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.offer-popup-report-error.displayed,
.offer-popup-report-input-file-select-modal.displayed {
    visibility: visible;
    opacity: 1;
}

.offer-popup-report-error .button-hidden {
    align-self: flex-end;
}

.offer-popup-report-error-heading {
    color: #FF3C3C;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px !important;
}

.offer-popup-report-error-desc {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
}

@media screen and (min-width: 528px) {
    .offer-popup-report {
        width: 100%;
    }
    
    .offer-popup-report-input-file-select {
        width: 30vw;
        max-width: 200px;
    }
}
@media screen and (min-width: 727px) {
    .offer-popup .close-button {
        right: 20px;
        top: 20px;
    }
}