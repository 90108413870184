button{
    cursor: pointer;
}

.swal2-styled.swal2-confirm {
    border-radius: 40px;
    padding: 12px 24px;
    margin-top: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    width: 260px;
    border-radius: 40px;
    border: none;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 40px;
    padding: 12px 24px;
    border: none;
    margin-top: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    width: 260px;
    border-radius: 40px !important;
    border: none;
}

.swal2-styled.swal2-cancel:focus {
    box-shadow: none !important;
}

.standard-confirm-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%) !important;
    border-radius: 18px !important;
    color: #FFFFFF !important;
}

.standard-cancel-button {
    color: #FFFFFF;
}

.underlined-cancel-button {
    color: #0A0B14 !important;
    text-decoration: underline;
    background: transparent !important;
}

div.swal2-popup {
    border-radius: 10px;
}

div.swal2-popup:has(.successful-alert) {
    border-radius: 8px !important;
}

.swal2-container:has(.successful-alert) {
    margin-top: 75px;
    display: flex;
    justify-content: center;
}

div.swal2-popup:has(.error-alert) {
    border-radius: 8px !important;
}

.swal2-container:has(.error-alert) {
    margin-top: 75px;
    display: flex;
    justify-content: center;
}

.swal2-popup.rounded {

}

div.swal2-actions {
    margin-top: 0;
}

.swal2-confirm.nfts-confirm-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px !important;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 22px;
    text-align: center;
    margin-top: 2px !important;
    margin-bottom: 30px !important;
    padding: 12px 88px;
    width: 100%;
}

.swal2-confirm.legitimacy-confirm-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px !important;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 22px;
    text-align: center;
    margin-top: 2px !important;
    margin-bottom: 30px !important;
    padding: 16px 88px;
    width: 100%;
}

.swal2-confirm.payment-method-hint-confirm-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px !important;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 22px;
    text-align: center;
    margin-top: 2px !important;
    margin-bottom: 30px !important;
    padding: 16px 88px;
    width: 100%;
}

.swal2-confirm.payment-method-add-confirm-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px !important;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px !important;
    line-height: 22px;
    text-align: center;
    margin-top: 2px !important;
    margin-bottom: 30px !important;
    padding: 16px 44px;
    width: 100%;
}

.swal-payment-methods-paxum-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.join-treatcoin-button,
.start-survey-button,
.approve-button,
.kyc-button-next,
.daily-earnings-plan-hint_btn {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    padding: 16px 50px;
    margin-top: 32px;
    cursor: pointer;
}

.join-treatcoin-button{
    max-width: 240px;
}

.approve-button {
    margin-top: 0;
    padding: 8px 30px;
    width: max-content;
}

.confirm-button {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 6px;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-bottom: 16px;
    border: none;
    border-radius: 18px;
    background: linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    width: 100%;
    max-width: 306px;
    margin: 0 auto;
    margin-bottom: 8px;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.button-back-bordered {
    color: #fff;
    display: block;
    margin: 0 auto;
    padding: 10px 48px !important;
    border-radius: 18px;
    border: 2.5px solid #5352B5 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.button-upload-bordered {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    border: 2px solid #5352B5;
}
.convert-button {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(20, 22, 41, 0.4);
    backdrop-filter: blur(8px);
    border-radius: 18px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 90px;
    width: calc(100% - 36px);
    max-width: 400px;
}

.convert-cancel-button {
    position: absolute;
    display: inline-block;
    width: auto !important;
    background: transparent !important;
    left: 0;
    bottom: 80px;
}

.back-button {
    background: none;
    border: 1px solid #5352B5;
    line-height: 110%;
    background: linear-gradient(127deg, #020024 0%, #8f91ff 41%, #bccdff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.see-all-offers-button {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 44px;
    border: none;
    margin-top: 16px;
}

.see-all-offers-button.incomplete-offers-list {
    margin-bottom: 16px;
}

.sort-by-offers-button {
    background: #F6F7FB;
    border: 1px solid #141629;
    border-radius: 12px;
    padding: 3px 3px 3px 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.sort-by-offers-button.highlighted {
    border: 1px solid #8938B9;
    background: linear-gradient(138.74deg, #8938B9 7.25%, #6219DE 65.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.join-treatcoin-homepage-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 11px;
    border: none;
    padding: 7px 14px 7px 10px;
    position: absolute;
    top: 16px;
    right: 14px;
}

.connect-to-telegram-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 20px 17px 20px;
    border: none;
    margin-top: 48px;
    display: inline-block;
}

.contact-support-suspended-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 20px 17px 20px;
    border: none;
    margin-top: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    display: block;
}

.offers-page-link {
    display: inline-block;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%), #EA81B6;
    border-radius: 18px;
    border: none;
    padding: 16px 36px;
    margin-top: 24px;
    margin-bottom: 32px;
}

.joined-user-indicator {
    background: linear-gradient(85.33deg, rgba(83, 82, 181, 0.75) -20.5%, rgba(159, 125, 219, 0.75) 96.23%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 26px;
    margin-top: 32px;
}

.menu-option-button,
.menu-option-section-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #15182A;
    display: inline-block;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 160, 0.5);
    padding-left: 0px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
}

.menu-option-section-button {
    align-items: center;
    border: none;
    display: flex;
    justify-content: space-between;
}

.menu-option-button-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #15182A;
    display: inline-block;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(55, 53, 160, 0.5);
    padding-left: 9px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
}

.swal2-styled.swal2-confirm:hover {
    background-color: #85FFC4 !important;
}

#contact-us-option {
    border: none;
    padding-bottom: 16px;
}

.list-offer-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    border: none;
    padding: 8px 16px;
    width: 100px;
}

.list-offer-button.completed {
    background: #5FC88F;
}

.hot-offer-button {
    position: relative;
    height: 49px;
    width: 70px;
    border-radius: 12px;
    padding: 8px 16px;
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    line-height: 110%;
    color: #FFFFFF;
    border: none;
    align-self: center;
}

.hot-offer-button.x2 {
    width: auto;
    padding: 8px
}

.hot-offer-button.x2 span {
    display: inline-block;
}

.hot-offer-button-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.hot-offer-button-span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
}

.hot-offer-button-img-abs {
    position: absolute;
    top: 10px;
    left: 5px;
}

.list-offer-container.featured-offer>.list-offer-container-button>.list-offer-button {
    padding: 8px 16px;
    background: #EA81B6;
}

.featured-offer-root.desktop.discord .list-offer-button.desktop {
    background: hsla(0, 0%, 100%, .2);
    color: #fff;
}

.list-offer-button>p {
    margin-top: 2px;
    margin-bottom: 2px;
}

.list-offer-button>.coins-amount {
    font-weight: 600;
    font-size: 14px;
}

.list-offer-button>.unlimited-indicator {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 0;
}

.list-offer-button>.toggle-indicator {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-top: 0;
}

.login-button,
.register-button,
.reset-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    border: none;
    color: #fff;
    display: inline-block;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 24px;
    padding: 12px 24px;
    width: 100%;
    max-width: 350px;
    cursor: pointer;
}

.register-button:not(.verification) {
    margin-top: 0;
}

.login-button {
    padding: 16px 24px;
}

.login-with-button a,
.login-with-button {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.login-with-button {
    width: 149px;
    cursor: pointer;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    border: none;
    padding: 12px 18px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.login-with-button img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    height: 22px;
    width: auto;
}

.login-button-styled {
    position: absolute;
    right: 5px;
    padding: 8px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #5352B5;
    border-radius: 18px;
    color: #5352B5;
    background-color: transparent;
    top: calc(50% - 28px);
    max-width: none;
}

.login-button-styled-or {
    font-size: 8px;
    line-height: 1px;
}

.login-button-styled-sign-in{
    line-height: 1px;
    font-size: 10px;
}

.account-settings-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    border: none;
    padding: 0 80px;
    margin-bottom: 24px;
    height: 56px;
}

.account-settings-button.save-password-button {
    margin-bottom: 12px;
}

.account-settings-button.delete-account-button {
    background: transparent;
    border: none;
    text-decoration: underline;
}

.account-settings-button.delete-account-button:hover {
    background: transparent;
    border: none;
    text-decoration: underline;
}

.account-settings-button.cancel-button {
    margin-top: 16px;
}

.login-button:hover,
.register-button:hover,
.reset-button:hover,
.account-settings-button:hover {
    background-color: #4947C8;
}

.login-logout-button {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #15182A;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    margin: 0 auto;
    margin-bottom: 0;
    padding-top: 9px;
    text-align: center;
}

.google-signup-button {
    display: none;
}

.scroll-to-top-button {
    width: 44px;
    height: auto;
}

#offers-policy-cta {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #141629;
    opacity: 0.7;
    cursor: pointer;
}

.refer-friends-now-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 32px;
    border: none;
}

.swal-copy-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%);
    border-radius: 12px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    padding: 14px 24px;
    position: absolute;
    top: 7px;
    right: 12px;
}

.swal-copy-button:focus {
    box-shadow: none !important;
}

.home-timer-button {
    background: linear-gradient(43.74deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
    border-radius: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 16px;
    padding-bottom: 16px;
    border: none;
    margin-top: 16px;
    width: calc(100% - 90px);
    margin-left: auto;
    margin-right: auto;
}

.withdrawal-option-button {
    width: 82px;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 87px;
    top: 414px;
    border-radius: 12px;
    border: 1px solid #5352B5;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #141629;
    margin-left: 18px;
    margin-right: 18px;
    background: transparent;
}

.withdrawal-option-button.selected {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    border: none;
    color: #FFFFFF;
}

.complete-withdraw-button,
.start-withdraw-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
    padding-top: 16px;
    padding-bottom: 16px;
    width: calc(100% - 44px);
    border: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    margin-bottom: 32px;
}

.start-withdraw-button:disabled{
    opacity: 0.4;
}

.complete-withdraw-button {
    max-width: 400px;
    border-radius: 12px;
}

.start-withdraw-button {
    max-width: 326px;
    border-radius: 18px;
}

.complete-withdraw-button.disabled {
    opacity: 0.3;
}

#submit-verification-button:disabled {
    opacity: 0.3;
}

.exchange-treat-coins-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    border-radius: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #FFFFFF;
    border: none;
    width: 125px;
    margin-top: 16px;
}

.withdraw-digit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 110%;
    text-align: center;
    color: #141629;
    border: none;
    background: #ffffff;
    padding: 0;
}

.offer-details-button {
    background: linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;
    border-radius: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 16px 10px;
    min-width: 250px;
    border: none;
    justify-content: center;
}

.providers-homepage-card-button {
    cursor: pointer;
    margin: 25px auto;
    background: #5FC88F;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    padding: 15px 20px;
    border: none;
    border-radius: 18px;
    width: fit-content;
}

.swal2-close.successful-alert {
    color: #FFFFFF;
}

.swal2-close.error-alert {
    color: #FFFFFF;
}

.disabled-btn{
    opacity: 0.3;
    cursor: not-allowed !important;
}

.link-button-container {
    position: relative;
}

.link-button-hint {
    position: absolute;
    left: 60%;
        top: -15px;
    z-index: 4444444;
    height: 30px;
    color: white;
    border-radius: 15px;
    width: fit-content;
    font-size: 16px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 1));
}

.link-button-hint.telegram{
    left: 45%;
}
.hidden-button:hover+.link-button-hint {
    animation: moveUpDown 3s ease-in-out 2;
}

@keyframes moveUpDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    25% {
        transform: translateY(-16px);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(-16px);
    }

    95% {
        transform: translateY(0);
    }
    100% {
        opacity: 0;
    }
}

.button-hidden {
    background: none;
    border: none;
}

.see-offers {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 6px;
    background: #FFFFFF;
    border-radius: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #6219DE;    
    border: none;
    outline: none;
}

.info-modal-button {
    position: absolute;
    right: 3vw;
}

.choose-socials-button {
    display: flex;
    align-items: center;
    position: relative;
    gap: 8px;
    transition: all 0.5s ease-in-out;
    }
    
    .choose-socials-button:hover {
        transform: scale(1.05);
}

.choose-socials-button-image {
    position: absolute;
    top: 6px;
    left: 6px;
}

.choose-socials-button-text {
    text-decoration: underline;
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.link-button {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #018E4D;
}

.link-button,
.bottom-menu-item {
    transition: all 0.5s ease-in-out;
}

.link-button:hover {
    transform: scale(1.05);
    box-shadow: #49C188;
}

.bottom-menu-item:hover {
    border-radius: 10px;
    box-shadow: 0px 4px 12px #5352B5;
}


.link-button-text {
    margin: 0;
    max-width: 125px;
    color: #018E4D;
}

.purple-light-button {
    background-color: #9985F6;
}

.choose-button {
    font-family: 'Poppins';
    border-radius: 16px;
    background: #2E3B57;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
}
.choose-button.chosen{
    background: #6260E1;
}
.choose-button.disabled {
    background: #73738D;
    opacity: 0.4;
}
.offer-popup-stock-confirm-button{
    font-size: 14px !important;
    gap: 10px !important;
    max-width: 100%;
}
.fixed-popup-wrapper .close-button {
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px;
}
.how-it-works-popup .close-button,
.terms-popup .close-button{
    position: absolute;
    right: 18px;
    top: 18px;
}

.how-it-works-popup .close-button img,
.terms-popup .close-button img {
    width: 19px;
    height: 19px;
}
.pink-outlined-button,
.pink-contained-button {
    border-radius: 8px;
    max-width: 160px;
    padding: 8px 10px;
}

.pink-outlined-button {
    border: 2px solid #8D31E4;
    background: transparent;
}

.pink-contained-button {
    background: #8D31E4;
}
.build-my-team-pagination-item {
    width: 24px;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.7;
    transition: all 0.2 ease-in-out;
}

.build-my-team-pagination-item.chosen {
    opacity: 1;
    border-radius: 20px;
    background: linear-gradient(44deg, #6BC6F5 3.37%, #EE94ED 50.17%, #F5BC68 83.44%, #EFCA45 110.31%);
}

.build-my-team-pagination-arrow {
    position: absolute;
    width: 24px;
    height: 24px;
}

.build-my-team-pagination-arrow.previous {
    left: -18px;
}

.build-my-team-pagination-arrow.next {
    right: -18px;
}

.build-my-team-pagination-arrow svg {
    fill: #fff;
    opacity: 0.7;
}
.delete-review-modal-cancel-button {
    border-radius: 18px;
    border: 1px solid #5352B5;
    background: transparent;
    color: #0A0B14;
    font-size: 15px;
    font-weight: 600;
}
.info-icon:after {
    content: "i";
    width: 15px;
    height: 15px;
    border-radius: 99px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: auto;
    cursor: pointer;
    font-size: 13px;
}
.affiliates-join-button {
    border-radius: 30px;
    background: #8D31E4;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    width: fit-content;
        padding: 17px 16px;
}
.more-offers-button {
    height: auto;
    position: absolute;
    right: 0px;
    border: none;
    background: transparent;
    padding: 0;
}
@media (min-width: 682px) {
    .terms-popup .close-button {
            display: block;
            position: absolute;
            right: 33px;
            top: 33px;
        }
    
        .terms-popup .close-button img {
            width: 24px;
            height: 24px;
        }
}
.completed-layout-button {
    max-width: 95%;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 6px;
    position: relative;
    z-index: 5;
}

.homepage .completed-layout-button{
    font-size: 10px;
}

.completed-layout-button img {
    width: 20px;
    height: 20px;
}
.carousel-indicator {
    width: 10px;
    height: 10px;
    background-color: #D9D9D9 !important;
    border-radius: 8px;
    transition: all 0.5s ease-in-out;
}

.carousel-indicator.active {
    background-color: #8D31E4 !important;
}
.affiliates-white-join-btn {
    border-radius: 30px;
    background: #FFF;
    height: 52px;
    padding: 16px;
    color: #8D31E4;
    font-size: 12px;
    font-weight: 700;
    padding: 17px 35px;
        max-width: fit-content;
}
@media (min-width: 1024px) {

    .carousel-indicator {
            width: 18px;
            height: 18px;
            border-radius: 18px;
        }
    .withdrawal-confirm-body .purple-button {
        width: 237px;
        height: 56px;
    }
    
        .list-offer-container.featured-offer:not(.special)>.list-offer-container-button.desktop>.list-offer-button.desktop {
            color: #fff;
            background: rgba(141, 49, 228, 0.15);
            color: #5e5dbb;
        }

    .list-offer-button.desktop {
        background: rgba(141, 49, 228, 0.15);
        color: #5e5dbb;
    }

    .favourite-offers-list.desktop .list-offer-button {
        color: #fff;
        background: #8447f1;
    }

    .list-offer-container.featured-offer>.list-offer-container-button>.list-offer-button {
        padding: 8px 16px !important;
        background: #EA81B6 !important;
        color: #fff !important;
    }
    
    .featured-offer-root.desktop.discord .list-offer-button.desktop {
        background: hsla(0, 0%, 100%, .2) !important;
        color: #fff !important;
    }

    .account-settings-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    .swal2-confirm.nfts-confirm-button {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .swal2-confirm.legitimacy-confirm-button {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .swal2-confirm.payment-method-hint-confirm-button {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .swal2-confirm.payment-method-add-confirm-button {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .swal2-confirm.payment-method-get-verification-button {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
        padding: 15px 10px;
    }

    #offer-data-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .login-button,
    .register-button,
    .reset-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        width: 350px;
    }
    
    .reset-button {
        margin-bottom: 32px;
    }

    .join-treatcoin-button,
    .start-survey-button {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
    }

    .intro-survey-starting-section.desktop .start-survey-button {
        padding: 16px 90px;
    }

    .approve-button:not(.one-two-treat-area-btn) {
        font-size: 17px;
        padding: 10px 30px;
    }

    .approve-button.one-two-treat-area-btn {
        font-size: 13px;
        padding: 10px 30px;
    }

    .see-all-offers-button {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
    }

    .offers-page-link {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .refer-friends-now-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
    }

    .join-treatcoin-homepage-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        width: 280px;
        padding: 14px 26px 17px 25px;
        border-radius: 18px;
        top: 45px;
    }

    .connect-to-telegram-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 14px 60px 17px 59px;
    }

    .contact-support-suspended-button {
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 14px 60px 17px 59px;
        width: 400px;
    }

    .swal2-styled.swal2-confirm,
    .swal2-styled.swal2-cancel {
        font-weight: 400;
        font-size: 18px !important;
        line-height: 140%;
        letter-spacing: 0.01em;
    }

    .home-timer-button {
        font-size: 18px;
        line-height: 27px;
    }

    .exchange-treat-coins-button {
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        color: #FFFFFF;
        border: none;
        width: 150px;
    }

    .withdrawal-option-button {
        font-size: 16px;
        width: 122px;
    }

    .complete-withdraw-button,
    .start-withdraw-button {
        font-size: 18px;
        width: 540px;
    }

    .withdraw-digit {
        font-size: 36px;
        width: 55px;
        height: 55px;
    }

    .purple-outlined-button,
    .purple-button {
        font-size: 17px !important;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {

    .reset-button {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
   
    .account-settings-button{
        padding: 0 16px;
        width: 100%;
    }

        @media (max-width: 400px) {

        .see-offers {
            padding: 10px 7px;
        }
        
        .offer-details-button {
            min-width: 220px;
        }
        
        @media (max-width: 360px) {
            .purple-button {
                padding: 16px 25px !important;
            }
        }
    }
}
@media (min-width: 1440px) {
    .affiliates-join-button, .affiliates-white-join-btn {
        font-weight: 700;
        font-size: 24px;
        max-width: fit-content;
        border-radius: 30px;
        padding: 15px;
        height: fit-content;
    }
}