.additional-offers-container p,
.additional-offers-container h1,
.additional-offers-container h2,
.additional-offers-container h3,
.additional-offers-container h4,
.additional-offers-container h5,
.additional-offers-container ul,
.additional-offers-container h6 {
    margin: 0;
}

.additional-offers {
    /* position: relative; */
    /* z-index: 1; */
}

.additional-offers-container {
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    width: 330px;
    border-radius: 14px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 8px;
    padding-bottom: 24px;
    font-family: Poppins;
    text-align: start;
    transition: all 1s ease-in-out;
    position: relative;
}

.additional-offers-container.desktop {
    width: 1110px;
    height: 498px;
}

.additional-offers-head-img {
    position: absolute;
    width: 300px;
    height: 278px;
    transform: translate(-50%, -50%);
    top: 40px;
    left: 50%;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
}

.additional-offers-heading-img {
    width: 24px;
    height: 24px;
}

.additional-offers-heading-container {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 5px;
}

.additional-offers-heading {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.additional-offers-desc {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 15px !important;
}

.head-img-knowledge {
    background-size: contain;
    top: -12px;
}

.additional-offers-gradient-1 {
    background: linear-gradient(6deg, #E8E462 4.85%, #3fe9e9c9 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-gradient-2 {
    background: linear-gradient(6deg, #51A7EE 4.85%, #EA4661 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-gradient-3 {
    background: linear-gradient(6deg, #FDDE7D 4.85%, #FE8288 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-gradient-4 {
    background: linear-gradient(6deg, #FFC661 4.85%, #FF9797 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-gradient-5 {
    background: linear-gradient(6deg, #FDDE7D 4.85%, #FE8288 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-gradient-6 {
    background: linear-gradient(6deg, #E8C222 4.85%, #4CCEFF 79.16%), linear-gradient(85deg, #5352B5 -20.5%, #9F7DDB 96.23%);
}

.additional-offers-paginator-desktop {
    position: absolute;
    display: none;
    width: 99%;
    top: 50%;
    left: 5px;
    z-index: 2;
    justify-content: space-between;
}

.additional-offers-swipe-btn img{
    height: 70px;
}

.additional-offers-list {
    list-style-type: none;
    padding: 0;
    gap: 10px;
    display: flex;
    width: 317px;
    overflow-x: scroll;
}

.additional-offer-card {
    width: 317px;
    border-radius: 14px;
    padding: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 3;
    position: relative;
}

.offers-categories-homepage-currencies {
    position: absolute;
    bottom: 15px;
    left: 28px;
}

.additional-offer-card-1 {
    background: linear-gradient(177deg, #EFEA22 30.64%, #23D3FF 104.6%);
}

.additional-offer-card-2 {
    background: linear-gradient(174deg, #4AABF5 -0.35%, #EA4661 99.52%);
}

.additional-offer-card-3 {
    background: linear-gradient(176deg, #FDE37C 21.14%, #FE8288 69.38%);
}

.additional-offer-card-4 {
    background: linear-gradient(170deg, #FFCD4B 26.54%, #F56C55 98.85%);
}

.additional-offer-card-5 {
    background: linear-gradient(176deg, #FCECC3 15.14%, #DE8BAE 101.38%);
}

.additional-offer-card-6 {
    background: linear-gradient(164deg, #FFD600 -2.12%, #00A3FF 98.27%);
}

.additional-offer-card-img {
    border-radius: 8px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 304px;
    height: 200px;
    margin-bottom: 12px !important;
}

.additional-offer-card-name {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.additional-offer-card-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 4px !important;
}

.additional-offer-card-usd-desc,
.additional-offer-card-tc-desc {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.additional-offer-card-tc-desc {
    margin-top: -5px !important;
}

.additional-offer-card-usd-desc {
    position: absolute;
    left: 15px;
    bottom: -15px;
}

.additional-offer-card-border {
    border-bottom: 1px solid #fff;
    margin: 4px 0 4px 0;
    margin-bottom: 15px;
}

.additional-offer-card-details {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
.additional-offers-paginator{
    display: flex;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translateX(50%);
}
.additional-offers-paginator img{
    width: 10px;
    height: 10px;
}
@media screen and (min-width: 1440px) {

    .offers-categories-homepage-currencies {
        bottom: 30px;
        left: 42px;
    }

    .additional-offers-paginator {
        display: none;
    }

    .additional-offers-paginator-desktop {
        display: flex;
    }

    .additional-offers-container {
        width: 1180px;
        padding: 20px 60px;
        padding-bottom: 24px;
        margin-top: 50px;
    }

    .additional-offers-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .additional-offers-head-img {
        width: 540px;
        height: 500px;
        top: 100px;
    }

    .additional-offers-list {
        width: 1069px;
        gap: 20px;
    }

    .additional-offer-card {
        width: 343px;
        padding: 20px;
    }
}