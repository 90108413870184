.page-description {
    font-size: 18px;
}

.lable-header{
    font-size: 22px;
}

.review-app-name{
    font-size: 18px;
}

.review-title{
    font-size: 16px;
}

.review-img{
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 10px;
}

.review-img img {
    border-radius: 10px;
    max-height:100%; 
    max-width:100%;
}

.reviews-todo .review-img{
    width: 160px;
    height: 185px;
    flex: 0 0 160px;
}

.reviews-done .review-img{
    width: 105px;
    height: 150px;
    flex: 0 0 105px;
}

.review-wrap{
    gap: 20px;
}

.edit-review-wrap {
    background: #222337;
    padding: 20px;
    border-radius: 20px;
    gap: 15px;
    text-align: left;
    margin-bottom: 20px;
}

.edit-review-wrap .review-img{
    width: 155px;
}

.edit-review-rating{
    margin-bottom: 20px;
}

.review-earn{
    background: #303149;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}

.edit-review-outter-wrap .form-area{
    margin-bottom: 20px;
}

.edit-review-outter-wrap .form-area textarea{
    resize: none;
}

.reviews-todo-content{
    gap: 7px;
}

.review-earn-payout{
    display: flex;
    gap: 10px;
    align-items: center;
}

.review-text{
    font-size: 13px;
}

.review-status {
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 400;
    width: fit-content;
}

.review-status.status-1 {
    border: 1px solid #FAC401;
    color: #FAC401;
}


.review-status.status-2 {
    color: #FA0101;
    border: 1px solid #FA0101;
}

.review-status.status-3 {
    color: #42FA01;
    border: 1px solid #42FA01;
}
.review-score{
    gap: 2px;
    margin: 3px 0 7px;
}

.todo-reviews, .done-reviews{
    gap: 30px;
}

.show{
    display: block;
}

.edit-review-title {
    font-size: 32px;
    margin-top: 30px;
    font-weight: 700;
}

.edit-review-sub-title{
    font-size: 21px;
    margin: 15px 0;
}

.no-reviews-msg {
    font-size: 16px;
    opacity: 0.5;
}

.filter-wrap{
    cursor: pointer;
    font-size: 13px;
    position: relative;
}

.filter-set {
    border: 1px solid #6260E1;
    border-radius: 8px;
    padding: 2px 5px;
    color: #6260E1;
}


.filter-wrap .dots-action-menu {
    width: 160px;
    font-size: inherit;
    text-align: left;
    color: #fff;
}

.filter-set .dots-action-menu{
    top: 35px;
    right: 2px;
}



@media screen and (max-width: 500px) {
    .reviews-todo .review-img {
        height: 150px;
        flex: 0 0 30vw;
    }

    .reviews-done .review-img {
        height: 120px;
        flex: 0 0 23vw;
    }

    .review-wrap {
        gap: 10px;
    }

    .todo-reviews{
        gap: 20px;
    }

    .review-app-name{
        font-size: 14px;
    }

    .review-title{
        font-size: 12px;
    }

    .round-btn{
        font-size: 10px;
    }

    .review-text {
        font-size: 10px;
    }

    .review-wrap .text10 {
        font-size: 9px;
    }

    .edit-review-wrap .review-img {
        width: 120px;
        height: 95px;
    }

    .edit-review-wrap {
        padding: 15px;
    }

    .edit-review-sub-title {
        margin: 10px 0;
    }

    .edit-review-wrap, 
    .edit-review-rating, 
    .edit-review-outter-wrap .form-area {
        margin-bottom: 15px;
    }

    .edit-review-title {
        margin-top: 20px;
    }

    .form-area {
        gap: 10px;
    }

}