.user-stats.new {
    padding: 5px 0 0;
}

.stats-new {
    background: linear-gradient(230.6deg, #313545 4.66%, #121118 101.95%);
    padding: 0 30px 31px;
    border-radius: 38px 38px 0px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 15px;
}

.stats-new * {
    color: #fff;
}

.user-stats-header.new {
    background-color: #ffffff06;
    box-shadow: 0px 0px 6px 0px #6260E1;
    border-radius: 14px;
    transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-stats-header.new:hover,
.user-stats-header.new:focus {
    box-shadow: 0px 0px 14px 2px #6260E1;
}

.user-stats-details.new {
    padding-top: 20px;
}

.stats-component-texts.new {
    margin-top: 10px;
    background-color: rgba(20, 22, 41, 1);
    top: 30px;
}

.stats-component-texts.new * {
    color: #fff;
}

.stats-component-text-item+hr {
    border-bottom: 1px solid #fff;
}

.stats-component-title.new {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-top: 0px;
}

.stats-component-subtitle.new {
    opacity: 1;
    margin: 20px auto;
    width: 90%;
}

.stats-component-link-item.new {
    border: 1px solid rgba(255, 255, 255, .3);
}

.stats-component-links p {
    background: none;
    color: #fff;
    -webkit-text-fill-color: inherit;
}

.stats-component-link-item.new span,
.stats-component-links.new p {
    -webkit-text-fill-color: initial;
}

.user-stats-header-earnings {
    display: flex;
    align-items: start;
    padding-left: 2px;
    width: 100%;
    height: 100%;
}

.user-stats-header-earnings .coin {
    width: 28px;
    height: 28px;
    margin-right: 6px;
}

.user-stats-header .icon {
    width: 16px;
    height: 16px;
}

.user-stats-header .discord {
    width: 16px;
    height: 14px;
    margin-top: -2px;
    margin-bottom: 4px;
}

.user-stats-header .offer {
    margin-top: -5px;
    margin-bottom: 5px;
}

.user-stats-header .team {
    width: 18px;
    height: 18px;
    margin-top: -2px;
}

.user-stats-header-badge {
    position: absolute;
    top: 5px;
    left: 8px;
    font-size: 10px;
}

.user-stats-header-earnings-available {
    text-align: left;
}

.user-stats-header-earnings-values {
    text-align: left;
    margin-right: 5px;
}

.user-stats-header-earnings-values .value {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 1.5px;
    min-width: 33px;
    display: block;
}

.user-stats-header-earnings-values .available {
    font-weight: 600;
    font-size: 24px;
}
.user-stats-header-earnings-info.available {
    font-weight: 400;
    font-size: 8px !important;
    margin-top: -1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-stat-header-table-actions {
    border-spacing: 0 8px;
}

.user-stats-header-action * {
    display: inline-block;
}

.user-stats-header-action {
    padding-left: 20%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 3px !important;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 5px;
    height: 50px;
    width: 53px;
}

.user-stats-header-action:hover,
.user-stats-header-action:focus {
    transform: scale(1.005) translateY(-2px);
    box-shadow: 0px 0px 8px 2px #6260e15a;
    background-color: #6260e144;
}

.user-stats-header-earnings-info {
    font-size: 8px;
    text-align: start !important;
    width: 100%;
    color: #fff;
}

.user-stats-header-text {
    color: #fff;
    font-size: 8px;
    line-height: 12px;
    margin-top: 4px !important;
}

.user-stats-header-earnings tr {
    height: 18px;
}

@media (max-width: 1023px) {
    .user-stats-header.new.desktop {
        margin-top: 30px;
    }
}

@media (min-width: 1024px) {
    .user-stats-header-earnings {
        scale: 1.1;
        padding-left: 30px;
        padding-top: 30px !important;
    }

    .user-stats-header-action {
        height: 78px;
        padding-top: 15px !important;
    }

    .user-stats-header-earnings {
        padding-top: 20px;
        gap: 8px;
    }

    .user-stats-header-text {
        font-size: 12px;
        line-height: 14px;
        margin-top: 6px !important;
        }

    .user-stats-header-earnings-info {
        font-size: 14px !important;
    }

    .user-stats-header-earnings .values {
        font-size: 15px !important;
    }

    .user-stats-header.new.desktop {
        max-width: unset;
        width: 345px;
        height: 120px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .user-stats-header-action {
        width: 100px;
    }

    .user-stats-header.new.desktop img.coin {
        width: 32px;
        height: 32px;
    }

    .user-stats-header.new.desktop img.icon {
        width: 24px;
        height: 24px;
    }

    .user-stats-header.new.desktop img.team {
        width: 26px;
        height: 26px;
    }

    .user-stats-header.new.desktop img.discord {
        margin-top: 0px;
        width: 18px;
        height: 16px;
        margin-bottom: 8px;
    }

    .user-stats-header.new.desktop img.user {
        width: 27px;
        height: 34px;
    }


    .user-stats-header.new.desktop .user-stats-header-logos {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .stats-new.desktop {
        border-radius: 26px;
        min-height: 250px;
        padding: 0 30px 10px;
    }

}

@media (min-width: 1300px) {
    .user-stats-header.new.desktop img.coin {
        width: 40px;
        height: 40px;
    }
}